import { Link, withRouter } from "react-router-dom";
import CoursesList from "./CoursesList";
import styles from "./index.module.css";
import { Component } from "react";
import CourseOutcome from "../../CourseOutcome";
import FeedBack from "../../FeedBack";
import QuestionPaperMapping from "../../QuestionPaperMapping";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import SearchContext from "../../../../context/SearchContext";

class ManageCourses extends Component {
  state = {
    isLoading: true,
    data: [],
    content: false,
    isOpened: false,
    active: null,
    id: null,
    filters: {
      regulation: "",
      batch: "",
      branch: "",
      semester: "",
      section: "",
    },
    dropBatchOptions: [],
    dropBranchOptions: [],
    dropSemOptions: [],
    dropRegOptions: [],
    dropSectionOptions: [],
  };

  static contextType = SearchContext;

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropBatchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropBranchOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropSemOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropRegOptions: response["data"]["result"] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      await this.getBatchOptions();
      await this.getSemOptions();
      await this.getBranchOptions();
      await this.getRegOptions();
    } catch (err) {
      console.log(err);
    }
  };

  regulationChanged = (event) => {
    const { filters } = this.state;
    this.setState(
      { filters: { ...filters, regulation: event.target.value } },
      this.getCourses
    );
  };

  branchChanged = (event) => {
    const { filters } = this.state;
    this.setState(
      { filters: { ...filters, branch: event.target.value } },
      this.getCourses
    );
  };

  batchChanged = (event) => {
    const { filters } = this.state;

    this.setState(
      { filters: { ...filters, batch: event.target.value } },
      this.getCourses
    );
  };

  sectionChanged = (event) => {
    this.setState(
      (prev) => ({
        filters: { ...prev.filters, section: event.target.value },
      }),
      this.getCourses
    );
  };

  semesterChanged = (event) => {
    const { filters } = this.state;
    this.setState(
      { filters: { ...filters, semester: event.target.value } },
      this.getCourses
    );
  };

  getCourses = async () => {
    this.setState({ isLoading: true });
    try {
      const token = Cookies.get("jwt_token");
      const { filters } = this.state;

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${filters.branch}&regulation=${filters.regulation}&semester=${filters.semester}&section=${filters.section}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        this.setState({
          data: response["data"]["result"]["courseDetailsResponses"],
          content: true,
        });
      } else {
        this.setState({
          data: [],
          content: false,
        });
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const { dropBatchOptions, filters } = this.state;

      let batch = dropBatchOptions.filter(
        (each) => each.optionValue === filters.batch
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        this.setState({ dropSectionOptions: response["data"]["result"] });
      } else {
        this.setState({ dropSectionOptions: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        this.getCourses();
        this.getOptions();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filters.batch != this.state.filters.batch ||
      prevState.filters.branch != this.state.filters.branch
    ) {
      this.getSectionOptions();
    }
  }

  closeBtn = () => {
    this.setState({
      active: null,
      isOpened: false,
    });
  };

  openBtn = (id) => {
    this.setState({
      id: id,
      isOpened: true,
    });
  };

  reload = () => {
    this.getCourses();
  };

  renderView = () => {
    try {
      const { id, data, active } = this.state;
      const ans = data.filter((each) => each.courseId == id);

      switch (active) {
        case 1:
          return (
            <CourseOutcome
              item={ans}
              cls={this.closeBtn}
              reload={this.reload}
            />
          );
        case 2:
          return (
            <QuestionPaperMapping
              cls={this.closeBtn}
              item={ans}
              reload={this.reload}
            />
          );

        case 3:
          return (
            <FeedBack item={ans} cls={this.closeBtn} reload={this.reload} />
          );

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  preventBubbling = (event) => {
    event.stopPropagation();
  };

  coBtn = async (id) => {
    this.setState(
      {
        active: 1,
      },
      this.openBtn(id)
    );
  };

  quesPaperBtn = async (id) => {
    this.setState(
      {
        active: 2,
      },
      this.openBtn(id)
    );
  };

  feedbackBtn = async (id) => {
    this.setState(
      {
        active: 3,
      },
      this.openBtn(id)
    );
  };

  renderBatchOptions = () => {
    const { dropBatchOptions } = this.state;
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { dropBranchOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { dropSemOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropSemOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderRegOptions = () => {
    const { dropRegOptions } = this.state;

    const ans = [];
    try {
      for (const item of dropRegOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSectionOptions = () => {
    const ans = [];
    try {
      const { dropSectionOptions } = this.state;
      for (const item of dropSectionOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderCourses = () => {
    const { data } = this.state;

    const { search } = this.context;
    const filteredCourses = data.filter(
      (item) =>
        item.courseName !== null &&
        item.courseName.toLowerCase().includes(search.toLowerCase())
    );

    return filteredCourses.map((each) => (
      <CoursesList
        item={each}
        key={each.courseId}
        coBtn={this.coBtn}
        quesPaperBtn={this.quesPaperBtn}
        feedbackBtn={this.feedbackBtn}
      />
    ));
  };

  render() {
    const { match } = this.props;
    const { path } = match;
    const { isOpened, active, content, data, filters, isLoading } = this.state;

    return (
      <>
        <div
          onClick={this.closeBtn}
          className={isOpened ? styles.overlay : "hidden"}
        >
          <div
            onClick={this.preventBubbling}
            className={styles["overlay-card"]}
          >
            {active !== null && this.renderView()}
          </div>
        </div>
        <div className="ml-7">
          <div className="flex flex-row items-center mt-24">
            <Link to="/faculty/dashboard/managecourses">
              <h1
                className={`${
                  path === "/faculty/dashboard/managecourses"
                    ? "font-bold underline"
                    : "font-medium"
                } ml-4 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
              >
                MANAGE COURSES
              </h1>
            </Link>
            {/* <Link to="/faculty/dashboard/analysis">
              <h1
                className={`${
                  path === "/faculty/dashboard/analysis"
                    ? "font-bold underline"
                    : "font-medium"
                } ml-6 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
              >
                ANALYSIS
              </h1>
            </Link> */}
            <Link to="/faculty/dashboard/attainments">
              <h1
                className={`hover:font-bold hover:[#464D90] ${
                  path === "/faculty/dashboard/attainments"
                    ? "font-bold underline"
                    : "font-medium"
                } ml-6 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
              >
                ATTAINMENTS
              </h1>
            </Link>
          </div>

          <div className="flex flex-row justify-between items-center mt-6 mr-10 mb-1">
            <h1 className={styles["total-courses"]}>
              TOTAL COURSES : {content ? data.length : 0}
            </h1>
            <div className={styles["filters"]}>
              <select
                value={filters.regulation}
                onChange={this.regulationChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Regulations</option>
                {this.renderRegOptions()}
              </select>

              <select
                value={filters.batch}
                onChange={this.batchChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Batches</option>
                {this.renderBatchOptions()}
              </select>
              <select
                value={filters.branch}
                onChange={this.branchChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Branches</option>
                {this.renderBranchOptions()}
              </select>
              <select
                value={filters.semester}
                onChange={this.semesterChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Semesters</option>
                {this.renderSemOptions()}
              </select>

              <select
                value={filters.section}
                onChange={this.sectionChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Sections</option>

                {this.renderSectionOptions()}
              </select>
            </div>
          </div>
        </div>

        <div className={styles["dashboard-periodwisebar-bg"]}>
          {isLoading ? (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <ul
              className={`${
                !content && "justify-center"
              } list-none w-full m-0 p-0 flex flex-row flex-wrap ml-1`}
            >
              {content ? (
                this.renderCourses()
              ) : (
                <p className="text-center fixed text-red-400">No Data Found</p>
              )}
            </ul>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(ManageCourses);
