import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Component } from "react";
import * as jose from "jose";
import Popup from "reactjs-popup";
import { Oval } from "react-loader-spinner";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
highchartsMore(Highcharts);
require("highcharts/modules/exporting");

const dummyPsoData = [
  { poPsoName: "PO1", poAttvalue: "" },
  { poPsoName: "PO2", poAttvalue: "" },
  { poPsoName: "PO3", poAttvalue: "" },
  { poPsoName: "PO4", poAttvalue: "" },
  { poPsoName: "PO5", poAttvalue: "" },
  { poPsoName: "PO6", poAttvalue: "" },
  { poPsoName: "PO7", poAttvalue: "" },
  { poPsoName: "PO8", poAttvalue: "" },
  { poPsoName: "PO9", poAttvalue: "" },
  { poPsoName: "PO10", poAttvalue: "" },
  { poPsoName: "PO11", poAttvalue: "" },
  { poPsoName: "PO12", poAttvalue: "" },
  // { poPsoName: "PSO1", poAttvalue: "" },
  // { poPsoName: "PSO2", poAttvalue: "" },
];

class CoursesAnalysisOutcome extends Component {
  state = {
    isPopupLoading: false,
    seeWeightages: {},
    popup: false,
    batch: "",
    branch: "",
    sem: "",
    sectionOptions: [],
    secValue: "",
    course: "",
    courseCodes: [],
    data: null,
    psoData: dummyPsoData,
    content: "loading",
    internalData: [],
    externalData: [],
    batchOptions: [],
    branchOptions: [],
    semOptions: [],
    userType: "",
    itemAnalysis: { q1: [], q2: [], q3: [], q4: [], outliers: [] },
    bloomsData: [],
  };

  abortController = null;

  getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          this.setState(
            {
              batchOptions: response["data"]["result"],
              batch: response["data"]["result"][0]["optionValue"],
            },
            this.getCourseCodes
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    const { userType } = this.state;
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          if (userType === "HOD") {
            this.setState(
              {
                branchOptions: response["data"]["result"],
                // branch: response["data"]["result"][0]["optionValue"],
              },
              this.getCourseCodes
            );
          } else {
            this.setState(
              {
                branchOptions: response["data"]["result"],
                branch: response["data"]["result"][0]["optionValue"],
              },
              this.getCourseCodes
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          this.setState(
            {
              semOptions: response["data"]["result"],
              sem: response["data"]["result"][0]["optionValue"],
            },
            this.getCourseCodes
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getOptions = async () => {
    try {
      await this.getBatchOptions();
      await this.getBranchOptions();
      await this.getSemOptions();
      await this.getSectionOptions();
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    // Cleanup function to abort the request if the component unmounts
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  getData = async () => {
    try {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();
      this.setState({ content: "loading" });

      const { course, secValue, sectionOptions } = this.state;

      let sectionsVal = secValue === "" ? sectionOptions.join() : secValue;
      sectionsVal = sectionsVal === "" ? "A" : sectionsVal;

      const token = Cookies.get("jwt_token");

      if (course !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS +
            `?courseId=${course}&sectionList=${sectionsVal}`,
          { Authorization: token },
          {
            signal: this.abortController.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState(
            { data: response["data"]["result"], content: true },
            this.tableStruct
          );

          // console.log(response['data']['result']);
        } else {
          this.setState({ data: null, content: false });
        }
      } else {
        this.setState({ data: null, content: false });
      }
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    const { batchOptions, batch, branch } = this.state;
    try {
      let batchVal = batchOptions.filter((each) => each.optionValue === batch);

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${branch}&batch=${batchVal[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          this.setState({
            secValue: "",
            sectionOptions: response["data"]["result"],
          });
        } else {
          this.setState({
            secValue: "",
            sectionOptions: [],
          });
        }
      } else {
        this.setState({
          secValue: "",
          sectionOptions: [],
        });
      }
      this.getCodesFun();
    } catch (err) {
      console.log(err);
    }
  };

  secChanged = (event) => {
    this.setState({ secValue: event.target.value }, this.getCodesFun);
  };

  hitApi = async () => {
    await this.getCourseCodes();
    await this.getSectionOptions();
  };

  renderSectionOptions = () => {
    const ans = [];
    const { sectionOptions } = this.state;
    try {
      for (const item of sectionOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  batchChanged = (event) => {
    this.setState({ batch: event.target.value }, this.hitApi);
  };

  branchChanged = (event) => {
    this.setState({ branch: event.target.value }, this.hitApi);
  };

  semChanged = (event) => {
    this.setState({ sem: event.target.value }, this.getCourseCodes);
  };

  courseChanged = (event) => {
    this.setState({ course: event.target.value }, this.getCodesFun);
  };

  getCodesFun = () => {
    try {
      this.getData();
      this.getPsoData();
      this.getItemAnalysis();
      this.getBloomsLvl();
    } catch (err) {
      console.log(err);
    }
  };

  getCourseCodes = async () => {
    try {
      this.setState({ course: "", courseCodes: [], content: "loading" });
      const { batch, branch, sem } = this.state;

      const token = Cookies.get("jwt_token");

      if (batch !== "" && branch !== "" && sem !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS_CODE_OPTIONS +
            `?batch=${batch}&branch=${branch}&semester=${sem}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState(
            {
              course: response["data"]["result"][0]["courseId"],
              courseCodes: response["data"]["result"],
            },
            this.getCodesFun
          );
        } else {
          this.setState({ content: false, data: null });
        }
      } else {
        this.setState({ content: false, data: null });
      }

      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  getPsoData = async () => {
    try {
      const { batch, branch, sem, course } = this.state;

      const token = Cookies.get("jwt_token");

      if (batch !== "" && branch !== "" && sem !== "" && course !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.PO_PSO_ATTAINMENT +
            `?batch=${batch}&branch=${branch}&semester=${sem}&courseId=${course}`,
          { Authorization: token }
        );

        // console.log(response['data']);
        if (response["data"]["statusCode"] === "OK") {
          this.setState({ psoData: response["data"]["result"] });
        } else {
          this.setState({ psoData: dummyPsoData });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  tableStruct = () => {
    try {
      const { data } = this.state;

      const { courseStructure } = data;
      const item = courseStructure;

      const intTheory =
        item.intTheoryShortList === null
          ? ""
          : item.intTheoryShortList.split(",");

      const intPract =
        item.intPractShortList === null
          ? ""
          : item.intPractShortList.split(",");

      const intProj =
        item.intProjShortList === null ? "" : item.intProjShortList.split(",");

      const internal = intTheory.concat(intPract).concat(intProj);

      const internalValues = [];

      for (const each of internal) {
        if (each !== "") {
          internalValues.push(each);
        }
      }

      // console.log(internalValues);

      // internal = internal.filter((each) => each !== '');

      const extTheory =
        item.extTheoryShortList === null
          ? ""
          : item.extTheoryShortList.split(",");

      const extPract =
        item.extPractShortList === null
          ? ""
          : item.extPractShortList.split(",");
      const extProj =
        item.extProjShortList === null ? "" : item.extProjShortList.split(",");

      const external = extTheory.concat(extPract).concat(extProj);

      // external = external.filter((each) => each !== '');
      const externalValues = [];

      for (const each of external) {
        if (each !== "") {
          externalValues.push(each);
        }
      }

      this.setState({
        internalData: internalValues,
        externalData: externalValues,
      });
    } catch (err) {
      console.log(err);
    }
  };

  renderCoAtt = () => {
    const { data, internalData, externalData } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
              {/* {Object.keys(data["coAttData"])[index]} */}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `Internal` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <>
              {externalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `External` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <td
              className={`${styles["table-td"]} border border-blue-500 text-center p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "avgCie"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wAvgCie"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wAvgSee"
              ] ?? ""}{" "}
            </td>

            <th
              className={`${styles["table-td"]} text-center border border-blue-500 p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderOverAllAtt = () => {
    const { data } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            key={index}
            className="border border-blue-500 text-[#040C58] font-semibold"
          >
            <th className="border border-blue-500 p-2">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
              {/* {Object.keys(data["coAttData"])[index]} */}
            </th>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttIdaPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttIdaPCt"
              ] ?? ""}
            </td>

            <th className={`${styles["table-td"]} p-2 border border-blue-500`}>
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalCoAtt"
              ] ?? ""}
            </th>
            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "targetAtt"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttLvl"
              ] ?? ""}
            </td>
            <th
              className={`${styles["table-td"]} ${
                data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                  "isCoAchieved"
                ] === "Y"
                  ? "text-green-500"
                  : "text-red-500"
              } border border-blue-500 p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "isCoAchieved"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getIntTd = () => {
    const { internalData } = this.state;
    const ans = [];
    try {
      for (const each of internalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF]">
            {each}
          </th>
        );
      }
      // console.log(ans);

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getExtTd = () => {
    const { externalData } = this.state;

    const ans = [];

    try {
      for (const each of externalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF]">
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  filteringItemAnalysis = (res) => {
    const q1 = [];
    const q2 = [];
    const q3 = [];
    const q4 = [];
    const outliers = [];

    for (let each of res) {
      if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q3.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.2 &&
        each["DI"] < 0.4
      ) {
        q4.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.4 &&
        each["FV"] < 0.7 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.6
      ) {
        q2.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else if (
        each["FV"] >= 0.7 &&
        each["FV"] < 1 &&
        each["DI"] >= 0.4 &&
        each["DI"] < 0.7
      ) {
        q1.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      } else {
        outliers.push({
          x: each["FV"],
          y: each["DI"],
          dataLabels: { format: each["questionNo"] },
        });
      }
    }

    this.setState({ itemAnalysis: { q1, q2, q3, q4, outliers } });
  };

  getGraphOptions = () => {
    const { itemAnalysis } = this.state;

    const { q1, q2, q3, q4, outliers } = itemAnalysis;

    const option = {
      chart: {
        type: "scatter",
        height: 550,
        width: 900,
      },
      title: {
        margin: 30,
        text: "Item Analysis",
        align: "center",
        style: {
          color: "#606981",
          fontSize: "14px",
          fontWeight: "600",
        },
      },

      xAxis: [
        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1.1,

          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
            x: 30,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",
          tickWidth: 0.5,
        },
        {
          offset: 30,

          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [0, 0.364, 0.636, 1],
          gridLineWidth: 0,
          tickWidth: 0.5,
          tickLength: 30,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
            y: 20,
            x: 100,

            formatter: function () {
              if (this.value === 0.636) {
                return (
                  '<span style="color:' + "#1C60FF" + '">' + "Easy" + "</span>"
                );
              } else if (this.value === 0.364) {
                return (
                  '<span style="color:' +
                  "#27AE7A" +
                  '">' +
                  "Medium" +
                  "</span>"
                );
              } else if (this.value === 0) {
                return (
                  '<span style="color:' + "#E35050" + '">' + "Hard" + "</span>"
                );
              }
            },
          },
        },
        {
          offset: 60,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      yAxis: [
        {
          min: 0,
          max: 1,
          tickInterval: 0.1,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            y: 20,
          },

          gridLineColor: "#456BF1",
          gridLineWidth: 0.5,

          tickWidth: 0.5,
          tickLength: 40,
          tickColor: "#456BF1",
        },

        {
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },

          tickWidth: 0.5,
          tickLength: 60,
          tickColor: "#456BF1",
          tickPositions: [0, 0.2, 0.3, 0.4, 1],
          gridLineWidth: 0,

          labels: {
            overflow: "justify",
            y: 18,
            x: -5,

            formatter: function () {
              if (Math.ceil(Number(this.value) * 10) === 10) {
                return (
                  '<span style="color:' +
                  "#1C60FF" +
                  '">' +
                  "Excellent" +
                  "</span>"
                );
              } else if (Math.ceil(Number(this.value) * 10) === 4) {
                return (
                  '<span style="color:' + "#27AE7A" + '">' + "Good" + "</span>"
                );
              } else if (Math.ceil(Number(this.value) * 10) === 3) {
                return (
                  '<span style="color:' + "#27AE7A" + '">' + "Fair" + "</span>"
                );
              } else if (Math.ceil(Number(this.value) * 10) === 2) {
                return (
                  '<span style="color:' + "#E35050" + '">' + "Poor" + "</span>"
                );
              }
            },
          },
        },
        {
          offset: 100,
          lineColor: "#456BF1",
          lineWidth: 0.5,
          min: 0,
          max: 1,
          tickInterval: 0.05,
          title: {
            text: null,
          },
          tickPositions: [],

          gridLineWidth: 0,
          tickWidth: 0,
          tickColor: "#456BF1",

          labels: {
            overflow: "justify",
          },
        },
      ],

      accessibility: {
        enabled: false,
      },

      plotOptions: {
        scatter: {
          color: "#E35050",
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            verticalAlign: "middle",
            align: "center",
            borderRadius: 20,
            style: {
              textOutline: "none",
              color: "#FFFFFF",
              fontSize: 12,
              fontWeight: 400,
              borderRadius: 10,
            },
          },
        },
      },

      legend: {
        enabled: false,
        y: 20,
        align: "left",
        verticalAlign: "bottom",
        layout: "horizontal",
        symbolRadius: 0,
        itemStyle: {
          color: "#8F949F",
          fontWeight: "normal",
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        // below code is for the color quadrants

        {
          type: "area",
          lineWidth: 0,
          color: "#E0EAFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.4],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
            [0.4, 0.2],
            [0.4, 0.4],
            [0.4, 0.2],
            [0.7, 0.4],
            [0.7, 0.2],
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#FFE6E6",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [0.7, 0.6], //top-right
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], //bottom-right
            [0.4, 0.4], //bottom-left
            [0.4, 0.6], //top-left
            [0.4, 0.4], //bottom-left
            [0.7, 0.6], //top-right
            [0.7, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#EFEDFF",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.7], //top-right
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], //bottom-right
            [0.7, 0.4], //bottom-left
            [0.7, 0.7], //top-left
            [0.7, 0.4], //bottom-left
            [1, 0.7], //top-right
            [1, 0.4], // bottom-right
          ],
        },

        {
          type: "area",
          lineWidth: 0,
          color: "#E0FFF3",
          enableMouseTracking: false,
          showInLegend: false,
          data: [
            [1, 0.4], //top-right
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], //bottom-right
            [0.7, 0.2], //bottom-left
            [0.7, 0.4], //top-left
            [0.7, 0.2], //bottom-left
            [1, 0.4], //top-right
            [1, 0.2], // bottom-right
          ],
        },

        // //////////////////////////////////////

        {
          data: outliers,

          type: "scatter",
          name: "Others",
          color: "#E35050",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q2,

          type: "scatter",
          name: "Hard",
          color: "#F86E7F",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q3,

          type: "scatter",
          name: "Easy",
          color: "#456BF1",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q1,

          type: "scatter",
          name: "2015",
          color: "#855CF8",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },

        {
          data: q4,

          type: "scatter",
          name: "Medium",
          color: "#27AE7A ",
          marker: {
            radius: 15,
            symbol: "square",
          },
        },
      ],
    };

    return option;
  };

  getItemAnalysis = async () => {
    try {
      const { course } = this.state;
      const token = Cookies.get("jwt_token");

      if (course !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_COURSES_ITEM_ANALYSIS +
            `?courseId=${course}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.filteringItemAnalysis(response["data"]["result"]);
        } else {
          this.setState({
            itemAnalysis: { q1: [], q2: [], q3: [], q4: [], outliers: [] },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getBloomsLvl = async () => {
    try {
      const { course } = this.state;
      const token = Cookies.get("jwt_token");

      if (course !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_CATEGORIZATION_QSTNS_BLOOM_LVL +
            `?courseId=${course}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ bloomsData: response["data"]["result"] });
          // console.log(response["data"]["result"]);
        } else {
          this.setState({ bloomsData: [] });
        }
      }
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  renderBatchOptions = () => {
    const { batchOptions } = this.state;

    const ans = [];
    try {
      for (const item of batchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBranchOptions = () => {
    const { branchOptions } = this.state;

    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderSemOptions = () => {
    const { semOptions, userType } = this.state;

    const ans = [];
    try {
      if (userType === "FRESHMAN") {
        ans.push(
          <option
            key={semOptions[0]["optionValue"]}
            value={semOptions[0]["optionValue"]}
          >
            {semOptions[0]["optionDesc"]}
          </option>
        );
        ans.push(
          <option
            key={semOptions[1]["optionValue"]}
            value={semOptions[1]["optionValue"]}
          >
            {semOptions[1]["optionDesc"]}
          </option>
        );
      } else {
        for (const item of semOptions) {
          ans.push(
            <option key={item["optionValue"]} value={item["optionValue"]}>
              {item["optionDesc"]}
            </option>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPsosValues = () => {
    const { psoData } = this.state;

    const ans = [];
    try {
      for (let index = 12; index < psoData.length; index++) {
        ans.push(
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[index]?.["poAttvalue"] ?? ""}
          </td>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderPsoData = () => {
    const { psoData, data } = this.state;
    try {
      return (
        <tr className="border border-blue-500 text-[#040C58] font-semibold">
          <th className="border border-blue-500 p-2">
            {data["courseDetails"]["courseShortName"]}
          </th>

          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[0]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[1]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[2]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[3]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[4]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[5]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[6]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[7]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[8]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[9]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[10]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[11]?.["poAttvalue"] ?? ""}
          </td>
          {this.renderDynamicPsosValues()}
        </tr>
      );
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPsos = () => {
    const { psoData } = this.state;
    const ans = [];
    try {
      for (let index = 1; index <= psoData.length - 12; index++) {
        ans.push(<th className="border border-blue-500 p-2">PSO{index}</th>);
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getAttainedCos = () => {
    const { data } = this.state;
    let ans = 0;
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        if (
          data["coAttData"][Object.keys(data["coAttData"])[index]][
            "isCoAchieved"
          ] === "Y"
        ) {
          ans += 1;
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  oAscrollTbClickedLeft = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  oAscrollTbClicked = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  poscrollTbClickedLeft = () => {
    const container = document.getElementById("poscrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  poscrollTbClicked = () => {
    const container = document.getElementById("poscrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Data Found For The Selected Filters To View Attainments & Analysis
      </p>
    </div>
  );

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  renderCiewSeew = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * data["courseWeightages"]["cieW"]}% of <br />
            CIE
          </th>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * data["courseWeightages"]["seeW"]}% of <br />
            SEE
          </th>
        </>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDAw = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * data["courseWeightages"]["directCoW"]}% of DA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderIAw = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * data["courseWeightages"]["indirectCoW"]}% of IA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  questionMapping = () => {
    const { data } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#040C58] `}
          >
            <th className="border border-blue-500 p-1 text-sm">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>
            <td className={`border border-blue-500 p-1 text-[#606981] text-sm`}>
              {
                data["coAttData"][Object.keys(data["coAttData"])[index]][
                  "questnNameList"
                ]
              }
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
            >
              {
                data["coAttData"][Object.keys(data["coAttData"])[index]][
                  "totalCoAtt"
                ]
              }
            </td>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  bloomsCourseName = () => {
    let ans = [];
    const { data, bloomsData } = this.state;

    try {
      ans.push(
        <tr
          className={`${styles["table-th"]} border border-blue-500 text-[#040C58]`}
        >
          <th
            className="border border-blue-500 p-2"
            rowSpan={bloomsData.length + 1}
          >
            {data !== null && data["courseDetails"]["courseShortName"]}
          </th>
        </tr>
      );
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderBloomsTable = () => {
    const { bloomsData } = this.state;

    try {
      let ans = [];

      for (let each of bloomsData) {
        if (each["bloomsLvl"] === "Count") {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-normal">
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["bloomsLvl"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q1Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q2Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q3Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q4Count"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["qNoCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["totalQCount"]}
              </td>
            </tr>
          );
        } else if (each["bloomsLvl"] === "Percent of Questions") {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-bold">
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                % of Questions
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ1"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ2"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ3"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQ4"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["pctQNo"]}%
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {""}
              </td>
            </tr>
          );
        } else {
          ans.push(
            <tr className="border border-blue-500 text-[#040C58] font-normal">
              <td
                className={`${styles["table-td"]} capitalize border border-blue-500 p-2 text-[#606981]`}
              >
                {each["bloomsLvl"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q1LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q2LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q3LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["q4LvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["qNoLvlCount"]}
              </td>
              <td
                className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
              >
                {each["totalLvlCount"]}
              </td>
            </tr>
          );
        }
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      const { data } = this.state;

      if (Cookies.get("jwt_token") !== undefined) {
        const token = Cookies.get("jwt_token");
        const claim = jose.decodeJwt(token);

        this.setState({
          userType: claim["user_type"],
        });

        if (claim["user_type"] === "HOD") {
          // console.log(claim);
          this.setState({ branch: claim["dept_name"] });
        }

        this.getOptions();
        this.getCourseCodes();
        this.getData();
        this.getPsoData();
        data !== null && this.tableStruct();
      }
    }
  }

  changeWeightages = (event) => {
    const { seeWeightages } = this.state;
    const id = event.target.id;

    const [co, template] = id.split("-");
    const data = {
      ...seeWeightages[co],
      [`${template}`]: event.target.value,
    };

    this.setState({ seeWeightages: { ...seeWeightages, [`${co}`]: data } });
  };

  renderSeeWeightagesData = () => {
    const { data, internalData, externalData, seeWeightages } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                >
                  <input
                    id={`CO${index + 1}-${e}`}
                    type="text"
                    className={styles["weightage-input"]}
                    onChange={this.changeWeightages}
                    value={seeWeightages?.[`CO${index + 1}`]?.[e] ?? ""}
                  />
                  <span className="text-[#BBBBBB]">
                    {data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]
                      ? `${
                          data?.["coAttData"]?.[`CO${index + 1}`]?.[
                            "daData"
                          ]?.map((each) => {
                            if (
                              each["assmntCat"] === `Internal` &&
                              each["templateName"] === e
                            ) {
                              return each["pctGtThValue"];
                            }
                            return "";
                          }) ?? ""
                        }`.replaceAll(",", "")
                      : ""}
                  </span>
                </td>
              ))}
            </>

            <>
              {externalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                >
                  <input
                    className={styles["weightage-input"]}
                    onChange={this.changeWeightages}
                    id={`CO${index + 1}-${e}`}
                    type="text"
                    value={seeWeightages?.[`CO${index + 1}`]?.[e] ?? ""}
                  />
                  <span className="text-[#BBBBBB]">
                    {data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]
                      ? `${
                          data?.["coAttData"]?.[`CO${index + 1}`]?.[
                            "daData"
                          ]?.map((each) => {
                            if (
                              each["assmntCat"] === `External` &&
                              each["templateName"] === e
                            ) {
                              return each["pctGtThValue"];
                            }
                            return "";
                          }) ?? ""
                        }`.replaceAll(",", "")
                      : ""}
                  </span>
                </td>
              ))}
            </>
          </tr>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  popUpModal = () => {
    const { popup } = this.state;

    !popup && this.fetchWeightages();
    this.setState({ popup: !popup });
  };

  storeWeightages = async () => {
    const { seeWeightages, course } = this.state;
    this.setState({ isPopupLoading: true });
    const token = Cookies.get("jwt_token");

    const reqBody = [];

    Object.keys(seeWeightages).map((each) => {
      Object.entries(seeWeightages[each]).map((item) => {
        reqBody.push({
          co: each,
          cotemplateWeightage: item[1],
          courseId: course,
          templateName: item[0],
        });
      });
    });

    console.log(reqBody);

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqBody),
    };

    try {
      const res = await fetch(
        apiClient.urls.fatcat.STORE_COURSE_WEIGHTAGES,
        options
      );

      if (res.ok) {
        const response = await res.json();
        if (response["result"] === "OK") {
          alert("Weightages Updated Successfully!");
          await this.fetchWeightages();
          await this.getData();
        } else {
          alert("Changes are not updated!");
        }
      } else {
        alert("Changes are not updated!");
      }
    } catch (err) {
      alert("Changes are not updated!");
      console.log(err);
    }
    this.setState({ isPopupLoading: false });
  };

  fetchWeightages = async () => {
    const { data, internalData, externalData, course } = this.state;
    this.setState({ isPopupLoading: true, seeWeightages: [] });
    const token = Cookies.get("jwt_token");
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const weightagesData = await fetch(
        apiClient.urls.fatcat.COURSE_WEIGHTAGES + `?courseId=${course}`,
        options
      );

      const res = await weightagesData.json();
      if (res["statusCode"] === "OK") {
        const updatedData = {};

        for (
          let index = 1;
          index <= Object.keys(data["coAttData"]).length;
          index++
        ) {
          const dummy = {};
          internalData.map((each) => (dummy[each] = ""));
          externalData.map((each) => (dummy[each] = ""));
          updatedData[`CO${index}`] = dummy;

          res?.["result"]?.[`CO${index}`]?.map((item) => {
            updatedData[`CO${index}`][`${item["templateName"]}`] =
              item?.["coTemplateWeightage"];
          });
        }

        this.setState({ seeWeightages: updatedData });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ isPopupLoading: false });
  };

  renderPopup = () => {
    const { isPopupLoading, internalData, externalData, seeWeightages } =
      this.state;
    return (
      <div className={`${styles["modal"]}`}>
        {isPopupLoading ? (
          <Oval
            height={40}
            width={40}
            color="#3D65F4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3D65F4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <div
            className={`${styles["modal-container"]} ${
              Object.keys(seeWeightages).length < 1 &&
              "flex justify-center items-center"
            }`}
          >
            <table className="mb-2 mt-6 border-solid border-blue-600 border-1">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-2" rowSpan={2}>
                    Course Outcome
                  </th>
                  {internalData.length !== 0 && (
                    <th
                      className="border border-blue-500 p-2"
                      colSpan={internalData.length}
                    >
                      Internal
                    </th>
                  )}

                  {externalData.length !== 0 && (
                    <th
                      className="border border-blue-500 p-2"
                      colSpan={externalData.length}
                    >
                      External
                    </th>
                  )}
                </tr>
                <tr className="text-center text-sm">
                  {internalData.length !== 0 && this.getIntTd()}
                  {externalData.length !== 0 && this.getExtTd()}
                </tr>
                {this.renderSeeWeightagesData()}
              </tbody>
            </table>
          </div>
        )}
        {Object.keys(seeWeightages).length > 0 && (
          <button
            onClick={this.storeWeightages}
            className={styles["update-changes-btn"]}
            type="button"
          >
            Update changes
          </button>
        )}
      </div>
    );
  };

  renderScreen = () => {
    try {
      const { content, internalData, externalData, popup } = this.state;
      const overlayStyle = {
        background: "rgba(0,0,0,0.5)",
        position: "absolute",
      };

      switch (content) {
        case true:
          return (
            <>
              <span id="pdf">
                <div className="flex items-center justify-center">
                  <p className="font-semibold text-sm text-[#606981] text-center mt-8 pb-2 mb-4">
                    Attainment Of Course Outcomes (Cos) Through Direct
                    Assessment (DA) Tools
                  </p>

                  <button
                    onClick={this.popUpModal}
                    className={`${styles["weightages-btn"]} absolute right-8`}
                  >
                    See Weightages
                  </button>

                  <Popup
                    open={popup}
                    onClose={this.popUpModal}
                    {...{ overlayStyle }}
                  >
                    {this.renderPopup()}
                  </Popup>
                </div>

                {content && (
                  <>
                    <div className={styles.table}>
                      <div
                        id="scrollTb"
                        className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                      >
                        <table className="mb-2 border-solid border-blue-600 border-1">
                          <tbody>
                            <tr
                              className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                            >
                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Course Outcome
                              </th>
                              {internalData.length !== 0 && (
                                <th
                                  className="border border-blue-500 p-0"
                                  colSpan={internalData.length}
                                >
                                  Internal
                                </th>
                              )}

                              {externalData.length !== 0 && (
                                <th
                                  className="border border-blue-500 p-0"
                                  colSpan={externalData.length}
                                >
                                  External
                                </th>
                              )}
                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Average <br /> Of CIE
                              </th>
                              {this.renderCiewSeew()}

                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Total Attainment <br /> Through DA (100%)
                              </th>
                            </tr>
                            <tr className="text-center text-sm">
                              {internalData.length !== 0 && this.getIntTd()}
                              {externalData.length !== 0 && this.getExtTd()}
                            </tr>

                            {this.renderCoAtt()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.toggleButtons("scrollTb") && (
                      <span className="flex justify-end">
                        <span
                          onClick={this.scrollTbClickedLeft}
                          className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                        <span
                          onClick={this.scrollTbClicked}
                          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}

                <p className="font-semibold text-sm text-[#606981] text-center mt-10 pb-2 mb-4">
                  Overall Attainment Of Course Outcomes (Co) Through Direct And
                  Indirect Assessment Tools:
                </p>

                {content && (
                  <>
                    <div className={styles.table}>
                      <div
                        id="oAscrollTb"
                        className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                      >
                        <table className="text-center border border-blue-500">
                          <tbody>
                            <tr
                              className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                            >
                              <th className="border border-blue-500 p-2">
                                Course Outcome
                              </th>
                              <th className="border border-blue-500 p-2">
                                CO Attainment through Direct Assessment(DA)
                              </th>
                              {this.renderDAw()}

                              <th className="border border-blue-500 p-2">
                                CO Attainment through Indirect Assessment(IA)
                              </th>
                              {this.renderIAw()}

                              <th className="border border-blue-500 p-2">
                                Total CO Attainment
                              </th>
                              <th className="border border-blue-500 p-2">
                                Target
                              </th>
                              <th className="border border-blue-500 p-2">
                                Attainment Level
                              </th>
                              <th className="border border-blue-500 p-2">
                                Attainment Achieved
                              </th>
                            </tr>

                            {this.renderOverAllAtt()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.toggleButtons("oAscrollTb") && (
                      <span className="flex justify-end">
                        <span
                          onClick={this.oAscrollTbClickedLeft}
                          className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                        <span
                          onClick={this.oAscrollTbClicked}
                          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}
              </span>
              <span id="page4">
                <p className="font-semibold text-sm text-[#606981] text-center mt-10 pb-2 mb-4">
                  Direct Program Outcomes and Program-Specific Outcome
                  Attainment
                </p>
                {content && (
                  <>
                    <div className={styles.table}>
                      <div
                        id="poscrollTb"
                        className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                      >
                        <table className="text-center border border-blue-500">
                          <tbody>
                            <tr
                              className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                            >
                              <th className="border border-blue-500 p-2">
                                Course Name
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO1
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO2
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO3
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO4
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO5
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO6
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO7
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO8
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO9
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO10
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO11
                              </th>
                              <th className="border border-blue-500 p-2">
                                PO12
                              </th>
                              {this.renderDynamicPsos()}
                              {/* <th className="border border-blue-500 p-2">PS01</th>
                    <th className="border border-blue-500 p-2">PS02</th> */}
                            </tr>
                            {this.renderPsoData()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.toggleButtons("poscrollTb") && (
                      <span className="flex justify-end pb-5">
                        <span
                          onClick={this.poscrollTbClickedLeft}
                          className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                        <span
                          onClick={this.poscrollTbClicked}
                          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}
              </span>
              <p className="font-semibold text-sm text-[#606981] text-center mt-10">
                Course Outcomes And Question Grouping
              </p>

              <div className="flex flex-col mt-20 lg:mt-4 w-full overflow-x-auto">
                <table className="text-center border border-blue-500">
                  <tbody>
                    <tr
                      className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                    >
                      <th className="border border-blue-500 p-2">
                        Course Outcome
                      </th>
                      <th className="border border-blue-500 p-2">
                        Question Numbers
                      </th>
                      <th className="border border-blue-500 p-2">
                        Actual CO Attainment
                      </th>
                    </tr>

                    {this.questionMapping()}
                  </tbody>
                </table>
              </div>
            </>
          );
        case false:
          return this.renderEmptyView();
        case "loading":
          return (
            <div className="flex justify-center items-center mt-20 mb-10">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      secValue,
      batch,
      branch,
      sem,
      data,
      course,
      content,
      courseCodes,
      userType,
    } = this.state;

    const options = this.getGraphOptions();
    return (
      <div className={styles["dashboard-periodwisebar-bg"]}>
        <div className="w-full flex flex-row justify-between items-center pr-2">
          <h1 className={styles["course-analysis-title"]}>COURSE ANALYSIS</h1>
          <div className={styles["filters"]}>
            <select
              onChange={this.batchChanged}
              value={batch}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              {this.renderBatchOptions()}
            </select>
            {userType !== "HOD" && (
              <select
                onChange={this.branchChanged}
                value={branch}
                className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
              >
                {this.renderBranchOptions()}
              </select>
            )}

            <select
              onChange={this.semChanged}
              value={sem}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              {this.renderSemOptions()}
            </select>
            {courseCodes.length !== 0 && (
              <select
                onChange={this.courseChanged}
                value={course}
                className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
              >
                {courseCodes.map((each) => (
                  <option key={each["courseId"]} value={each["courseId"]}>
                    {each["courseShortName"]}
                  </option>
                ))}
              </select>
            )}

            <select
              onChange={this.secChanged}
              value={secValue}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              <option value="">All Sections</option>
              {this.renderSectionOptions()}
            </select>
          </div>
        </div>

        <div className="w-full flex flex-row justify-between pr-2 mt-4 border border-r-0 border-l-0 border-t-0 pb-5 border-[#456BF1]">
          <h1 className={styles["course-title"]}>
            COURSE:{" "}
            {data !== null &&
              `${data["courseDetails"]["courseName"]}
                (${data["courseDetails"]["courseShortName"]})`}
          </h1>
          <div className="flex flex-row justify-around items-start w-2/2 font-semibold">
            <h1 className={styles["course-title"]}>
              TOTAL CO&apos;s:{" "}
              {content === true
                ? data["courseDetails"]["numberOfCourseOutcomes"]
                : 0}
            </h1>
            <h1 className={`${styles["course-title"]} ml-12`}>
              TOTAL Attained CO&apos;s: {content ? this.getAttainedCos() : 0}
            </h1>
          </div>
        </div>
        {this.renderScreen()}

        <div className="flex flex-row justify-center w-full pb-5 mt-14">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            {...this.props}
          />
        </div>

        <p className="font-semibold text-sm text-[#606981] text-center mt-10">
          Categorization Of Questions Vs Blooms Level
        </p>

        <div className="flex flex-col mt-20 lg:mt-4 w-full overflow-x-auto mb-8">
          <table className="text-center border-0 border-blue-500">
            <tbody>
              <tr
                className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
              >
                <th className="border border-blue-500 p-2">Course Name</th>
                <th className="border border-blue-500 p-2">
                  Blooms&apos;s Level
                </th>
                <th className="border border-blue-500 p-2">Q1</th>
                <th className="border border-blue-500 p-2">Q2</th>
                <th className="border border-blue-500 p-2">Q3</th>
                <th className="border border-blue-500 p-2">Q4</th>
                <th className="border border-blue-500 p-2">Not In quadrants</th>
                <th className="border border-blue-500 p-2">Total</th>
              </tr>
              {this.bloomsCourseName()}
              {this.renderBloomsTable()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default CoursesAnalysisOutcome;
