import styles from "./index.module.css";
import MappingCoursesList from "./MappingCoursesList";
import { useState, useEffect } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";

const FacultyMappingDetails = (props) => {
  const [filters, updateFilters] = useState({
    regulation: "",
    batch: "",
    branch: "",
    semester: "",
    section: "",
  });

  const { closeButton, id, fetchData, facNumId } = props;

  const [data, updateData] = useState([]);
  const [content, updateContent] = useState("loading");
  const [dropBatchOptions, UpdateBatchOptions] = useState([]);
  const [dropBranchOptions, UpdateBranchOptions] = useState([]);
  const [dropSemOptions, UpdateSemOptions] = useState([]);
  const [dropRegOptions, UpdateRegOptions] = useState([]);
  const [dropSectionOptions, UpdateSectionOptions] = useState([]);
  const [loader, updateLoader] = useState(true);

  const [userType, updateType] = useState("");

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSemOptions(response["data"]["result"]);
        if (userType === "FRESHMAN") {
          updateFilters({
            ...filters,
            semester: response["data"]["result"][0]["optionValue"],
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateRegOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batch = dropBatchOptions.filter(
        (each) => each.optionValue === filters.batch
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSectionOptions(response["data"]["result"]);
      } else {
        UpdateSectionOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSectionOptions();
  }, [filters.batch, filters.branch]);

  const renderSectionOptions = () => {
    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getSemOptions();
      await getBranchOptions();
      await getRegOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    updateLoader(true);
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${filters.branch}&regulation=${filters.regulation}&semester=${filters.semester}&section=${filters.section}&username=${id}`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        updateData(response["data"]["result"]["courseDetailsResponses"]);
        updateContent(true);
      } else {
        updateData([]);
        updateContent(false);
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim["user_type"]);
        if (claim["user_type"] === "HOD") {
          updateFilters({ ...filters, branch: claim["dept_name"] });
        } else {
          updateFilters({ ...filters, branch: "" });
        }
        getOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        getCourses();
      }
    }
  }, [filters]);

  const sectionChanged = (event) => {
    updateFilters({ ...filters, section: event.target.value });
  };

  const regulationChanged = (event) => {
    updateFilters({ ...filters, regulation: event.target.value });
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value });
  };

  const batchChanged = (event) => {
    updateFilters({ ...filters, batch: event.target.value });
  };

  const semesterChanged = (event) => {
    updateFilters({ ...filters, semester: event.target.value });
  };

  const renderEmptyCourses = () => (
    <div className="relative flex flex-col top-1/4 items-center mt-20">
      <img
        src="/assets/cos.svg"
        width={361}
        height={38}
        alt="COURSE OUTCOMES"
      />
      <p className={styles["no-courses-text"]}>
        You Don&apos;t Have Any Courses Created For This Faculty
      </p>
    </div>
  );

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType === "FRESHMAN") {
        ans.push(
          <option
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
          >
            {dropSemOptions[0]["optionDesc"]}
          </option>
        );
        ans.push(
          <option
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
          >
            {dropSemOptions[1]["optionDesc"]}
          </option>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <option key={item["optionValue"]} value={item["optionValue"]}>
              {item["optionDesc"]}
            </option>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderRegOptions = () => {
    const ans = [];
    try {
      for (const item of dropRegOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMapping = async (courseId) => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.DELETE_COURSE_MAPPING +
          `?courseId=${courseId}&facultyId=${facNumId}`,
        options
      );

      if (response.ok === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          await getCourses();
          await fetchData();
          alert("Course Mapping deleted successfully");
        } else {
          alert("Oops! Course Mapping not deleted");
        }
      } else {
        alert("Oops! Course Mapping not deleted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderInitialView = () => {
    try {
      switch (content) {
        case true:
          return data.map((each) => (
            <MappingCoursesList
              item={each}
              key={each.courseId}
              deleteMapping={deleteMapping}
            />
          ));
        case false:
          return renderEmptyCourses();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="overflow-x-hidden">
      <div className="flex flex-row justify-between items-center mr-0 mb-5">
        <h1 className={styles["total-courses"]}>
          TOTAL COURSES : {content ? data.length : 0}
        </h1>
        <div className={styles["filters"]}>
          <select
            value={filters.regulation}
            onChange={regulationChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
          >
            <option value="">All Regulations</option>
            {renderRegOptions()}
          </select>
          <select
            value={filters.batch}
            onChange={batchChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
          >
            <option value="">All Batches</option>
            {renderBatchOptions()}
          </select>
          {userType !== "HOD" && (
            <select
              value={filters.branch}
              onChange={branchChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
            >
              <option value="">All Branches</option>

              {renderBranchOptions()}
            </select>
          )}
          <select
            value={filters.semester}
            onChange={semesterChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
          >
            {!(userType === "FRESHMAN") && (
              <option value="">All Semesters</option>
            )}

            {renderSemOptions()}
          </select>

          <select
            value={filters.section}
            onChange={sectionChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
          >
            <option value="">All Sections</option>

            {renderSectionOptions()}
          </select>
        </div>
      </div>

      {loader ? (
        <div className="flex justify-center mt-10">
          <Oval
            height={50}
            width={50}
            color="#3d65f4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3d65f4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <ul
          className={`${
            !content ? "justify-center" : "justify-between"
          } list-none w-full flex flex-row flex-wrap`}
        >
          {renderInitialView()}
        </ul>
      )}

      <div className="flex justify-end">
        <button
          onClick={closeButton}
          className="font-Lato-normal text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-14 py-1.5 mr-5 mt-4"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default FacultyMappingDetails;
