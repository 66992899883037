import { useState, useEffect } from "react";
import { BiSolidUser } from "react-icons/bi";
import apiClient from "../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import OtpContext from "../../context/OtpContext";
import "../LoginForm/index.css";

const ForgotLink = (props) => {
  const [userInput, updateUserInput] = useState("");
  const [forgot, updateForgot] = useState(false);
  const [otp, updateOtp] = useState("");
  const [code, updateCode] = useState("");

  const codeChanged = (event) => {
    updateCode(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    const { history } = props;
    if (token !== undefined) {
      history.replace("/obe/dashboard");
    }
  }, []);

  const valueChanged = (event) => {
    updateUserInput(event.target.value);
  };

  const loginBtn = forgot ? "login-btn-disabled" : "login-btn";

  const renderView = () => (
    <OtpContext.Consumer>
      {(value) => {
        const { updateVerified, headerChange } = value;

        const otpSubmitted = async (event) => {
          event.preventDefault();
          if (code == otp) {
            updateVerified(userInput);
            const { history } = props;
            history.replace("/forgot-password");
          } else {
            alert("Invalid Otp!");
          }
        };

        const forgotSubmitted = async (event) => {
          event.preventDefault();
          if (forgot === false) {
            updateForgot(true);
            getOTP();
          }
        };

        const getOTP = async () => {
          const token = Cookies.get("jwt_token");

          try {
            const response = await apiClient.get(
              apiClient.urls.fatcat.GET_OTP + `?userName=${userInput}`,
              { Authorization: token }
            );

            const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;

            if (response["data"]["statusCode"] === "OK") {
              const decrypted = CryptoJS.AES.decrypt(
                response["data"]["result"],
                key
              ).toString(CryptoJS.enc.Utf8);

              headerChange(response.headers.authorization.split(" ")[1]);
              updateOtp(decrypted);
            }
            alert(response["data"]["description"]);
          } catch (err) {
            console.log(err);
          }
        };

        return forgot ? (
          <form className="login-form" onSubmit={otpSubmitted}>
            <h1 className="greet-heading">Enter Verification Code</h1>

            <div className="input-bg mt-8">
              <input
                required
                autoComplete="off"
                onChange={codeChanged}
                value={code}
                className="input focus:ring-0"
                type="text"
                placeholder="One Time Password"
              />
            </div>

            <button button type="submit" className="login-btn">
              Verify
            </button>
          </form>
        ) : (
          <form className="login-form" onSubmit={forgotSubmitted}>
            <h1 className="greet-heading">Reset your password</h1>

            <div className="input-bg mt-8">
              <BiSolidUser className="icons" size={30} />
              <input
                required
                autoComplete="off"
                onChange={valueChanged}
                value={userInput}
                className="input focus:ring-0"
                type="text"
                placeholder="Enter User Id"
              />
            </div>

            {forgot ? (
              <p className="error text-blue-800 mt-5">
                If your user name is valid, Otp will be sent to your email.
              </p>
            ) : (
              <button disabled={forgot} type="submit" className={loginBtn}>
                Send Link
              </button>
            )}
          </form>
        );
      }}
    </OtpContext.Consumer>
  );

  return (
    <div className="login-page">
      <div className="form-container">
        <div className="logo">
          <img
            alt="logo"
            src="/assets/logo.svg"
            height={270}
            width={300}
            layout="intrinsic"
            priority
          />
        </div>
        <span className="text">
          <img src="/assets/fatcat-title.svg" width={200} height={45} alt="" />
          <p className="para">Empowering Educational Technology.</p>
          <button
            disabled
            type="button"
            className="read-more-btn cursor-not-allowed"
          >
            Read More
          </button>
        </span>

        <span className="ellip">
          <img
            src="/assets/ellipse.svg"
            width={200}
            height={150}
            alt="ellipse"
          />
        </span>
      </div>

      {renderView()}
    </div>
  );
};

export default ForgotLink;
