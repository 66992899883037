import { Link, withRouter } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FiPlusCircle } from "react-icons/fi";
import CoursesList from "./CoursesList";
import styles from "./index.module.css";
import CreateCourse from "./CreateCourse";
import CourseStructure from "./CourseStructure";
import ModifyWeightages from "./ModifyWeightages";
import NewCourseStructure from "./NewCourseStructure";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";
import CourseOutcome from "../../../FacultyModule/CourseOutcome";
import QuestionPaperMapping from "../../../FacultyModule/QuestionPaperMapping";
import FeedBack from "../../../FacultyModule/FeedBack";
import SearchContext from "../../../../context/SearchContext";

const ManageCourses = (props) => {
  const [isOpened, updateOpened] = useState(false);
  const [loader, updateLoader] = useState(true);

  const [active, updateActive] = useState(null);
  const [inputDataActive, updateInputDataActive] = useState(null);
  const [dataId, updateDataId] = useState(null);
  const { search, updateSearch } = useContext(SearchContext);

  const [filters, updateFilters] = useState({
    regulation: "",
    batch: "",
    branch: "",
    semester: "",
    section: "",
  });

  const [data, updateData] = useState([]);
  const [dataCross, updateDataCross] = useState([]);
  const [content, updateContent] = useState("loading");
  const [dropBatchOptions, UpdateBatchOptions] = useState([]);
  const [dropBranchOptions, UpdateBranchOptions] = useState([]);
  const [dropSemOptions, UpdateSemOptions] = useState([]);
  const [dropSectionOptions, UpdateSectionOptions] = useState([]);
  const [dropRegOptions, UpdateRegOptions] = useState([]);
  const [userType, updateType] = useState("");
  const [item, updateItem] = useState(null);
  const [courseData, updateCourseData] = useState(null);

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSemOptions(response["data"]["result"]);
        if (userType === "FRESHMAN") {
          updateFilters({
            ...filters,
            semester: response["data"]["result"][0]["optionValue"],
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRegOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=regulation_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateRegOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getSemOptions();
      await getBranchOptions();
      await getRegOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const getCrossCourses = async () => {
    const token = Cookies.get("jwt_token");
    try {
      if (userType === "HOD") {
        const responseCross = await apiClient.get(
          apiClient.urls.fatcat.OBE_MANAGE_COURSES_CROSS_BRANCH +
            `?batch=${filters.batch}&branch=${filters.branch}&regulation=${filters.regulation}&semester=${filters.semester}&section=${filters.section}`,
          { Authorization: token }
        );

        if (responseCross["data"]["statusCode"] === "OK") {
          updateContent(true);
          updateDataCross(
            responseCross["data"]["result"]["courseDetailsResponses"]
          );
        } else {
          updateDataCross([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    updateLoader(true);
    const token = Cookies.get("jwt_token");

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${filters.branch}&regulation=${
            filters.regulation
          }&semester=${
            userType === "FRESHMAN" && filters.semester === ""
              ? "1"
              : filters.semester
          }&section=${filters.section}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateContent(true);
        updateData(response["data"]["result"]["courseDetailsResponses"]);
      } else {
        updateContent(false);
        updateData([]);
      }

      await getCrossCourses();
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim["user_type"]);

        if (claim["user_type"] === "HOD") {
          updateFilters({ ...filters, branch: claim["dept_name"] });
        } else {
          updateFilters({ ...filters, branch: "" });
        }
        getOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        if (userType !== "") {
          getCourses();
        }
      }
    }
  }, [filters]);

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batch = dropBatchOptions.filter(
        (each) => each.optionValue === filters.batch
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSectionOptions(response["data"]["result"]);
      } else {
        UpdateSectionOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSectionOptions();
  }, [filters.batch, filters.branch]);

  const regulationChanged = (event) => {
    updateFilters({ ...filters, regulation: event.target.value });
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value });
  };

  const batchChanged = (event) => {
    updateFilters({ ...filters, batch: event.target.value });
  };

  const semesterChanged = (event) => {
    updateFilters({ ...filters, semester: event.target.value });
  };

  const sectionChanged = (event) => {
    updateFilters({ ...filters, section: event.target.value });
  };

  const { match } = props;
  const { path } = match;

  const closeBtn = () => {
    updateOpened(false);
    updateActive(null);
    updateInputDataActive(null);
    updateDataId(null);
    updateItem(null);
    updateCourseData(null);
  };

  const openBtn = () => {
    updateOpened(true);
    createCourseBtn();
  };

  const openInputBtn = (id) => {
    updateOpened(true);
    updateDataId(id);
  };

  const itemUpdate = (item) => {
    updateItem(item);
    newCourseBtn();
  };

  const deleteCourse = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_DELETE_COURSE + `?courseId=${id}`,
        options
      );

      if (response.ok === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          await getCourses();
          await getCrossCourses();
          alert("Course deleted successfully");
        } else {
          alert("Oops! Course not deleted");
        }
      } else {
        alert("Oops! Course not deleted");
      }
      await getCrossCourses();
    } catch (err) {
      console.log(err);
    }
  };

  const updateCourse = async (item) => {
    try {
      updateActive(1);
      updateOpened(true);
      updateCourseData(item);
    } catch (err) {
      console.log(err);
    }
  };

  const renderView = () => {
    try {
      if (inputDataActive !== null) {
        const coursesCombined = [...data, ...dataCross];
        const ans = coursesCombined.filter((each) => each.courseId == dataId);

        switch (inputDataActive) {
          case 1:
            return (
              <CourseOutcome item={ans} cls={closeBtn} reload={getCourses} />
            );
          case 2:
            return (
              <QuestionPaperMapping
                cls={closeBtn}
                item={ans}
                reload={getCourses}
              />
            );

          case 3:
            return <FeedBack item={ans} cls={closeBtn} reload={getCourses} />;

          default:
            break;
        }
      } else {
        switch (active) {
          case 1:
            return (
              <CreateCourse
                item={courseData}
                reload={getCourses}
                newCourseBtn={newCourseBtn}
                closeBtn={closeBtn}
              />
            );
          case 2:
            return <CourseStructure itemUpdate={itemUpdate} />;
          case 3:
            return <NewCourseStructure itemUpdate={itemUpdate} edit={item} />;
          case 4:
            return <ModifyWeightages />;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const createCourseBtn = () => {
    updateActive(1);
    updateItem(null);
  };

  const courseStrucBtn = () => {
    updateActive(2);
    updateItem(null);
  };

  const newCourseBtn = () => {
    updateActive(3);
  };

  const modifyBtn = () => {
    const val = active === 4 ? 1 : 4;

    updateActive(val);
  };

  const renderEmptyCourses = () => (
    <div className="relative flex flex-col top-1/4 items-center min-h-screen">
      <img
        src="/assets/cos.svg"
        width={361}
        height={38}
        alt="COURSE OUTCOMES"
      />
      <p className={styles["no-courses-text"]}>
        You Don&apos;t Have Any Courses Created
      </p>
      <button onClick={openBtn} className={styles["cc-btn"]}>
        Create Courses
      </button>
    </div>
  );

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType === "FRESHMAN") {
        ans.push(
          <option
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
          >
            {dropSemOptions[0]["optionDesc"]}
          </option>
        );
        ans.push(
          <option
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
          >
            {dropSemOptions[1]["optionDesc"]}
          </option>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <option key={item["optionValue"]} value={item["optionValue"]}>
              {item["optionDesc"]}
            </option>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionOptions = () => {
    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderRegOptions = () => {
    const ans = [];
    try {
      for (const item of dropRegOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const coBtn = async (id) => {
    updateInputDataActive(1);
    openInputBtn(id);
  };

  const quesPaperBtn = async (id) => {
    updateInputDataActive(2);
    openInputBtn(id);
  };

  const feedbackBtn = async (id) => {
    updateInputDataActive(3);
    openInputBtn(id);
  };

  const renderInitialView = () => {
    try {
      switch (content) {
        case true:
          const ans = [...data, ...dataCross];

          const filteredSearch = ans.filter(
            (item) =>
              item["courseName"] !== null &&
              item["courseName"].toLowerCase().includes(search.toLowerCase())
          );
          return filteredSearch.map((each) => (
            <CoursesList
              deleteCourse={deleteCourse}
              updateCourse={updateCourse}
              item={each}
              key={each.courseId}
              coBtn={coBtn}
              quesPaperBtn={quesPaperBtn}
              feedbackBtn={feedbackBtn}
            />
          ));

        case false:
          return renderEmptyCourses();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div
          onClick={preventBubbling}
          className={
            inputDataActive === null
              ? styles["overlay-card"]
              : styles["overlay-card-input-data"]
          }
        >
          {inputDataActive === null && (
            <>
              <span className="flex flex-row justify-between">
                <h1 className={styles["overlay-title"]}>
                  {active === 4
                    ? "Weightages & PO IDA"
                    : "Enter Course Details"}
                </h1>
                <span className="flex items-center">
                  {courseData === null && (
                    <button
                      onClick={modifyBtn}
                      className={
                        isOpened
                          ? "flex flex-row justify-center items-center text-[#456BF1] rounded-3xl w-9 h-9 p-1 mr-5"
                          : "hidden"
                      }
                    >
                      <MdOutlineSettings
                        className={`${active === 4 && "transform rotate-90"}`}
                        size={26}
                      />
                    </button>
                  )}
                  <RxCross2
                    onClick={closeBtn}
                    size={26}
                    className="cursor-pointer text-[#456bf1]"
                  />
                </span>
              </span>
              {active !== 4 && (
                <div className="bg-[#FFFFFF] p-5 mt-4">
                  <span className="flex flex-row">
                    <button
                      onClick={createCourseBtn}
                      type="button"
                      className={`hover:text-[#3f4451] ${
                        active === 1
                          ? styles["overlay-tab-active"]
                          : styles["overlay-tab"]
                      } mb-5 mr-12`}
                    >
                      Create Course
                    </button>

                    {courseData === null && (
                      <>
                        <button
                          onClick={newCourseBtn}
                          type="button"
                          className={`hover:text-[#3f4451] ${
                            active === 3
                              ? styles["overlay-tab-active"]
                              : styles["overlay-tab"]
                          } mb-5 mr-12`}
                        >
                          Create New Course Structure
                        </button>
                        <button
                          onClick={courseStrucBtn}
                          type="button"
                          className={`hover:text-[#3f4451] ${
                            active === 2
                              ? styles["overlay-tab-active"]
                              : styles["overlay-tab"]
                          } mb-5`}
                        >
                          Saved Structures
                        </button>
                      </>
                    )}
                  </span>
                </div>
              )}
            </>
          )}

          {renderView()}
        </div>
      </div>

      <div className="ml-7">
        <div className="flex flex-row items-center mt-24">
          <Link to="/obe/courses/managecourses">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/courses/managecourses"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-2 text-[#040C58]  underline-offset-8 decoration-2 cursor-pointer`}
            >
              MANAGE COURSES
            </h1>
          </Link>
          {/* <Link to="/obe/courses/analysis">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/courses/analysis"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-6 mb-2 text-[#040C58]  underline-offset-8 decoration-2 cursor-pointer`}
            >
              ANALYSIS
            </h1>
          </Link> */}

          <Link to="/obe/courses/attainments">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/courses/analysis"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-6 mb-2 text-[#040C58]  underline-offset-8 decoration-2 cursor-pointer`}
            >
              ATTAINMENTS
            </h1>
          </Link>
        </div>
        <div className="flex flex-row justify-between items-center mt-4 mr-10 mb-1">
          <h1 className={styles["total-courses"]}>
            TOTAL COURSES : {content ? data.length + dataCross.length : 0}
          </h1>
          <div className={styles["filters"]}>
            <select
              value={filters.regulation}
              onChange={regulationChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
            >
              <option value="">All Regulations</option>
              {renderRegOptions()}
            </select>

            <select
              value={filters.batch}
              onChange={batchChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
            >
              <option value="">All Batches</option>
              {renderBatchOptions()}
            </select>

            {!(userType === "HOD") && (
              <select
                value={filters.branch}
                onChange={branchChanged}
                className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
              >
                <option value="">All Branches</option>

                {renderBranchOptions()}
              </select>
            )}
            <select
              value={filters.semester}
              onChange={semesterChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-3 p-2 pr-5`}
            >
              {!(userType === "FRESHMAN") && (
                <option value="">All Semesters</option>
              )}

              {renderSemOptions()}
            </select>

            <select
              value={filters.section}
              onChange={sectionChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-2 p-2 pr-5`}
            >
              <option value="">All Sections</option>

              {renderSectionOptions()}
            </select>

            <span
              className={`${styles["info-btns"]} hover:bg-[#456bf1] hover:text-[#F4F9FF]`}
              onClick={openBtn}
            >
              <button type="button" className="flex items-center">
                <span className="mr-2">
                  <FiPlusCircle
                    size={18}
                    className="hover:bg-[#456bf1] hover:text-[#F4F9FF]"
                  />
                </span>
                Create New Course
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className={styles["dashboard-periodwisebar-bg"]}>
        {loader ? (
          <div className="flex justify-center mt-10 pb-5">
            <Oval
              height={50}
              width={50}
              color="#3d65f4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3d65f4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <ul
            className={`${
              !content && "justify-center"
            } list-none w-full flex flex-row flex-wrap ml-1`}
          >
            {renderInitialView()}
          </ul>
        )}
      </div>
    </>
  );
};

export default withRouter(ManageCourses);
