/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import styles from "./index.module.css";
import apiClient from "../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
import { RxCross2 } from "react-icons/rx";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import CoPoMapping from "../CoPoMapping";
import Popup from "reactjs-popup";

const descDummy = [];

const actionVerbs = {
  L1: [
    "arrange",
    "define",
    "describe",
    "duplicate",
    "identity",
    "label",
    "list",
    "match",
    "memorize",
    "name",
    "order",
    "outline",
    "recall",
    "recognize",
    "relate",
    "repeat",
    "reproduce",
    "select",
    "show",
    "state",
  ],
  L2: [
    "classify",
    "convert",
    "defend",
    "describe",
    "discuss",
    "distinguish",
    "estimate",
    "explain",
    "express",
    "extend",
    "generalize",
    "give examples",
    "identify",
    "indicate",
    "infer",
    "locate",
    "paraphrase",
    "predict",
    "recognize",
    "report",
    "restate",
    "review",
    "rewrite",
    "select",
    "summarize",
    "translate",
  ],
  L3: [
    "apply",
    "change",
    "choose",
    "compute",
    "construct",
    "demonstrate",
    "discover",
    "dramatize",
    "employ",
    "illustrate",
    "implement",
    "interpret",
    "manipulate",
    "modify",
    "operate",
    "practice",
    "predict",
    "prepare",
    "produce",
    "relate",
    "schedule",
    "show",
    "sketch",
    "solve",
    "translate",
    "use",
    "write",
  ],
  L4: [
    "analyze",
    "appraise",
    "calculate",
    "categorize",
    "compare",
    "compute",
    "contrast",
    "criticize",
    "differentiate",
    "distinguish",
    "examine",
    "experiment",
    "explore",
    "identify",
    "illustrate",
    "infer",
    "model",
    "outline",
    "point out",
    "question",
    "relate",
    "select",
    "separate",
    "subdivide",
    "test",
  ],
  L5: [
    "appraise",
    "argue",
    "assess",
    "attach",
    "choose",
    "compare",
    "compute",
    "conclude",
    "contrast",
    "defend",
    "describe",
    "estimate",
    "evaluate",
    "explain",
    "interpret",
    "judge",
    "justify",
    "measure",
    "predict",
    "rate",
    "relate",
    "revise",
    "score",
    "select",
    "summarize",
    "support",
    "value",
  ],
  L6: [
    "arrange",
    "assemble",
    "categorize",
    "collect",
    "combine",
    "comply",
    "compose",
    "construct",
    "create",
    "design",
    "develop",
    "devise",
    "explain",
    "formulate",
    "generate",
    "manage",
    "organize",
    "plan",
    "prepare",
    "propose",
    "rearrange",
    "reconstruct",
    "relate",
    "reorganize",
    "revise",
    "rewrite",
    "set up",
    "summarize",
    "synthesize",
    "tell",
    "write",
  ],
};

const CourseOutcome = (props) => {
  const { reload, cls } = props;
  const [justificationScreen, updateJustificationScreen] = useState(false);
  const [created, updateCreated] = useState(false);
  const [articulation, updateArticulation] = useState(false);
  const [data, updateData] = useState({});
  const [rubCount, updateRubCount] = useState(0);
  const [art, updateArt] = useState([]);
  const [desc, updateDesc] = useState(descDummy);
  const [psos, updatePsos] = useState([]);
  const [cosOptions, updateCosOptions] = useState([]);
  const [defRub, updateDefRub] = useState([]);
  const [rubdropdownsSelected, rubsetDropdownsSelected] = useState([[]]);
  const [isLoading, updateLoading] = useState(false);
  const [piLvl, updatePiLvl] = useState(null);
  const [selected, updateSelected] = useState(null);
  const [coCount, updateCoCount] = useState(0);
  const [apiHit, updateApiHit] = useState(0);
  const [activeLvl, updateActiveLvl] = useState("L1");
  const [verbs, updateVerbs] = useState({
    L1: "",
    L2: "",
    L3: "",
    L4: "",
    L5: "",
    L6: "",
  });
  const [verbFocussed, updateVerbFocussed] = useState(false);
  const [coSubmitStatus, updateCoSubmitStatus] = useState(null);
  const [coTemp, updateCoTemp] = useState(false);
  const [editCos, updateEditCos] = useState(false);
  const [editLoad, updateEditLoad] = useState(false);

  const [popUp, updatePop] = useState(false);
  const [delCo, updateDelCo] = useState(null);

  const addCo = async () => {
    try {
      updateLoading(true);
      const token = Cookies.get("jwt_token");
      const coNames = desc.map((each) => each.coName);

      if (apiHit > 0) {
        const poList = [];

        for (let index = 1; index <= desc.length; index++) {
          for (let each = 1; each <= 12; each++) {
            const inputEl = document.getElementById(`co${index}Po${each}`);
            poList.push({
              po: `PO${each}`,
              updatedWeight: inputEl["value"],
              co: `${coNames[index - 1]}`,
            });
          }

          for (let each = 1; each <= psos.length; each++) {
            const inputEl = document.getElementById(
              `co${index}${psos?.[each - 1]?.["po"]}`
            );

            poList.push({
              po: `${psos?.[each - 1]?.["po"]}`,
              updatedWeight: inputEl["value"],
              co: `${coNames[index - 1]}`,
            });
          }
        }

        const reqBody = poList;
        // console.log(reqBody);

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(reqBody),
        };

        // rubrics

        const rubricList = [];

        if (data["courseHavRub"].toLowerCase() === "y") {
          for (let index = 1; index <= rubCount; index++) {
            const coData = [];

            for (let each of rubdropdownsSelected[index - 1]) {
              coData.push(each.name);
            }

            const desc = document.getElementById(`rubric${index}Description`);

            rubricList.push({
              coList: coData.join(),
              rubricDesc: desc["value"],
              rubricNo: `r${index}`,
            });
          }
        }

        // console.log(reqBody);

        const optionsRubrics = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(rubricList),
        };

        const response = await fetch(
          apiClient.urls.fatcat.OBE_UPDATE_ARTICULATION_MATRIX_ONLY +
            `?courseId=${data["courseId"]}`,
          options
        );

        const responseRubrics = await fetch(
          apiClient.urls.fatcat.OBE_UPDATE_RUBRICS +
            `?courseId=${data["courseId"]}`,
          optionsRubrics
        );

        if (response["ok"] === true && responseRubrics["ok"] === true) {
          const res = await response.json();
          const resRubrics = await responseRubrics.json();

          if (res["result"] === "OK" && resRubrics["statusCode"] === "OK") {
            updateCreated(true);
            reload();
          } else {
            alert(res["description"]);
          }
        } else {
          alert("Data not inserted");
        }
      } else {
        const matrixList = [];
        for (let index = 1; index <= desc.length; index++) {
          // const level = document.getElementById(`co${index}Level`);
          const levelData = [];

          for (let each in verbs) {
            if (verbs[each] !== "") {
              levelData.push(each);
            }
          }

          // for (const each of level) {
          //   if (each.selected) {
          //     levelData.push(each.value);
          //   }
          // }
          const desc = document.getElementById(`CO${index}Desc`);

          const poList = [];
          for (let each = 1; each <= 12; each++) {
            const inputEl = document.getElementById(`co${index}Po${each}`);
            inputEl["value"] !== "" &&
              inputEl["value"] !== null &&
              poList.push({ poNo: `PO${each}`, weight: inputEl["value"] });
          }

          const psoList = [];
          for (let each = 1; each <= psos.length; each++) {
            const inputEl = document.getElementById(
              `co${index}${psos?.[each - 1]?.["po"]}`
            );
            inputEl["value"] !== "" &&
              inputEl["value"] !== null &&
              psoList.push({
                psoNo: `${psos?.[each - 1]?.["po"]}`,
                weight: inputEl["value"],
              });
          }

          matrixList.push({
            bloomTaxLvl: levelData.join(),
            coDescription: desc["value"],
            coName: `CO${index}`,
            poNameAndWeightList: poList,
            psoNameAndWeightList: psoList,
          });
        }

        const rubricList = [];

        if (data["courseHavRub"].toLowerCase() === "y") {
          for (let index = 1; index <= rubCount; index++) {
            // const co = document.getElementById(`rubric${index}Level`);
            const coData = [];

            for (let each of rubdropdownsSelected[index - 1]) {
              coData.push(each.name);
            }

            // for (const each of co) {
            //   if (each.selected) {
            //     coData.push(each.value);
            //   }
            // }
            const desc = document.getElementById(`rubric${index}Description`);

            rubricList.push({
              cosList: coData.join(),
              rubricDesc: desc["value"],
              rubricNumber: `r${index}`,
            });
          }
        }

        const reqBody = {
          coPoArticulationMatrixList: matrixList,
          courseDetailsId: data["courseId"],
          rubricsCoMappingMatrixList: rubricList,
        };
        // console.log(reqBody);

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(reqBody),
        };

        const response = await fetch(
          apiClient.urls.fatcat.OBE_FACULTY_CREATE_CO_OUTCOME,
          options
        );

        // console.log(response);

        if (response["ok"] === true) {
          const res = await response.json();
          if (res["statusCode"] === "OK") {
            updateCreated(true);
            reload();
          } else {
            alert(res["description"]);
          }
        } else {
          alert("Data not inserted");
        }
      }
      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const rubhandleDropdownChange = (index, selectedList) => {
    const updatedDropdowns = [...rubdropdownsSelected];
    updatedDropdowns[index] = selectedList;
    rubsetDropdownsSelected(updatedDropdowns);
  };

  const renderMatrix = () => {
    const ans = [];
    try {
      for (let index = 1; index <= desc.length; index++) {
        data["coCreated"] !== null &&
        data["coCreated"].toLowerCase() === "y" &&
        art.length !== 0
          ? ans.push(
              <tr className="border border-blue-500 text-[#606981] font-medium p-0">
                <th
                  className={`${styles["labels"]} border border-blue-500 p-0 px-2 py-0`}
                >
                  {`CO${index}`}
                </th>
              </tr>
            )
          : ans.push(
              <tr className="border border-blue-500 text-[#606981] font-medium p-0">
                <th
                  className={`${styles["labels"]} border border-blue-500 p-0 px-2 py-0`}
                >
                  {`CO${index}`}
                </th>
              </tr>
            );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const editCosBtn = (event) => {
    updateEditCos(true);
  };

  const saveCosBtn = async () => {
    try {
      updateEditLoad(true);
      const reqBody = [];
      for (let i of desc) {
        reqBody.push({
          bloomsTaxLvlStr: i["bloomsLvl"],
          co: i["coName"],
          coDesc: i["coDesc"],
        });
      }

      // console.log(reqBody);

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_UPDATE_COURSE_OUTCOMES +
          `?courseId=${data["courseId"]}&noOfCos=${desc.length}`,
        options
      );

      const res = await response.json();
      if (res["result"] === "OK") {
        alert("Cos updated");
        updateEditCos(false);
        getDescCo(data["courseId"]);
        reload();
      } else {
        alert("COs not updated");
      }

      updateEditLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeCos = (event) => {
    const coStatName = event.target.id.replace("Desc", "");
    const coStatVal = event.target.value;
    const ans = desc.map((each) => {
      if (each["coName"] === coStatName) {
        return { ...each, coDesc: coStatVal };
      } else {
        return each;
      }
    });
    updateDesc(ans);
  };

  const openModal = async (event) => {
    updateDelCo(event.target.id);
    updatePop(true);
  };

  const closeModal = async () => {
    updatePop(false);
    // updateDelCo(null);
  };

  const coDelete = async () => {
    const token = Cookies.get("jwt_token");

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.DELETE_COS +
          `?coList=${delCo}&courseId=${data["courseId"]}&noOfCos=${
            desc.length - 1
          }`,
        options
      );

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          closeModal();
          alert("CO deleted successfully");

          await getDescCo(data["courseId"]);
          reload();
          cls();
        } else {
          alert("CO not deleted");
        }
      } else {
        alert("CO not deleted");
      }
    } catch (e) {
      alert("CO not deleted");
      console.log(e);
    }
  };

  const renderPopup = () => {
    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you want to delete the Course Outcome ?
        </p>

        <span>
          <button
            onClick={closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={coDelete}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  const renderMatrixCoStatements = () => {
    const ans = [];
    const overlayStyle = { background: "rgba(0,0,0,0.2)" };

    try {
      for (let index = 1; index <= desc.length; index++) {
        ans.push(
          <li className="flex items-center w-full text-[#606981] bg-[#ffffff] font-medium p-0 pl-6 mb-3">
            <span className="flex flex-col w-full">
              <p
                className={`${styles["co-statement-text"]} mb-4 mt-4 flex items-center text-[#8F949F] w-full mr-1`}
              >
                CO{index} Statement:
                {/* {data["courseCode"]}.{index} Statement: */}
              </p>
              {!editCos ? (
                <p
                  className={`${styles["filter-select-desc"]} bg-[#fff] border border-0 border-b w-full p-0 m-0 flex break-words`}
                >
                  {desc[index - 1]["coDesc"]}
                </p>
              ) : (
                <input
                  onChange={editCos ? changeCos : null}
                  id={`CO${index}Desc`}
                  type="text"
                  className={`${styles["filter-select-desc"]} bg-[#fff] border border-0 border-b w-full p-0 m-0 flex break-words`}
                  value={desc[index - 1]["coDesc"]}
                />
              )}
            </span>

            {/* <span className="w-1/12 flex justify-end mr-2"> */}
            <Popup
              open={popUp}
              closeOnDocumentClick={false}
              onClose={closeModal}
              {...{ overlayStyle }}
            >
              {renderPopup()}
            </Popup>
            <MdDeleteOutline
              onClick={openModal}
              id={desc[index - 1]["coName"]}
              className="text-red-600 cursor-pointer w-1/12 flex justify-end mr-2"
              size={20}
            />
            {/* </span> */}
          </li>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getDefaultRubrics = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.DEFAULT_RUBRICS,
        { Authorization: token }
      );
      if (response["data"]["statusCode"] === "OK") {
        updateDefRub(response["data"]["result"]);
        // console.log(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const rubricPreOptions = () => {
    const ans = [];
    try {
      for (const item of defRub) {
        ans.push(
          <option key={item["rubricNo"]} value={item["rubricName"]}>
            {item["rubricName"]}
          </option>
        );
      }
    } catch (err) {
      console.log(err);
    }

    // console.log(ans);
    return ans;
  };

  const renderRubrics = () => {
    const ans = [];

    try {
      const cnt = rubCount;

      for (let index = 1; index <= cnt; index++) {
        data["coCreated"] !== null &&
        data["coCreated"].toLowerCase() === "y" &&
        art.length !== 0
          ? ans.push(
              <tr className="border border-blue-500 text-[#606981] font-medium p-0">
                <th
                  className={`${styles["labels"]} border border-blue-500 p-0 w-1/4 px-2 py-2`}
                >
                  {`R${index}`}
                </th>
                <th className="border border-blue-500 p-0 w-2/4 py-2">
                  {data["courseType"] === "epic" ? (
                    <input
                      defaultValue={
                        art?.["rubricsCoMappingMatrixList"]?.[index - 1]?.[
                          "rubricDesc"
                        ] ?? ""
                      }
                      id={`rubric${index}Description`}
                      type="text"
                      className={`${styles["filter-select"]} focus:ring-0 w-10/12`}
                      placeholder={`Rubric${index} Description`}
                    />
                  ) : (
                    <select
                      defaultValue={
                        art?.["rubricsCoMappingMatrixList"]?.[index - 1]?.[
                          "rubricDesc"
                        ] ?? ""
                      }
                      id={`rubric${index}Description`}
                      className={`${styles["label-box-rub"]} rounded border-slate-300 focus:ring-0`}
                    >
                      <option value="">SELECT</option>
                      {rubricPreOptions()}
                    </select>
                  )}
                </th>
                <th className="border border-blue-500 p-0 px-2 py-2 w-2/6">
                  {/* <input
                    value={
                      art["rubricsCoMappingMatrixList"][index - 1]["cosList"]
                    }
                    id={`rubric${index}Level`}
                    type="text"
                    className={`${styles["filter-select"]} focus:ring-0 w-10/12`}
                  /> */}

                  <Multiselect
                    key={`rubric${index}Level`}
                    className={styles["react-select"]}
                    showCheckbox
                    hideSelectedList
                    avoidHighlightFirstOption={true}
                    options={cosOptions} // Options to display in the dropdown
                    selectedValues={rubdropdownsSelected[index - 1]} // Preselected value to persist in dropdown
                    onSelect={(selectedList) =>
                      rubhandleDropdownChange(index - 1, selectedList)
                    }
                    onRemove={(selectedList) =>
                      rubhandleDropdownChange(index - 1, selectedList)
                    }
                    displayValue="name" // Property name to display in the dropdown options
                  />
                </th>
              </tr>
            )
          : ans.push(
              <tr className="border border-blue-500 text-[#606981] font-medium p-0">
                <th
                  className={`${styles["labels"]} border border-blue-500 p-0 w-1/4 px-2 py-2`}
                >
                  {`R${index}`}
                </th>
                <th className="border border-blue-500 p-0 w-2/4 py-2">
                  {data["courseType"] === "epic" ? (
                    <input
                      id={`rubric${index}Description`}
                      type="text"
                      className={`${styles["filter-select"]} focus:ring-0 w-10/12`}
                      placeholder={`Rubric${index} Description`}
                    />
                  ) : (
                    <select
                      id={`rubric${index}Description`}
                      className={`${styles["label-box-rub"]} rounded border-slate-300 focus:ring-0`}
                    >
                      <option value="">SELECT</option>
                      {rubricPreOptions()}
                    </select>
                  )}
                </th>
                <th className="border border-blue-500 p-0 px-2 py-2 w-2/6">
                  <Multiselect
                    key={`rubric${index}Level`}
                    className={styles["react-select"]}
                    showCheckbox
                    hideSelectedList
                    avoidHighlightFirstOption={true}
                    options={cosOptions} // Options to display in the dropdown
                    selectedValues={rubdropdownsSelected[index - 1]} // Preselected value to persist in dropdown
                    onSelect={(selectedList) =>
                      rubhandleDropdownChange(index - 1, selectedList)
                    }
                    onRemove={(selectedList) =>
                      rubhandleDropdownChange(index - 1, selectedList)
                    }
                    displayValue="name" // Property name to display in the dropdown options
                  />
                </th>
              </tr>
            );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const renderPos = () => {
    const ans = [];
    try {
      for (let index = 1; index <= desc.length; index++) {
        const posList = [];
        for (let each = 1; each <= 12; each++) {
          data["coCreated"] !== null &&
          data["coCreated"].toLowerCase() === "y" &&
          art.length !== 0
            ? posList.push(
                <td className="py-6 text-center">
                  {piLvl === null ? (
                    <select
                      defaultValue={
                        selected[`CO${index}PO${each}`]["weightage"]
                      }
                      id={`co${index}Po${each}`}
                      className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                    >
                      <option value="">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  ) : (
                    <select
                      defaultValue={
                        piLvl[`CO${index}`][`PO${each}`]["poWeight"]
                      }
                      id={`co${index}Po${each}`}
                      className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                    >
                      <option value="">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  )}
                </td>
              )
            : posList.push(
                <td className="py-6 text-center">
                  <select
                    defaultValue={
                      piLvl !== null &&
                      piLvl[`CO${index}`][`PO${each}`]["poWeight"]
                    }
                    id={`co${index}Po${each}`}
                    className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                  >
                    <option value="">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </td>
              );
        }

        for (let each = 1; each <= psos.length; each++) {
          data["coCreated"] !== null &&
          data["coCreated"].toLowerCase() === "y" &&
          art.length !== 0
            ? posList.push(
                <td className="py-6 text-center">
                  <select
                    defaultValue={
                      selected[`CO${index}${psos?.[each - 1]?.["po"]}`][
                        "weightage"
                      ]
                    }
                    id={`co${index}${psos?.[each - 1]?.["po"]}`}
                    className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                  >
                    <option value="">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </td>
              )
            : posList.push(
                <td className="py-6 text-center">
                  <select
                    id={`co${index}${psos?.[each - 1]?.["po"]}`}
                    className={`${styles["label-box"]} rounded border-slate-300 focus:ring-0`}
                  >
                    <option value="">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </td>
              );
        }

        ans.push(
          <>
            <tr className="text-[#040C58] border border-t-0 border-l-0 border-blue-500">
              {posList}
            </tr>
          </>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const getSelected = () => {
    let selectedData = {};

    for (let index = 1; index <= desc.length; index++) {
      for (let each = 1; each <= 12; each++) {
        selectedData[`CO${index}PO${each}`] = {
          weightage: "",
          coTblId: "",
        };
      }

      for (let each = 1; each <= psos.length; each++) {
        selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
          weightage: "",
          coTblId: "",
        };
      }
    }
    const coNames = desc.map((each) => each.coName);

    for (let index = 1; index <= desc.length; index++) {
      if (data["courseHavRub"].toLowerCase() === "y") {
        for (let each = 1; each <= 12; each++) {
          for (let item of art.coPoMappingList) {
            if (
              item["coName"] === `CO${index}` &&
              item["poName"] === `PO${each}`
            ) {
              selectedData[`CO${index}PO${each}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }

        for (let each = 1; each <= psos.length; each++) {
          for (let item of art.coPoMappingList) {
            if (
              item["coName"] === `CO${index}` &&
              item["psoName"] === `${psos?.[each - 1]?.["po"]}`
            ) {
              selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }
      } else {
        for (let each = 1; each <= 12; each++) {
          for (let item of art) {
            if (
              item["coName"] === `${coNames[index - 1]}` &&
              item["poName"] === `PO${each}`
            ) {
              selectedData[`CO${index}PO${each}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }

        for (let each = 1; each <= psos.length; each++) {
          for (let item of art) {
            if (
              item["coName"] === `${coNames[index - 1]}` &&
              item["psoName"] === `${psos?.[each - 1]?.["po"]}`
            ) {
              selectedData[`CO${index}${psos?.[each - 1]?.["po"]}`] = {
                weightage: item["weightage"],
                coTblId: item["coTblId"],
              };
            }
          }
        }
      }
    }

    updateSelected(selectedData);
  };

  useEffect(() => {
    if (navigator.onLine) {
      try {
        getSelected();
      } catch (err) {
        console.log(err);
      }
    }
  }, [art]);

  const getDescCo = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_ART_MATRIX + `?courseId=${id}`,
        { Authorization: token }
      );

      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      // console.log(response);

      if (response["data"]["statusCode"] === "OK") {
        if (response?.["data"]?.["result"]?.rubricsCoMappingMatrixList) {
          const resData =
            response?.["data"]?.["result"]?.rubricsCoMappingMatrixList ?? [];

          const selectedResRub = [];

          resData.map((each) => {
            const splittedData = each?.cosList.split(",");
            const result = [];

            for (let i of splittedData) {
              result.push({ name: `${i}`, id: `${i}` });
            }
            selectedResRub.push(result);
          });

          rubsetDropdownsSelected(selectedResRub);
          updateRubCount(
            response?.["data"]?.["result"]?.rubricsCoMappingMatrixList.length
          );
        }

        updateArt(response["data"]["result"]);
      }

      if (responseDesc["data"]["statusCode"] === "OK") {
        updateDesc(responseDesc["data"]["result"]);
      } else {
        updateDesc(descDummy);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateMatrix = (piData) => {
    updatePiLvl(piData);
  };

  const getPsoData = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.MANAGE_CAMPUS_PSO + `?branch=${data["branch"]}`,
        { Authorization: token }
      );
      // console.log(response['data']['result']);
      if (response["data"]["statusCode"] === "OK") {
        updatePsos(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const genArt = () => {
    updateArticulation(true);
  };

  const rubChanged = () => {
    updateRubCount(rubCount + 1);
    rubsetDropdownsSelected([...rubdropdownsSelected, []]);
  };

  const scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "left", 25, 100, 400);
  };

  const scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    sideScroll(container, "right", 25, 100, 400);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const justifyBtn = () => {
    updateJustificationScreen(true);
  };

  const renderCourseData = () => {
    try {
      return (
        <div className="flex flex-row items-center mb-2">
          <h1
            className={`${styles["course-title"]} text-center w-2/12 text-[#040C58] bg-[#F4F9FF] rounded-lg p-5 pl-2 pr-2 text-ellipsis overflow-hidden`}
          >
            {data["courseCode"]}
          </h1>
          <div className="ml-3 w-8/12">
            <h1
              className={`${styles["course-title"]} capitalize text-[#040C58] `}
            >
              {data["courseName"]}
            </h1>
            <p className={`${styles.batch} text-[#8F949F] uppercase`}>
              {data["branch"]} ({data["batch"]})
            </p>
          </div>
        </div>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderActionValue = () => {
    let ans = "";
    for (let each in verbs) {
      if (verbs[each] !== "") {
        if (ans !== "") {
          ans += " and " + verbs[each];
        } else {
          ans += verbs[each];
        }
      }
    }

    return ans;
  };

  const ownVerbChanged = (event) => {
    if (event.key === "Enter") {
      updateVerbs({
        ...verbs,
        [activeLvl]: event.target.value,
      });
    }
  };

  const renderCoStatusBtn = () => {
    try {
      switch (coSubmitStatus) {
        case "submit":
          return "Submit";
        case "loading":
          return (
            <span className="flex justify-center items-center">
              <Oval
                height={20}
                width={20}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </span>
          );

        case "add":
          return (
            <span className="flex justify-center items-center text-2xl">+</span>
          );

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const coCountAdded = () => {
    updateCoTemp(true);
    setTimeout(() => {
      updateCoSubmitStatus("submit");
    }, 50);
  };

  const coSubmitBtn = async (event) => {
    event.preventDefault();
    const { reload } = props;
    updateCoSubmitStatus("loading");
    try {
      const actionVal = renderActionValue();
      const goalVal = document.getElementById("goal").value;
      const condVal = document.getElementById("condition").value;

      const statConcat = `Students will be able to ${actionVal}${
        actionVal !== "" ? " " : ""
      }${goalVal} ${condVal}`;

      let lvls = [];

      for (let key in verbs) {
        if (verbs[key] !== "") {
          lvls.push(key);
        }
      }

      const reqBody = [
        {
          bloomsTaxLvlStr: lvls.join(),
          co: `CO${coCount}`,
          coDesc: statConcat,
        },
      ];

      // console.log(reqBody);

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_UPDATE_COURSE_OUTCOMES +
          `?courseId=${data["courseId"]}&noOfCos=${coCount}`,
        options
      );

      const res = await response.json();
      if (res["result"] === "OK") {
        await getDescCo(data["courseId"]);
        updateCoSubmitStatus("add");
        updateCoTemp(false);

        // getPi(data["courseId"], statConcat, `CO${coCount}`);

        reload();
      } else {
        updateCoSubmitStatus("submit");
      }

      updateVerbs({
        L1: "",
        L2: "",
        L3: "",
        L4: "",
        L5: "",
        L6: "",
      });

      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const actionVerbFocussed = () => {
    updateVerbFocussed(true);
  };

  const actionVerbBlurred = () => {
    updateVerbFocussed(false);
  };

  const renderCoTemplate = () => {
    try {
      return (
        <>
          <p
            className={`${styles["co-statement-text"]} text-[#8F949F] mt-6 mb-5 ml-6`}
          >
            CO{coCount} Statement :
          </p>
          <div className={`${styles["co-input-container"]} ml-6`}>
            <p>Students will be able to&nbsp;</p>
            <input
              // required
              id="actionVerb"
              autoComplete="off"
              value={renderActionValue()}
              onFocus={actionVerbFocussed}
              placeholder="ACTION VERB"
              type="text"
              className={`${styles["filter-select-co"]} w-40 focus:ring-0`}
            />
            &nbsp; + &nbsp;
            <input
              required
              onFocus={actionVerbBlurred}
              id="goal"
              placeholder="GOAL"
              type="text"
              className={`${styles["filter-select-co"]} w-64 focus:ring-0`}
            />
            &nbsp; + &nbsp;
            <input
              onFocus={actionVerbBlurred}
              id="condition"
              placeholder="CONDITION"
              type="text"
              className={`${styles["filter-select-co"]} w-64 focus:ring-0`}
            />
          </div>
        </>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const lvlClicked = (event) => {
    updateActiveLvl(event.target.id);
  };

  const actionVerbClicked = (event) => {
    let keyName = event.target.id.split(" ")[0];
    let keyVal = event.target.id.split(" ").slice(1).join(" ");
    if (verbs[keyName] !== keyVal) {
      updateVerbs({
        ...verbs,
        [keyName]: keyVal,
      });
    } else {
      updateVerbs({
        ...verbs,
        [keyName]: "",
      });
    }
  };

  const renderActionVerbsList = () => {
    let ans = actionVerbs[activeLvl].map((each) => (
      <li
        key={each}
        onClick={actionVerbClicked}
        id={`${activeLvl} ${each}`}
        className={`${
          verbs[activeLvl] === each
            ? styles["action-verbs-active"]
            : styles["action-verbs"]
        }`}
      >
        {each}
      </li>
    ));

    return ans;
  };

  const renderDynamicPso = () => {
    const ans = [];
    try {
      for (let each of psos) {
        ans.push(
          <th scope="col" className="py-3.5">
            {each?.["po"]}
          </th>
        );
      }
    } catch (err) {
      console.log(err);
    }
    return ans;
  };

  const clsBtn = () => {
    updateJustificationScreen(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const { item } = props;

      updateData(item[0]);
      try {
        item[0]["coCreated"] !== null &&
          item[0]["coCreated"].toLowerCase() === "y" &&
          getDescCo(item[0]["courseId"]);
        item[0]["coCreated"] !== null &&
          item[0]["coCreated"].toLowerCase() === "y" &&
          genArt();

        // arePIsMatched();
        getPsoData();
        getDefaultRubrics();
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        updateApiHit(desc.length);
        updateCoCount(desc.length + 1);
      } catch (err) {
        console.log(err);
      }
    }
  }, [desc]);

  useEffect(() => {
    try {
      let coRub = [];
      for (let i = 0; i < coCount - 1; i++) {
        coRub.push({ name: `CO${i + 1}`, id: `CO${i + 1}` });
      }
      updateCosOptions(coRub);
    } catch (err) {
      console.log(err);
    }
  }, [coCount]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        getPsoData();

        desc.length === 0 ? updateCoTemp(true) : updateCoTemp(false);
        desc.length > 1
          ? updateCoSubmitStatus("add")
          : updateCoSubmitStatus("submit");
      } catch (err) {
        console.log(err);
      }
    }
  }, [data]);

  return justificationScreen ? (
    <CoPoMapping
      clsBtn={clsBtn}
      info={data}
      cos={desc}
      updateMatrix={updateMatrix}
    />
  ) : (
    <>
      <span className="flex items-center justify-between">
        <h1 className={styles["overlay-title"]}>Enter Course Details</h1>
        <RxCross2
          onClick={cls}
          size={26}
          className="cursor-pointer text-[#456bf1]"
        />
      </span>
      <div className="bg-[#FFFFFF] p-5 mt-6 mb-0 pb-2">
        <span className="flex flex-row justify-between mb-0">
          <h1
            className={`${styles["overlay-heading"]} underline underline-offset-8 mb-0 ml-2`}
          >
            Create Course Outcomes
          </h1>
        </span>
      </div>

      {created ? (
        <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
          <img src="/assets/tick.svg" alt="new course" width={34} height={34} />
          <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
            Course Articulation Matrix Created Successfully
          </h1>
        </div>
      ) : (
        <div className={styles["div-container"]}>
          <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] mb-0 p-5 pb-5 pt-0">
            {renderCourseData()}
          </div>
          <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] mb-5 p-5 pb-0 pt-0 mt-4 w-full">
            {desc.length > 0 && (
              <button
                disabled={editLoad}
                onClick={editCos ? saveCosBtn : editCosBtn}
                className={`${styles["co-edit-btn"]} ${
                  editLoad ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                {editLoad ? (
                  <span className="flex justify-center items-center">
                    <Oval
                      height={25}
                      width={80}
                      color="#3D65F4"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#3D65F4"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                ) : (
                  !editCos && (
                    <span>
                      <RiPencilFill className="text-blue-600 mr-2" size={18} />
                    </span>
                  )
                )}
                {!editLoad &&
                  (!editCos ? "Edit CO Statements" : "Update CO Statements")}
              </button>
            )}

            <ul className="flex flex-col w-full items-center list-none">
              {renderMatrixCoStatements()}
            </ul>
            <form onSubmit={coSubmitBtn} className="flex flex-col">
              {coTemp && renderCoTemplate()}
              {verbFocussed && (
                <div className="flex mt-5 h-72">
                  <ul className="list-none flex flex-col mr-8">
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L1" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L1"
                    >
                      Remembering
                    </li>
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L2" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L2"
                    >
                      Understanding
                    </li>
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L3" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L3"
                    >
                      Applying
                    </li>
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L4" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L4"
                    >
                      Analyzing
                    </li>
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L5" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L5"
                    >
                      Evaluating
                    </li>
                    <li
                      className={`${styles["action-lvl"]} ${
                        activeLvl === "L6" && styles["action-lvl-active"]
                      }`}
                      onClick={lvlClicked}
                      id="L6"
                    >
                      Creating
                    </li>
                  </ul>
                  <div className={styles["vertical-line"]}></div>
                  <ul className="list-none flex w-full items-start flex-wrap overflow-y-auto ml-8">
                    {renderActionVerbsList()}
                  </ul>
                </div>
              )}
              <button
                onClick={coSubmitStatus === "add" ? coCountAdded : null}
                disabled={coSubmitStatus === "loading"}
                type={coSubmitStatus === "submit" ? "submit" : "button"}
                className={`${styles["co-submit-btn"]} ${
                  coSubmitStatus === "loading" && "cursor-not-allowed"
                } self-end mt-10`}
              >
                {renderCoStatusBtn()}
              </button>
            </form>
            {verbFocussed && (
              <input
                onKeyDown={ownVerbChanged}
                placeholder="ADD OWN VERB"
                type="text"
                className={`${styles["filter-select-co"]} w-40 ml-48 relative bottom-24 focus:ring-0 mt-10`}
              />
            )}
          </div>

          {articulation && (
            <>
              <div className="w-full flex flex-col justify-start flex-wrap mt-10">
                <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] mb-5 p-5 pt-0 mt-10 w-full">
                  <h2 className={`${styles["matrix-text"]} mt-2 mb-5`}>
                    Course Articulation Matrix
                  </h2>

                  <div className={styles.table}>
                    <table className="text-center border border-blue-500 w-full">
                      <tbody>
                        <tr
                          className={`${styles.matrix} border border-blue-500 p-0 w-full`}
                        >
                          <th className="bg-[#fff] truncate border border-blue-500 p-0">
                            Course Outcomes
                          </th>
                        </tr>
                        {renderMatrix()}
                      </tbody>
                    </table>

                    <div
                      id="scrollTb"
                      className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                    >
                      <table className="p-0 m-0">
                        <thead>
                          <tr
                            className={`${styles.matrix} border border-t-0 border-l-0 border-blue-500 p-0`}
                          >
                            <th scope="col" className="py-3.5">
                              PO1
                            </th>
                            <th scope="col" className="py-3.5">
                              PO2
                            </th>
                            <th scope="col" className="py-3.5">
                              PO3
                            </th>
                            <th scope="col" className="py-3.5">
                              PO4
                            </th>
                            <th scope="col" className="py-3.5">
                              PO5
                            </th>
                            <th scope="col" className="py-3.5">
                              PO6
                            </th>
                            <th scope="col" className="py-3.5">
                              PO7
                            </th>
                            <th scope="col" className="py-3.5">
                              PO8
                            </th>
                            <th scope="col" className="py-3.5">
                              PO9
                            </th>
                            <th scope="col" className="py-3.5">
                              PO10
                            </th>
                            <th scope="col" className="py-3.5">
                              PO11
                            </th>
                            <th scope="col" className="py-3.5">
                              PO12
                            </th>
                            {renderDynamicPso()}
                          </tr>
                        </thead>
                        <tbody>{renderPos()}</tbody>
                      </table>
                    </div>
                  </div>

                  <span className="flex self-end mt-4">
                    <span
                      onClick={scrollTbClickedLeft}
                      className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] cursor-pointer rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2`}
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                    <span
                      onClick={scrollTbClicked}
                      className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 cursor-pointer pb-1.5 pl-2 pr-2"
                    >
                      <img
                        src="/assets/floatarrow.svg"
                        alt="arrow"
                        width={8}
                        height={8}
                      />
                    </span>
                  </span>
                </div>
              </div>

              {data?.["courseHavRub"] === "Y" && (
                <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0 mt-10 w-full">
                  <h2 className={`${styles["matrix-text"]} mt-2 mb-5`}>
                    Rubrics Details
                  </h2>
                  <div className={styles.table}>
                    <table className="text-center border border-blue-500 w-full">
                      <tbody>
                        <tr
                          className={`${styles.matrix} border border-blue-500 p-0 w-full`}
                        >
                          <th className="border border-blue-500 p-0 py-4">
                            Rubric Number
                          </th>
                          <th className="border border-blue-500 p-0 py-4">
                            Rubric Description
                          </th>
                          <th className="border border-blue-500 p-0 py-4">
                            Course Outcome
                          </th>
                        </tr>
                        {renderRubrics()}
                      </tbody>
                    </table>
                  </div>
                  {data["coCreated"] !== null && (
                    <button
                      className="font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mt-4"
                      onClick={rubChanged}
                    >
                      Add Rubric
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}

      <span className="flex flex-row justify-end items-end mt-10 mb-5">
        {articulation ? (
          created === false && (
            <>
              <button
                onClick={justifyBtn}
                className={`${styles["justification-btn"]} self-end bg-[#E2E9FF] rounded-2xl px-8 py-1.5 mr-5 mt-4`}
                type="button"
              >
                Generate CO-PO Matrix
              </button>
              <button
                disabled={isLoading}
                onClick={addCo}
                className={`${
                  isLoading && "cursor-not-allowed"
                } font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mt-4`}
                type="button"
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#FFF"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </>
          )
        ) : (
          <button
            disabled={desc.length < 1}
            onClick={genArt}
            className={`${styles.articulation} ${
              desc.length < 1 && "cursor-not-allowed"
            } self-end bg-[#456BF1] rounded-2xl px-8 py-2 mr-5 mt-4`}
            type="button"
          >
            Generate Articulation Matrix
          </button>
        )}
      </span>
    </>
  );
};

export default CourseOutcome;
