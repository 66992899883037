/* eslint-disable eqeqeq */
import { Component } from "react";
import styles from "./index.module.css";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import Input from "../Templates/Input";
import Statement from "../Templates/Statement";
import MissionPeoMatrix from "../Templates/MissionPeoMatrix";

const token = Cookies.get("jwt_token");

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};

const screenView = {
  loading: "loading",
  success: "success",
  failed: "failed",
  dataUploaded: "uploaded",
};

const templateNames = {
  input: "input",
  missionStatement: "mission-statement",
  peoStatement: "peo-statement",
  missionPeoMatrix: "mission-peo-matrix-table",
};

const metricStatus = {
  1: { content: "NOT STARTED", bg: "bg-[#F6F5FF]" },
  2: { content: "SUBMITTED", bg: "bg-[#FFF7EE]" },
  3: { content: "REJECTED", bg: "bg-[#FBE3E3]" },
  4: { content: "APPROVED", bg: "bg-[#EBFFF7]" },
  5: { content: "EVALUATED", bg: "bg-[#E0FFD9]" },
};

class MetricsView extends Component {
  state = {
    assignedMetric: {},
    screen: screenView.loading,
    metricInfo: {},
    metricNum: [],
    subMetricNum: {},
    activeMainMetric: "",
    activeMetric: "",
    activeQuestion: {},
  };

  componentDidMount() {
    this.fetchAssignedMetric();
    this.fetchMetricData();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    if (metricsId !== prevProps.match.params.metricsId) {
      this.fetchAssignedMetric();
      this.fetchMetricData();
    }
  }

  fetchAssignedMetric = async () => {
    const { match } = this.props;
    const { params } = match;
    const { projectId, metricsId } = params;

    this.setState({
      assignedMetric: {},
      metricNum: [],
      subMetricNum: {},
      activeMainMetric: "",
      activeMetric: "",
      activeQuestion: {},
    });

    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_ASSIGNED +
          `/${projectId}`,
        options
      );

      if (response.ok) {
        const res = await response.json();
        const { data } = res;

        let sub = {};

        const mainMetrics = data.map((each) => {
          const splitted = each.metric_no.split(".");

          if (each.metrics_id == metricsId) {
            this.setState({
              activeMainMetric: splitted.slice(0, 2).join("."),
              activeMetric: each.metric_no,
              activeQuestion: each,
            });
          }

          sub[`${splitted.slice(0, 2).join(".")}`] = (
            sub[`${splitted.slice(0, 2).join(".")}`] +
            "," +
            each.metric_no
          )
            .replace("undefined", "")
            .replace(/^,+|,+$/g, "");

          return splitted.slice(0, 2).join(".");
        });

        this.setState({
          assignedMetric: res,
          subMetricNum: sub,
          metricNum: [...new Set(mainMetrics)],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchMetricData = async () => {
    this.setState({
      screen: screenView.loading,
      metricInfo: {},
    });

    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    const options = {
      method: "GET",
      headers,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA + `/${metricsId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();

        this.setState({ screen: screenView.success, metricInfo: data });
      } else {
        this.setState({ screen: screenView.failed });
      }
    } catch (err) {
      console.log(err);
      this.setState({ screen: screenView.failed });
    }
  };

  mainMetricChanged = (event) => {
    const { assignedMetric, subMetricNum } = this.state;
    const { data } = assignedMetric;
    const { history } = this.props;

    const subMetric = subMetricNum[event.target.id].split(",")[0];

    const metricIdData = data.filter((each) => each.metric_no === subMetric);

    history.push(
      `/connect/dashboard/metrics-data/${metricIdData[0]["project_id"]}/${metricIdData[0]["metrics_id"]}`
    );
  };

  subMetricChanged = (event) => {
    const { assignedMetric } = this.state;
    const { data } = assignedMetric;
    const { history } = this.props;

    const metricIdData = data.filter(
      (each) => each.metric_no === event.target.id
    );

    history.push(
      `/connect/dashboard/metrics-data/${metricIdData[0]["project_id"]}/${metricIdData[0]["metrics_id"]}`
    );
  };

  renderMainMetric = () => {
    const { metricNum, activeMainMetric } = this.state;

    return metricNum.map((each) => (
      <li
        id={each}
        onClick={this.mainMetricChanged}
        className={
          each === activeMainMetric
            ? "bg-[#456BF1] text-[#fff]"
            : "text-[#606981]"
        }
      >
        {each}
      </li>
    ));
  };

  renderSubMetric = () => {
    const { subMetricNum, activeMetric, activeMainMetric } = this.state;

    const data = subMetricNum[activeMainMetric]?.split(",");

    return data?.map((each) => (
      <li
        onClick={this.subMetricChanged}
        id={each}
        className={
          each === activeMetric
            ? "underline underline-offset-8 text-[#040C58] font-extrabold decoration-2"
            : "text-[#464D90] font-bold"
        }
      >
        {each}
      </li>
    ));
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-20 mb-5">
      <p className={styles["empty-view"]}>Something went wrong.</p>
    </div>
  );

  renderUploadedView = () => (
    <div className="flex flex-col justify-center items-center p-10 mt-20 mb-5">
      <img
        src="/assets/tick.svg"
        width={35}
        height={35}
        alt="done"
        className="mb-5"
      />
      <p className={styles["uploaded-view"]}>
        Metric data uploaded Successfully!
      </p>
      <button onClick={this.okBtn} type="button" className={styles["ok-btn"]}>
        Ok
      </button>
    </div>
  );

  uploadedStatus = () => {
    this.setState({ screen: screenView.dataUploaded });
  };

  okBtn = () => {
    this.fetchAssignedMetric();
    this.fetchMetricData();
  };

  renderView = (template) => {
    const { match } = this.props;
    const { params } = match;
    const { metricsId } = params;

    const { metricInfo } = this.state;

    switch (template) {
      case templateNames.input:
        return (
          <Input
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]?.[0]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.missionStatement:
        return (
          <Statement
            templateNames={templateNames}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.peoStatement:
        return (
          <Statement
            templateNames={templateNames}
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo?.[template]}
            uploadedStatus={this.uploadedStatus}
          />
        );

      case templateNames.missionPeoMatrix:
        return (
          <MissionPeoMatrix
            template={template}
            metricsId={metricsId}
            metricInfo={metricInfo}
            uploadedStatus={this.uploadedStatus}
          />
        );

      default:
        break;
    }
  };

  renderMetricScreen = () => {
    const { screen, metricInfo } = this.state;

    switch (screen) {
      case screenView.loading:
        return (
          <div className="absolute top-52 flex justify-center w-full">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case screenView.success:
        return metricInfo?.templateNames?.map((each) => this.renderView(each));
      case screenView.failed:
        return this.renderEmptyView();
      case screenView.dataUploaded:
        return this.renderUploadedView();

      default:
        break;
    }
  };

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  scrollTbClickedLeft = () => {
    const container = document.getElementById("metric-ul");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  scrollTbClicked = () => {
    const container = document.getElementById("metric-ul");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  render() {
    const { activeMetric, activeQuestion, screen } = this.state;

    return (
      <div className={styles["dashboard-container"]}>
        <div className="flex items-center ml-3">
          {this.toggleButtons("metric-ul") && (
            <span
              onClick={this.scrollTbClickedLeft}
              className={`${styles["floatarr"]} relative bottom-2 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={10}
                height={10}
              />
            </span>
          )}
          <ul id="metric-ul" className={styles["metric-ul"]}>
            {this.renderMainMetric()}
          </ul>

          {this.toggleButtons("metric-ul") && (
            <span
              onClick={this.scrollTbClicked}
              className="relative bottom-2 right-3 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={10}
                height={10}
              />
            </span>
          )}
        </div>

        <ul className={styles["sub-metric-ul"]}>{this.renderSubMetric()}</ul>

        <div className="ml-3 flex justify-between pr-4 items-center mb-3 w-full">
          <span className="flex items-center w-full">
            <p className={styles["metric-qstn"]}>
              {activeMetric}
              {screen !== screenView.loading && ")"}{" "}
              {activeQuestion?.metric_statement}
            </p>
            {screen !== screenView.loading && (
              <p className={styles["qstn-weightage"]}>
                {activeQuestion?.weightage} Marks
              </p>
            )}
          </span>

          {screen !== screenView.loading && (
            <p
              className={`${styles["metric-status"]} ${
                metricStatus?.[activeQuestion?.status]?.bg
              }`}
            >
              {metricStatus?.[activeQuestion?.status]?.content}
            </p>
          )}
        </div>
        {this.renderMetricScreen()}
      </div>
    );
  }
}

export default withRouter(MetricsView);
