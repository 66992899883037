import MetricAssignOverlay from "../MetricAssignOverlay";
import CriteriaList from "../CriteriaList";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import roles from "../../../../roles";
import * as jose from "jose";

const Criteria = (props) => {
  const [isOpened, updatedOpened] = useState(false);
  const [criteria, updateCriteria] = useState([]);
  const [criteriaLoader, updateCriteriaLoader] = useState(false);
  const [userdata, updateUserData] = useState({});

  const { match } = props;
  const { params } = match;
  const { projectId } = params;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const renderStatusCards = () => (
    <ul className={styles["metrics-status-card"]}>
      <li className="bg-[#F6F5FF]">
        <h2>NOT STARTED</h2>
        <h1>
          90 <span>85%</span>
        </h1>
      </li>

      <li className="bg-[#F1F8FE]">
        <h2>IN DRAFT</h2>
        <h1>
          10 <span>20%</span>
        </h1>
      </li>

      <li className="bg-[#FFF7EE]">
        <h2>SUBMITTED</h2>
        <h1>
          35 <span>15%</span>
        </h1>
      </li>

      <li className="bg-[#FBE3E3]">
        <h2>REJECTED</h2>
        <h1>
          10 <span>10%</span>
        </h1>
      </li>

      <li className="bg-[#EBFFF7]">
        <h2>APPROVED</h2>
        <h1>
          12 <span>5%</span>
        </h1>
      </li>

      <li className="bg-[#E0FFD9]">
        <h2>EVALUATED</h2>
        <h1>
          9 <span>85%</span>
        </h1>
      </li>
    </ul>
  );

  const progressStyle = {
    width: `${60}%`,
  };

  const closeBtn = () => {
    updatedOpened(false);
  };

  const openBtn = () => {
    updatedOpened(true);
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const fetchCriteria = async () => {
    updateCriteriaLoader(true);
    updateCriteria([]);
    const options = {
      method: "GET",
      headers,
    };

    try {
      const fetchedResponse = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_CRITERIA + `/${projectId}`,
        options
      );

      if (fetchedResponse.ok) {
        const data = await fetchedResponse.json();

        const updatedData = data.map((each) => ({
          id: each.id,
          criteriaNo: each.criteria_no,
          criteriaStatement: each.criteria_statement,
          weightage: each.weightage,
          projectId: each.project_id,
          status: each.status,
          startDate: each.start_date,
          endDate: each.end_date,
        }));

        updateCriteria(updatedData);
      } else {
        updateCriteria([]);
      }
    } catch (err) {
      updateCriteria([]);
      console.log(err);
    }

    updateCriteriaLoader(false);
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Criteria Found To View <br /> Metrics should be assigned to view the
        criteria
      </p>
    </div>
  );

  const renderCriteriaView = () => {
    const content = criteriaLoader
      ? "loading"
      : criteria.length === 0
      ? "empty"
      : "content";

    switch (content) {
      case "loading":
        return (
          <div className="absolute top-80 left-2/4">
            <Oval
              height={50}
              width={50}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        );

      case "content":
        return (
          <ul className={styles["metrics-list"]}>
            {criteria.map((each) => (
              <CriteriaList key={each.id} item={each} projectId={projectId} />
            ))}
          </ul>
        );

      case "empty":
        return renderEmptyView();

      default:
        return null;
    }
  };

  useEffect(() => {
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateUserData(claim);
    }
    fetchCriteria();
  }, []);

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div onClick={preventBubbling} className={styles["overlay-card"]}>
          <MetricAssignOverlay
            projectId={projectId}
            closeBtn={closeBtn}
            fetchCriteria={fetchCriteria}
          />
        </div>
      </div>
      <div className={styles["dashboard-container"]}>
        <div className="flex items-center w-full">
          <div className="flex flex-col">
            <h1 className={styles["metric-value"]}>
              SAR: 78% done (400 Marks)
            </h1>
            <div className={styles["progress-bar-container"]}>
              <div className={styles["progress-bar"]} style={progressStyle}>
                &nbsp;
              </div>
            </div>
          </div>

          <div className={styles["profile-dp-container"]}>
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />
            <img
              src="/assets/profileDp.svg"
              alt="dp"
              className={styles["profile-dp"]}
            />

            <span className={styles["profile-count"]}>+ 15 people</span>
          </div>

          <div className="flex items-center ml-6">
            <div className={styles["search-input-container"]}>
              <img src="/assets/search.svg" alt="search" />
              <input
                placeholder="Search"
                type="search"
                className={styles["search-input"]}
              />
            </div>
            {(userdata?.user_type === roles.principal ||
              userdata?.user_type === roles.hod) && (
              <button
                onClick={openBtn}
                type="button"
                className={styles["dotted-plus"]}
              >
                +
              </button>
            )}
          </div>
        </div>

        {renderStatusCards()}

        {renderCriteriaView()}
      </div>
    </>
  );
};

export default withRouter(Criteria);
