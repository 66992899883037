import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../utils/apiUrls/apiClient";
import { Component } from "react";
import { Oval } from "react-loader-spinner";

class CoAttainmentTable extends Component {
  state = {
    data: null,
    content: "loading",
    internalData: [],
    externalData: [],
  };

  abortController = null;

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  componentDidUpdate(prevProps) {
    const { batch, branch, sem, courseCode, sec } = this.props;

    if (
      prevProps.batch !== batch ||
      prevProps.branch !== branch ||
      prevProps.sem !== sem ||
      prevProps.courseCode !== courseCode ||
      prevProps.sec !== sec
    ) {
      this.getData();
    }
  }

  getData = async () => {
    try {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();
      this.setState({ content: "loading" });

      const { courseCode, sec, secOptions } = this.props;
      const joinedSec = secOptions.join();

      const sectionsVal = sec === "" ? joinedSec : sec;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS +
            `?courseId=${courseCode}&sectionList=${sectionsVal}`,
          { Authorization: token },
          {
            signal: this.abortController.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState(
            { data: response["data"]["result"], content: true },
            this.tableStruct
          );
        } else {
          this.setState({ data: null, content: false });
        }
      } else {
        this.setState({ data: null, content: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  tableStruct = () => {
    try {
      const { data } = this.state;

      const { courseStructure } = data;
      const item = courseStructure;

      const intTheory =
        item.intTheoryShortList === null
          ? ""
          : item.intTheoryShortList.split(",");

      const intPract =
        item.intPractShortList === null
          ? ""
          : item.intPractShortList.split(",");

      const intProj =
        item.intProjShortList === null ? "" : item.intProjShortList.split(",");

      const internal = intTheory.concat(intPract).concat(intProj);

      const internalValues = [];

      for (const each of internal) {
        if (each !== "") {
          internalValues.push(each);
        }
      }

      // console.log(internalValues);

      // internal = internal.filter((each) => each !== '');

      const extTheory =
        item.extTheoryShortList === null
          ? ""
          : item.extTheoryShortList.split(",");

      const extPract =
        item.extPractShortList === null
          ? ""
          : item.extPractShortList.split(",");
      const extProj =
        item.extProjShortList === null ? "" : item.extProjShortList.split(",");

      const external = extTheory.concat(extPract).concat(extProj);

      // external = external.filter((each) => each !== '');
      const externalValues = [];

      for (const each of external) {
        if (each !== "") {
          externalValues.push(each);
        }
      }

      this.setState({
        internalData: internalValues,
        externalData: externalValues,
      });
    } catch (err) {
      console.log(err);
    }
  };

  renderCoAtt = () => {
    const { data, internalData, externalData } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58] font-semibold">
            <th className="border border-blue-500 p-2">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
              {/* {Object.keys(data["coAttData"])[index]} */}
            </th>

            <>
              {internalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `Internal` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <>
              {externalData.map((e) => (
                <td
                  key={e + index}
                  className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
                >
                  {`${
                    data?.["coAttData"]?.[`CO${index + 1}`]?.["daData"]?.map(
                      (each) => {
                        if (
                          each["assmntCat"] === `External` &&
                          each["templateName"] === e
                        ) {
                          return each["pctGtThValue"];
                        }
                      }
                    ) ?? ""
                  }`.replaceAll(",", "")}
                </td>
              ))}
            </>

            <td
              className={`${styles["table-td"]} border border-blue-500 text-center p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "avgCie"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wAvgCie"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} text-center border border-blue-500 p-2 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wAvgSee"
              ] ?? ""}{" "}
            </td>

            <th
              className={`${styles["table-td"]} text-center border border-blue-500 p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderOverAllAtt = () => {
    const { data } = this.state;
    const ans = [];
    try {
      for (
        let index = 0;
        index < Object.keys(data["coAttData"]).length;
        index++
      ) {
        ans.push(
          <tr
            key={index}
            className="border border-blue-500 text-[#040C58] font-semibold"
          >
            <th className="border border-blue-500 p-2">
              {data["courseDetails"]["courseCode"]}.{[index + 1]}
              {/* {Object.keys(data["coAttData"])[index]} */}
            </th>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttDaPct"
              ] ?? ""}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 text-[#606981] p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttIdaPct"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "wTotalAttIdaPCt"
              ] ?? ""}
            </td>

            <th className={`${styles["table-td"]} p-2 border border-blue-500`}>
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "totalCoAtt"
              ] ?? ""}
            </th>
            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "targetAtt"
              ] ?? ""}
            </td>

            <td
              className={`${styles["table-td"]} p-2 border border-blue-500 text-[#606981]`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "coAttLvl"
              ] ?? ""}
            </td>
            <th
              className={`${styles["table-td"]} ${
                data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                  "isCoAchieved"
                ] === "Y"
                  ? "text-green-500"
                  : "text-red-500"
              } border border-blue-500 p-2`}
            >
              {data?.["coAttData"]?.[Object.keys(data["coAttData"])[index]]?.[
                "isCoAchieved"
              ] ?? ""}
            </th>
          </tr>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getIntTd = () => {
    const { internalData } = this.state;
    const ans = [];
    try {
      for (const each of internalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF]">
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  getExtTd = () => {
    const { externalData } = this.state;

    const ans = [];

    try {
      for (const each of externalData) {
        ans.push(
          <th className="border border-blue-500 p-2 text-[#040C58] bg-[#EFF3FF]">
            {each}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  scrollTbClickedLeft = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  scrollTbClicked = () => {
    const container = document.getElementById("scrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  oAscrollTbClickedLeft = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  oAscrollTbClicked = () => {
    const container = document.getElementById("oAscrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No Data Found For The Selected Filters To View Attainments & Analysis
      </p>
    </div>
  );

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  renderCiewSeew = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * data["courseWeightages"]["cieW"]}% of <br />
            CIE
          </th>
          <th className="border border-blue-500 p-2" rowSpan={2}>
            {100 * data["courseWeightages"]["seeW"]}% of <br />
            SEE
          </th>
        </>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderDAw = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * data["courseWeightages"]["directCoW"]}% of DA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderIAw = () => {
    const { data } = this.state;

    const ans = [];
    try {
      ans.push(
        <th className="border border-blue-500 p-2">
          {100 * data["courseWeightages"]["indirectCoW"]}% of IA
        </th>
      );

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    if (navigator.onLine) {
      const { data } = this.state;

      if (Cookies.get("jwt_token") !== undefined) {
        this.getData();
        data !== null && this.tableStruct();
      }
    }
  }

  renderScreen = () => {
    try {
      const { content, internalData, externalData } = this.state;

      switch (content) {
        case true:
          return (
            <>
              <div className={styles["bg-containers"]}>
                <p className={styles["art-matrix-title"]}>
                  Attainment Of Course Outcomes (Cos) Through Direct Assessment
                  (DA) Tools
                </p>

                {content && (
                  <>
                    <div className={styles.table}>
                      <div
                        id="scrollTb"
                        className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                      >
                        <table className="mb-2 border-solid border-blue-600 border-1">
                          <tbody>
                            <tr
                              className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                            >
                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Course Outcome
                              </th>
                              {internalData.length !== 0 && (
                                <th
                                  className="border border-blue-500 p-0"
                                  colSpan={internalData.length}
                                >
                                  Internal
                                </th>
                              )}

                              {externalData.length !== 0 && (
                                <th
                                  className="border border-blue-500 p-0"
                                  colSpan={externalData.length}
                                >
                                  External
                                </th>
                              )}
                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Average <br /> Of CIE
                              </th>
                              {this.renderCiewSeew()}

                              <th
                                className="border border-blue-500 p-2"
                                rowSpan={2}
                              >
                                Total Attainment <br /> Through DA (100%)
                              </th>
                            </tr>
                            <tr className="text-center text-sm">
                              {internalData.length !== 0 && this.getIntTd()}
                              {externalData.length !== 0 && this.getExtTd()}
                            </tr>

                            {this.renderCoAtt()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.toggleButtons("scrollTb") && (
                      <span className="flex justify-end">
                        <span
                          onClick={this.scrollTbClickedLeft}
                          className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                        <span
                          onClick={this.scrollTbClicked}
                          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className={styles["bg-containers"]}>
                <p className={styles["art-matrix-title"]}>
                  Overall Attainment Of Course Outcomes (Co) Through Direct And
                  Indirect Assessment Tools:
                </p>

                {content && (
                  <>
                    <div className={styles.table}>
                      <div
                        id="oAscrollTb"
                        className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
                      >
                        <table className="text-center border border-blue-500">
                          <tbody>
                            <tr
                              className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                            >
                              <th className="border border-blue-500 p-2">
                                Course Outcome
                              </th>
                              <th className="border border-blue-500 p-2">
                                CO Attainment through Direct Assessment(DA)
                              </th>
                              {this.renderDAw()}

                              <th className="border border-blue-500 p-2">
                                CO Attainment through Indirect Assessment(IA)
                              </th>
                              {this.renderIAw()}

                              <th className="border border-blue-500 p-2">
                                Total CO Attainment
                              </th>
                              <th className="border border-blue-500 p-2">
                                Target
                              </th>
                              <th className="border border-blue-500 p-2">
                                Attainment Level
                              </th>
                              <th className="border border-blue-500 p-2">
                                Attainment Achieved
                              </th>
                            </tr>

                            {this.renderOverAllAtt()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.toggleButtons("oAscrollTb") && (
                      <span className="flex justify-end">
                        <span
                          onClick={this.oAscrollTbClickedLeft}
                          className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                        <span
                          onClick={this.oAscrollTbClicked}
                          className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
                        >
                          <img
                            src="/assets/floatarrow.svg"
                            alt="arrow"
                            width={8}
                            height={8}
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
          );
        case false:
          return this.renderEmptyView();
        case "loading":
          return (
            <div className="flex justify-center items-center mt-20 mb-10">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return this.renderScreen();
  }
}

export default CoAttainmentTable;
