import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import styles from "./index.module.css";
import { useState } from "react";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import { Oval } from "react-loader-spinner";

const romanNum = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const CoursesList = (props) => {
  const [popUpStatus, updatePopUpStatus] = useState(false);
  const [popUpDelete, updatePopUpDelete] = useState(false);
  const [popUp, updatePopUp] = useState(false);
  const [popUpData, updatePopUpData] = useState([]);
  const [popAvailable, updatePopAvailable] = useState(false);
  const [isLoading, updateLoading] = useState(false);

  const { item, deleteCourse, updateCourse, coBtn, quesPaperBtn, feedbackBtn } =
    props;

  const openModalStatus = async () => {
    try {
      updateLoading(true);
      updatePopUpStatus(true);
      const token = Cookies.get("jwt_token");

      const sections = item?.sectionsForCourse ?? "";

      const sectionsFiltered = sections.replace(/\s+/g, "");

      const response = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_CHECK_STATUS_POPUP +
          `?courseId=${item.courseId}&courseStructId=${item.courseStructId}&sectionList=${sectionsFiltered}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updatePopUpData(response["data"]["result"]);
        updatePopAvailable(true);
      } else {
        updatePopUpData([]);
        updatePopAvailable(false);
      }
      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const closeModalStatus = () => {
    updatePopUpStatus(false);
    updateLoading(false);
  };

  const renderPopupStatus = () => (
    <div className={`${styles["modal"]}`}>
      {isLoading ? (
        <Oval
          height={40}
          width={40}
          color="#3D65F4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3D65F4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : popAvailable && popUpData.length > 0 ? (
        <div className={styles["modal-container"]}>
          <table className={`${styles["table"]} border-b border-blue-500`}>
            <tr className="border border-blue-500">
              <th className="border border-blue-500 p-2" rowSpan={1}>
                Course Name
              </th>
              <th className="border border-blue-500 p-2" rowSpan={1}>
                Section
              </th>
              <th className="border border-blue-500 p-2" rowSpan={1}>
                Type
              </th>
              <th className="border border-blue-500 p-2" rowSpan={1}>
                Status
              </th>
            </tr>
            <tr className="border-blue-500">
              <td
                className={`${styles["course-name"]} border-r border-l border-blue-500 p-2`}
                rowSpan={popUpData.length + 3}
                colSpan={1}
              >
                {item.courseName}
              </td>
            </tr>

            {popUpData.map((each) => (
              <tr key={each["templateName"]} className="border-blue-500 ">
                <td className="border-r border-blue-500 b-0 p-2" rowSpan={1}>
                  {each["sectionName"]}
                </td>
                <td className="border-r border-blue-500 b-0 p-2" rowSpan={1}>
                  {each["templateName"]}
                </td>
                <td
                  className="border-r border-blue-500 b-0 p-2.5 flex justify-center"
                  rowSpan={1}
                >
                  <img
                    src={
                      each["templateUploadStatus"] === "Y"
                        ? "/assets/ticks.svg"
                        : "/assets/cross.svg"
                    }
                    alt="status"
                    width={20}
                    height={20}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
      ) : (
        <p className="text-center mt-5 pb-2 text-red-400">No Data Found</p>
      )}
    </div>
  );

  const popUpModal = () => {
    updatePopUp(!popUp);
  };

  const openModalDelete = () => {
    updatePopUpDelete(true);
  };

  const closeModalDelete = () => {
    updatePopUpDelete(false);
  };

  const deleteCourseItem = () => {
    updatePopUpDelete(false);
    updatePopUp(false);
    deleteCourse(item["courseId"]);
  };

  const updateCourseItem = () => {
    updatePopUp(false);
    updateCourse(item);
  };

  const renderDeletePopup = () => (
    <div className={`${styles["modal"]} rounded-lg flex flex-col`}>
      <p className={`${styles["course-title"]} text-center mb-10`}>
        Do you want to delete the course?
      </p>
      <span>
        <button
          onClick={closeModalDelete}
          className="mr-9 cursor-pointer text-blue-600 font-medium bg-[#F4F9FF] rounded px-6 py-2 border border-blue-600"
        >
          No
        </button>
        <button
          onClick={deleteCourseItem}
          className="cursor-pointer text-blue-600 font-medium bg-red-600 text-white rounded px-6 py-2 border"
        >
          Yes
        </button>
      </span>
    </div>
  );

  const progressStyle = {
    width: `${item.courseProgress}%`,
  };

  const overlayStyle = { background: "rgba(0,0,0,0.5)", position: "absolute" };

  const createCo = () => {
    coBtn(item["courseId"]);
  };

  const mapQuestionPaper = () => {
    quesPaperBtn(item["courseId"]);
  };

  const feedback = () => {
    feedbackBtn(item["courseId"]);
  };

  return (
    <li className="flex flex-col justify-between mb-4 drop-shadow bg-[#FFFFFF] mr-3.5 rounded-xl w-[32%]">
      <span className="flex flex-col rounded-xl w-[100%] p-4">
        <button onClick={popUpModal} className="self-end cursor-pointer">
          <BsThreeDots size={20} />
        </button>
        {popUp && (
          <div className={`${styles["course-edit-popup"]}`}>
            <button
              onClick={updateCourseItem}
              className={`flex items-center cursor-pointer`}
            >
              <span>
                <RiPencilFill className="text-blue-600 mr-3" size={18} />
              </span>
              Edit
            </button>

            <button
              onClick={openModalDelete}
              className={`flex items-center cursor-pointer`}
            >
              <span>
                <MdDeleteOutline className="text-red-600 mr-3" size={18} />
              </span>
              Delete
            </button>

            <Popup
              open={popUpDelete}
              onClose={closeModalDelete}
              {...{ overlayStyle }}
            >
              {renderDeletePopup()}
            </Popup>
          </div>
        )}
        <div className="flex flex-row items-center">
          <h1
            className={`${styles["course-title"]} text-center w-1/3 text-[#040C58] bg-[#F4F9FF] rounded-lg p-5 text-ellipsis overflow-hidden`}
          >
            {item.courseCode}
          </h1>
          <div className="ml-3 w-2/3">
            <h1
              className={`${styles["course-title"]} capitalize text-[#040C58] `}
            >
              {item.courseName}
            </h1>
            <p className={`${styles.batch} text-[#8F949F] uppercase`}>
              {item.branch}({item.sectionsForCourse}) -{" "}
              {romanNum[item.semester]} &nbsp; ({item.batch})
            </p>
          </div>
        </div>
        <div className="mt-6 mb-6">
          <div className="flex flex-row justify-between pb-2">
            <p className="text-[#606981] text-sm">Course Type:</p>
            <p className="text-[#606981] text-sm font-medium capitalize">
              {item.courseType}
            </p>
          </div>
          <div className="flex flex-row justify-between pb-2">
            <p className="text-[#606981] text-sm">Lecturer:</p>
            <p className="text-[#606981] text-sm font-medium capitalize">
              {item.facultyName}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-[#606981] text-sm w-1/2">Lecturer email:</p>
            <p className="text-[#606981] text-sm font-medium break-words w-1/2 text-right">
              {item.facultyEmail}
            </p>
          </div>
        </div>
        <p className="text-[#606981] text-sm font-medium">
          Progress : {item.courseProgress}%
        </p>
        <div className={styles["progress-bar-container"]}>
          <div className={styles["progress-bar"]} style={progressStyle}>
            &nbsp;
          </div>
        </div>
        <div className="flex flex-row justify-around items-center mt-8">
          <Popup
            open={popUpStatus}
            onClose={closeModalStatus}
            {...{ overlayStyle }}
          >
            {renderPopupStatus()}
          </Popup>
          <button
            onClick={openModalStatus}
            type="button"
            className="hover:bg-[#456bf1] hover:text-[#F4F9FF] text-blue-600 font-medium bg-[#F4F9FF] rounded px-3 py-2 border"
          >
            Check status
          </button>
          {/* <Link
            to={`/obe/courses/managecourses/pdf/${item["courseId"]}/${item.batchId}/${item.branch}/${item.semester}`}
            target="_blank"
          >
            <button
              type="button"
              className="hover:bg-[#456bf1] hover:text-[#F4F9FF] text-blue-600 font-medium bg-[#F4F9FF] rounded px-3 py-2 border"
            >
              Generate Report
            </button>
          </Link> */}

          <Link to={`/obe/courses/attainments/${item["courseId"]}`}>
            <button
              type="button"
              className="hover:bg-[#456bf1] hover:text-[#F4F9FF] text-blue-600 font-medium bg-[#F4F9FF] rounded px-3 py-2 border"
            >
              View Attainments
            </button>
          </Link>
        </div>
      </span>

      <div className="flex flex-row justify-center items-center m-0 p-0 mt-5 w-full h-14">
        <button
          type="button"
          onClick={createCo}
          className={`${
            item.coCreated !== null && item.coCreated.toLowerCase() === "y"
              ? styles["overlay-labels-co-y"]
              : styles["overlay-labels-co"]
          } w-full border h-full`}
        >
          Create CO&apos;s
        </button>
        <button
          type="button"
          onClick={mapQuestionPaper}
          className={`${styles["overlay-labels-qp"]} w-full border h-full`}
        >
          Map Question Paper
        </button>
        <button
          type="button"
          onClick={feedback}
          className={`${
            (item.feedbackCreated !== null &&
              item.feedbackCreated.toLowerCase()) === "y"
              ? styles["overlay-labels-fb-y"]
              : styles["overlay-labels-fb"]
          } w-full border h-full`}
        >
          Enter Feedback
        </button>
      </div>
    </li>
  );
};

export default CoursesList;
