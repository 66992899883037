import { Link, withRouter } from "react-router-dom";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import * as jose from "jose";
import apiClient from "../../../../utils/apiUrls/apiClient";
import * as Highcharts from "highcharts";
import { Oval } from "react-loader-spinner";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";
HighchartsHeatmap(Highcharts);
require("highcharts/modules/exporting");

const cat = [
  "create",
  "evaluate",
  "analyse",
  "apply",
  "understand",
  "remember",
];

const Analysis = (props) => {
  const [facultyNames, updateFacultyNames] = useState([]);
  const [performance, updatePerformance] = useState([]);
  const [loader, updateLoader] = useState(true);

  const [batch, updateBatch] = useState("");
  const [branch, updateBranch] = useState("");
  const [semester, updateSemester] = useState("");
  const [userType, updateType] = useState("");
  const [course, updateCourse] = useState("");
  const [dropCourseOptions, UpdateCourseOptions] = useState([]);
  const [dropBranchOptions, UpdateBranchOptions] = useState([]);
  const [dropSemOptions, UpdateSemOptions] = useState([]);
  const [dropBatchOptions, UpdateBatchOptions] = useState([]);

  const options = {
    chart: {
      type: "heatmap",
      //   width: 580,
      // height: 100,
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    xAxis: {
      categories: [
        "Create",
        "Evaluate",
        "Analyse",
        "Apply",
        "Understand",
        "Remember",
      ],
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        rotation: 0,
        style: {
          color: "#456BF1",
          fontWeight: 400,
          fontSize: 13,
          fontFamily: "Merriweather Sans",
        },
      },
      opposite: true,
    },

    yAxis: {
      categories: facultyNames,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        style: {
          color: "#606981",
          fontWeight: 400,
          fontSize: 15,
          fontFamily: "Merriweather Sans",
        },
      },
    },

    accessibility: {
      enabled: false,
    },

    plotOptions: {
      column: {
        // pointPadding: 0.4,
        borderWidth: 0,
        // pointWidth: 30,
        // maxPointWidth: 30,
      },

      bar: {
        dataLabels: {
          enabled: true,
        },
      },

      series: {
        // pointPadding: 0,
        // borderRadius: 3,
        // rowsize: 0.99,
        // colsize: 0.99,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: "none",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
    },

    colorAxis: {
      minColor: "#F0F7FE",
      maxColor: "#2A6FCA",
      dataClasses: [
        {
          to: 50,
          color: "#CBE3FC",
        },
        {
          from: 50,
          to: 60,
          color: "#CBE3FC",
        },
        {
          from: 60,
          to: 70,
          color: "#7EBAF9",
        },
        {
          from: 70,
          to: 80,
          color: "#3F91F7",
        },
        {
          from: 80,
          to: 90,
          color: "#2A6FCA",
        },
        {
          from: 90,
          to: 100,
          color: "#04172C",
        },
      ],
    },

    legend: {
      title: {
        text: 'Color<br/><br/><span style="font-size: 16px; color: #000000; font-family: Merriweather Sans; font-weight: 600;">Attainment Level<br/></span>',
      },
      reversed: true,
      align: "right",
      layout: "vertical",
      margin: 80,
      verticalAlign: "top",
      itemMarginTop: 10,
      y: 20,
      symbolRadius: 0,
      itemStyle: {
        color: "#707070",
        fontWeight: "normal",
        fontFamily: "Lato",
        fontSize: 13,
      },

      labelFormatter: function () {
        if (this.from === undefined) {
          return "Failed (<" + this.to + "%)";
        }

        if (this.from >= 90) {
          return "Mastered (90-100%)";
        }

        if (this.from >= 80) {
          return "Proficient (80-90%)";
        }
        if (this.from >= 70) {
          return "Good (70-80%)";
        }
        if (this.from >= 60) {
          return "Progressing (60-70%)";
        }
        if (this.from >= 50) {
          return "Low (50-60%)";
        }

        return "";
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        data: performance,
        type: "heatmap",
        name: "",
      },
    ],
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          UpdateBranchOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          UpdateSemOptions(response["data"]["result"]);
          updateSemester(response["data"]["result"][0]["optionValue"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length !== 0) {
          UpdateBatchOptions(response["data"]["result"]);
          updateBatch(response["data"]["result"][0]["optionValue"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    const token = Cookies.get("jwt_token");

    try {
      if (batch !== "" && branch !== "" && semester !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS_CODE_OPTIONS +
            `?batch=${batch}&branch=${branch}&semester=${semester}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          if (response["data"]["result"].length !== 0) {
            UpdateCourseOptions(response["data"]["result"]);
            updateCourse(response["data"]["result"][0]["courseId"]);
          } else {
            UpdateCourseOptions([]);
            updateCourse("");
            updateLoader(false);
          }
        } else {
          UpdateCourseOptions([]);
          updateCourse("");
          updateLoader(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateBranch(event.target.value);
  };

  const batchChanged = (event) => {
    updateBatch(event.target.value);
  };

  const semesterChanged = (event) => {
    updateSemester(event.target.value);
  };

  const courseChanged = (event) => {
    updateCourse(event.target.value);
  };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getBranchOptions();
      await getSemOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const { match } = props;
  const { path } = match;

  const filtering = (data) => {
    try {
      const facNames = [];
      const facPerform = [];
      for (let each in data) {
        let val = {
          apply: 0,
          understand: 0,
          create: 0,
          remember: 0,
          analyse: 0,
          evaluate: 0,
        };

        const updatedPVal = [];

        for (let k of data[each]["performanceValues"]) {
          for (let j in k) {
            const key = j.toLowerCase();
            const obj = {};
            obj[key] = k[j];
            updatedPVal.push(obj);
          }
        }

        for (let item of updatedPVal) {
          val = { ...val, ...item };
        }
        facNames.push(data[each]["facultyName"]);
        facPerform.push(val);
      }

      const ans = [];

      for (let i = 0; i < facNames.length; i++) {
        for (let each = 0; each < 6; each++) {
          ans.push([each, i, facPerform[i][cat[each]]]);
        }
      }

      updateFacultyNames(facNames);
      updatePerformance(ans);
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  const getData = async () => {
    updateLoader(true);
    const token = Cookies.get("jwt_token");
    try {
      if (course !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_FACULTY_ANALYSIS_HEAT_MAP +
            `?branch=${branch}&courseId=${course}&semester=${semester}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          filtering(response["data"]["result"]);
        } else {
          updateLoader(false);
          updateFacultyNames([]);
          updatePerformance([]);
        }
      } else {
        updateLoader(false);

        updateFacultyNames([]);
        updatePerformance([]);
      }
    } catch (err) {
      updateLoader(false);
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      for (const item of dropSemOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim["user_type"]);
        if (claim["user_type"] === "HOD") {
          updateBranch(claim["dept_name"]);
        }
        getOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        if (claim["user_type"] !== "HOD") {
          if (dropBranchOptions.length > 0) {
            updateBranch(dropBranchOptions[0]["optionValue"]);
          }
        }
      }
    }
  }, [dropBranchOptions]);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        if (userType !== "") {
          getCourses();
        }
      }
    }
  }, [branch, batch, semester]);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        getData();
      }
    }
  }, [course]);

  return (
    <div className={`${styles["faculty-obe"]}`}>
      <div className="flex flex-row mb-5 ml-8">
        <Link to="/obe/faculty/analysis">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/faculty/analysis"
                ? "font-bold underline"
                : "font-medium"
            } ml-4 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            ANALYSIS
          </h1>
        </Link>
        <Link to="/obe/faculty">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/faculty" ? "font-bold underline" : "font-medium"
            } ml-4 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            MANAGE FACULTY
          </h1>
        </Link>
      </div>

      <div className="w-full flex flex-row justify-between items-center ml-5 mt-6 mb-2 pr-20">
        <h1 className={styles["course-analysis-title"]}>
          Heat Map To Faculty Performance
        </h1>
        <div className={styles["filters"]}>
          <select
            onChange={batchChanged}
            value={batch}
            className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
          >
            {renderBatchOptions()}
          </select>
          {!(userType === "HOD") && (
            <select
              value={branch}
              onChange={branchChanged}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              {renderBranchOptions()}
            </select>
          )}

          <select
            value={semester}
            onChange={semesterChanged}
            className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
          >
            {renderSemOptions()}
          </select>
          {dropCourseOptions.length !== 0 && (
            <select
              onChange={courseChanged}
              value={course}
              className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
            >
              {dropCourseOptions.map((each) => (
                <option key={each["courseId"]} value={each["courseId"]}>
                  {each["courseShortName"]}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {loader ? (
        <div className="flex justify-center mt-10 w-full">
          <Oval
            height={50}
            width={50}
            color="#3d65f4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#3d65f4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className={`${styles["dashboard-periodwisebar-bg"]} mb-20`}>
          {facultyNames.length === 0 && performance.length === 0 ? (
            <div className="flex justify-center items-center p-10 mt-5 mb-5">
              <p className={styles["empty-view"]}>
                No Data Found For The Selected Filters To View Faculty
                Performance
              </p>
            </div>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              {...props}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(Analysis);
