import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import CryptoJS from "crypto-js";
import { Oval } from "react-loader-spinner";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

const EditFaculty = (props) => {
  const [facData, updateFacData] = useState({
    deptName: "",
    designation: "",
    facultyName: "",
    facultyType: "",
    facultyUsername: "",
    loginPassword: "",
    workEmail: "",
  });

  const [facId, updateFacId] = useState(null);
  const [branchOptions, updateBranchOptions] = useState([]);
  const [created, updateCreated] = useState(false);
  const [isLoading, updateLoading] = useState(false);
  const [pwdHidden, updatePwdHidden] = useState(true);

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_FACULTY`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of branchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const formSubmitted = async (event) => {
    event.preventDefault();
    updateLoading(true);

    try {
      const token = Cookies.get("jwt_token");

      const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
      const encrypted = CryptoJS.AES.encrypt(
        facData["loginPassword"],
        key
      ).toString();

      const facultyData = {
        ...facData,
        loginPassword: encrypted,
      };

      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(facultyData),
      };

      const response = await fetch(
        apiClient.urls.fatcat.UPDATE_FACULTY + `?facultyId=${facId}`,
        options
      );

      // console.log(response);

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updateCreated(true);
        } else {
          alert(res["description"]);
        }
      } else {
        updateFacData({
          deptName: "",
          designation: "",
          facultyId: "",
          facultyName: "",
          loginPassword: "",
          workEmail: "",
          facultyType: "",
        });
        updateFacId(null);
      }

      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deptChanged = (event) => {
    updateFacData({ ...facData, deptName: event.target.value });
  };

  const designationChanged = (event) => {
    updateFacData({ ...facData, designation: event.target.value });
  };

  const facultyNameChanged = (event) => {
    updateFacData({ ...facData, facultyName: event.target.value });
  };

  const loginPasswordChanged = (event) => {
    updateFacData({ ...facData, loginPassword: event.target.value });
  };

  const mailChanged = (event) => {
    updateFacData({ ...facData, workEmail: event.target.value });
  };

  const okBtn = () => {
    const { reload, closeBtn } = props;
    updateCreated(false);
    updateFacData({
      deptName: "",
      designation: "",
      facultyId: "",
      facultyName: "",
      loginPassword: "",
      workEmail: "",
      facultyType: "",
    });
    updateFacId(null);
    reload();
    closeBtn();
  };

  useEffect(() => {
    if (navigator.onLine) {
      const { data } = props;
      const key = process.env.REACT_APP_PASSWORD_ENCRYPT_SECRET;
      const decrypted = CryptoJS.AES.decrypt(data["password"], key).toString(
        CryptoJS.enc.Utf8
      );

      updateFacData({
        deptName: data["facultyDept"],
        designation: data["facultyDesignation"],
        facultyName: data["facultyName"],
        facultyType: data["facultyType"],
        facultyUsername: data["facultyUsername"],
        loginPassword: decrypted,
        workEmail: data["workMail"],
      });
      updateFacId(data["facultyId"]);
      getBranchOptions();
    }
  }, []);

  return (
    <>
      {created ? (
        <div className="flex flex-col justify-center items-center h-80 flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
          <img src="/assets/tick.svg" alt="new course" width={34} height={34} />
          <h1 className="text-[#606981] font-bold font-Lato-normal mt-4 text-base">
            Faculty Updated successfully!
          </h1>
          <button
            onClick={okBtn}
            className="font-Lato-normal self-center text-blue-600 border rounded-2xl text-base font-bold px-5 bg-[#E2E9FF] py-1.5 mr-5 mt-4"
            type="button"
          >
            Ok
          </button>
        </div>
      ) : (
        <form onSubmit={formSubmitted}>
          <div className=" flex flex-row justify-between flex-wrap text-[#636363] bg-[#FFFFFF] shadow mb-5 p-5 pb-10 pt-0">
            <span className="w-2/5 mr-1 flex flex-col">
              <label className={styles.labels}>Faculty ID</label>

              <input
                readOnly
                required
                type="text"
                value={facData.facultyUsername}
                className={`${styles["filter-select"]} focus:ring-0 w-5/6`}
              />
            </span>
            <span className="w-2/5 mr-1 flex flex-col">
              <label className={styles.labels}>Designation</label>

              <input
                required
                onChange={designationChanged}
                type="text"
                value={facData.designation}
                className={`${styles["filter-select"]} focus:ring-0 w-5/6`}
              />
            </span>
            <span className="w-2/5 mt-3 mr-1 flex flex-col">
              <label className={styles.labels}>Faculty Name</label>

              <input
                type="text"
                required
                onChange={facultyNameChanged}
                value={facData.facultyName}
                className={`${styles["filter-select"]} focus:ring-0 w-5/6`}
              />
            </span>
            <span className="w-2/5 mt-3 mr-1 flex flex-col">
              <label className={styles.labels}>Faculty Department</label>
              <select
                required
                onChange={deptChanged}
                value={facData.deptName}
                className={`${styles["filter-select"]} focus:ring-0`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderBranchOptions()}
              </select>
            </span>

            <span className="w-2/5 mt-3 mr-1 flex flex-col">
              <label className={styles.labels}> Login Password</label>
              <div className={styles["input-bg"]}>
                <input
                  autoComplete="off"
                  onChange={loginPasswordChanged}
                  value={facData.loginPassword}
                  type={pwdHidden ? "password" : "text"}
                  required
                  className={`${styles["input"]} w-5/6`}
                />
                <button
                  onClick={() => {
                    updatePwdHidden(!pwdHidden);
                  }}
                  type="button"
                >
                  {pwdHidden ? (
                    <IoEyeSharp className="hide-icon" />
                  ) : (
                    <IoEyeOffSharp className="hide-icon" />
                  )}
                </button>
              </div>
            </span>
            <span className="w-2/5 mt-3 mr-1 flex flex-col">
              <label className={styles.labels}>Email Id</label>
              <input
                required
                onChange={mailChanged}
                value={facData.workEmail}
                type="email"
                className={`${styles["filter-select"]} focus:ring-0 w-5/6 normal-case`}
              />
            </span>
          </div>
          <span className="flex flex-row justify-end items-end mt-10 mb-5">
            <button
              disabled={isLoading}
              className={`${
                isLoading && "cursor-not-allowed"
              } font-Lato-normal self-end text-white bg-[#456BF1] rounded-2xl text-sm font-bold px-8 py-1.5 mr-5 mt-4`}
              type="submit"
            >
              {isLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#FFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Update Faculty"
              )}
            </button>
          </span>
        </form>
      )}
    </>
  );
};

export default EditFaculty;
