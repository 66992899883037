import styles from "./index.module.css";
import { Oval } from "react-loader-spinner";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const ExamMarks = (props) => {
  const [loader, updateLoader] = useState(false);
  const [marks, updateMarks] = useState({});
  const [coNames, updateCoNames] = useState([]);
  const [rollNums, updateRollNums] = useState([]);
  const [questions, updateQuestions] = useState({});

  const { courseCode, sec, secOptions, activeTab } = props;

  const renderEmptyView = (text) => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>{text}</p>
    </div>
  );

  const renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  const getMarks = async () => {
    updateLoader(true);
    updateMarks({});
    updateCoNames([]);
    updateRollNums([]);
    updateQuestions({});

    const joinedSec = secOptions.join();

    const sectionsVal = sec === "" ? joinedSec : sec;

    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_ATTAINMENTS_TEMPLATE_MARKS +
          `?courseId=${courseCode}&sectionList=${sectionsVal}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateMarks(response?.["data"]?.["result"]?.[activeTab] ?? {});
      }
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  const renderCosHeader = () => {
    const cos = Object.keys(marks?.coHeaderSection ?? {});

    const coHeaderData = cos.map((each) => {
      const qstns = Object.keys(marks?.coHeaderSection?.[each] ?? {});
      return (
        <>
          <th colSpan={qstns.length - 1}>{each}</th>
          <th className={styles["marks-table-total"]}>Total</th>
        </>
      );
    });

    return coHeaderData;
  };

  const renderMarksHeader = () => {
    const cos = Object.keys(marks?.coHeaderSection ?? {});

    const marksHeaderData = [];

    cos.map((each) => {
      const qstns = Object.keys(marks?.coHeaderSection?.[each] ?? {});

      qstns.map((item) => {
        if (item !== "totalMaxMarks") {
          marksHeaderData.push(
            <td>{marks?.coHeaderSection?.[each]?.[item]}</td>
          );
        }
      });

      marksHeaderData.push(
        <td className={styles["marks-table-total-value"]}>
          {marks?.coHeaderSection?.[each]?.["totalMaxMarks"]}
        </td>
      );
    });

    return marksHeaderData;
  };

  const renderQuestionsHeader = () => {
    const cos = Object.keys(marks?.coHeaderSection ?? {});

    const qstnsHeaderData = [];

    cos.map((each) => {
      const qstns = Object.keys(marks?.coHeaderSection?.[each] ?? {});

      qstns.map((item) => {
        if (item !== "totalMaxMarks") {
          qstnsHeaderData.push(<td>{item}</td>);
        }
      });

      qstnsHeaderData.push(
        <td className={styles["marks-table-total-value"]}>
          {qstns.length - 1}
        </td>
      );
    });

    return qstnsHeaderData;
  };

  const renderStdMarks = (roll) => {
    const stdMarks = [];
    stdMarks.push(<th>{roll}</th>);

    coNames.map((each) => {
      questions[each].map((item) => {
        if (item !== "coTotalMarks") {
          stdMarks.push(<td>{marks?.[each]?.[roll]?.[item] ?? ""}</td>);
        }
      });
      stdMarks.push(
        <td className={styles["marks-table-total-value"]}>
          {marks?.[each]?.[roll]?.["coTotalMarks"] ?? ""}
        </td>
      );
    });

    return stdMarks;
  };

  const filterMarks = () => {
    try {
      const cosData = Object.keys(marks?.coHeaderSection ?? {});
      let rollNumbers = [];

      if (cosData.length > 0) {
        cosData.map((co) => {
          rollNumbers = [...rollNumbers, ...Object.keys(marks?.[co] ?? {})];
        });
      }

      const qstnsHeaderData = {};

      cosData.map((each) => {
        const qstns = Object.keys(marks?.coHeaderSection?.[each] ?? {});

        const data = qstns.filter((item) => item !== "totalMaxMarks");

        qstnsHeaderData[each] = data;
      });

      updateRollNums([...new Set(rollNumbers)]);
      updateCoNames(cosData);
      updateQuestions(qstnsHeaderData);
    } catch (err) {
      console.log(err);
    }
  };

  const updateCoWt = async () => {
    updateLoader(true);
    const token = Cookies.get("jwt_token");
    try {
      const reqBody = Object.keys(marks?.coFooterSection).map((each) => {
        const val = document.getElementById(each).value;

        return {
          co: each,
          // coTemplateWeightage: 0,
          courseId: courseCode,
          templateName: activeTab,
          thValue: val,
        };
      });

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(
        apiClient.urls.fatcat
          .OBE_ATTAINMENTS_CO_SPECIFIC_TEMPLATE_WEIGHTAGE_UPDATE,
        options
      );
      const res = await response.json();

      if (res["result"] === "OK") {
        getMarks();
      } else {
        alert("Weightages are not updated");
        updateLoader(false);
      }
    } catch (err) {
      updateLoader(false);
      alert("Weightages are not updated");
      console.log(err);
    }
  };

  useEffect(() => {
    getMarks();
  }, [courseCode, activeTab, sec]);

  useEffect(() => {
    filterMarks();
  }, [marks]);

  return loader ? (
    renderLoader()
  ) : Object.keys(marks).length === 0 ? (
    renderEmptyView("No data found")
  ) : (
    <span className="bg-[#fff]">
      <div className={styles["marks-table-container"]}>
        <table className={`${styles["marks-table"]} mt-8`}>
          <tr>
            <td></td>
            {renderCosHeader()}
          </tr>
          <tr>
            <td>Max Marks</td>
            {renderMarksHeader()}
          </tr>

          <tr>
            <td>Question</td>
            {renderQuestionsHeader()}
          </tr>

          <tbody>
            {rollNums.map((each) => (
              <tr>{renderStdMarks(each)}</tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row mt-10 bg-[#fff] pl-6 pr-6">
        <table className={styles["threshold-table"]}>
          <tr className="bg-[#EFF3FF]">
            <td></td>
            {Object.keys(marks?.coFooterSection).map((each) => (
              <th>{each}</th>
            ))}
          </tr>
          <tbody>
            <tr>
              <th>Attempts (A)</th>
              {Object.keys(marks?.coFooterSection).map((each) => (
                <td>{marks?.coFooterSection?.[each]?.noAttempts ?? ""}</td>
              ))}
            </tr>

            <tr>
              <th>Threshold</th>
              {Object.keys(marks?.coFooterSection).map((each, idx) => (
                <td key={idx}>
                  <input
                    id={`${each}`}
                    defaultValue={marks?.coFooterSection?.[each]?.thValue ?? ""}
                  />
                  %
                </td>
              ))}
            </tr>

            <tr>
              <th>Score Above Threshold (T)</th>
              {Object.keys(marks?.coFooterSection).map((each) => (
                <td>{marks?.coFooterSection?.[each]?.noStAchievedCo ?? ""}</td>
              ))}
            </tr>

            <tr className="bg-[#EFF3FF]">
              <th>Attainment - (T/A)</th>
              {Object.keys(marks?.coFooterSection).map((each) => (
                <td>
                  {marks?.coFooterSection?.[each]?.templateCoAttPct ?? ""}(
                  {marks?.coFooterSection?.[each]?.templateCoAttPctLvl ?? ""})
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        <div className={styles["std-atd-info"]}>
          {/* <p>TOTAL STUDENTS: 109</p>
          <p>PRESENT: 99</p>
          <p>ABSENT: 10</p> */}

          <button
            type="button"
            onClick={updateCoWt}
            className={styles["threshold-btn"]}
          >
            Update
          </button>
        </div>
      </div>
    </span>
  );
};

export default ExamMarks;
