import { Editor } from "@tinymce/tinymce-react";
import { Component } from "react";
import Cookies from "js-cookie";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.css";

const initialContent = `<p>&nbsp;</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://fatcat-metrics-images.s3.ap-south-2.amazonaws.com/fatcat/fatcat-logo.png" alt="" width="68" height="63"></p>
<p style="text-align: center;"><span style="font-size: 14pt; font-family: 'comic sans ms', sans-serif;"><strong>Welcome to Fatcat!</strong></span></p>`;

const token = Cookies.get("jwt_token");

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};

class Input extends Component {
  state = { content: "", loading: true };

  componentDidMount() {
    const { metricInfo } = this.props;
    this.setState({
      content: metricInfo?.data ?? initialContent,
      loading: false,
    });
  }

  handleEditorChange = (data) => {
    this.setState({ content: data });
  };

  handleImageUpload = async (blobInfo) => {
    const { metricsId } = this.props;

    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(
      apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA_IMG_UPLOAD +
        `/${metricsId}`,
      options
    );

    if (response.ok) {
      const url = await response.json();
      return url;
    }

    return "";
  };

  submitted = async () => {
    const { content } = this.state;
    const { metricsId, metricInfo, template } = this.props;

    const reqBody = { data: content };

    const options = {
      method: metricInfo === undefined ? "POST" : "PUT",
      headers,
      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.CONNECT_DASHBOARD_METRIC_DATA +
          `/${metricsId}` +
          `?templateName=${template}`,
        options
      );

      if (response.ok) {
        const { uploadedStatus } = this.props;
        uploadedStatus();
      } else {
        alert("Failed to upload data");
      }
    } catch (err) {
      console.log(err);
      alert("Failed to upload data");
    }
  };

  render() {
    const { content, loading } = this.state;

    const editorKey = process.env.REACT_APP_TINY_MCE_KEY;

    return loading ? (
      <div className="flex justify-center items-center w-full h-80">
        <Oval
          height={50}
          width={50}
          color="#3D65F4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#3D65F4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    ) : (
      <div className="flex flex-col p-2 z-0">
        <Editor
          value={content}
          onEditorChange={this.handleEditorChange}
          apiKey={editorKey}
          init={{
            removed_menuitems: "newdocument",
            link_default_target: "_blank",
            link_target_list: [{ title: "New Window", value: "_blank" }],
            link_title: false,
            plugins:
              "autosave autolink charmap emoticons image link openlink lists searchreplace table visualblocks wordcount linkchecker fullscreen",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | spellcheckdialog | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
            branding: false,
            images_reuse_filename: true,
            images_upload_handler: this.handleImageUpload,
            ai_request: (request, respondWith) =>
              respondWith.string(() =>
                Promise.reject("See docs to implement AI Assistant")
              ),
          }}
        />
        <button
          className={styles["submit-btn"]}
          type="button"
          onClick={this.submitted}
        >
          Submit
        </button>
      </div>
    );
  }
}

export default Input;
