import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styles from "./index.module.css";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import StudentCoursesList from "./StudentCoursesList";
import { Oval } from "react-loader-spinner";
import { RxCross2 } from "react-icons/rx";
import Popup from "reactjs-popup";
import * as jose from "jose";
import roles from "../../../../roles";

const buttonValues = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const std = {
  stdId: "",
  rollNum: "",
  firstName: "",
  lastName: "",
  batchId: "",
  branch: "",
  section: "",
  gender: "",
  phoneNumber: "",
  email: "",
  dob: "",
  stdType: "",
};

const StudentInfo = (props) => {
  const [studentData, updateStudentData] = useState({});
  const [semButton, updateSemButton] = useState("1");
  const [studentCourses, updateStudentCourses] = useState({});
  const [loader, updateLoader] = useState(false);
  const [newStdLoader, updateNewStdLoader] = useState(false);
  const [newStudent, updateNewStudent] = useState(std);
  const [secOptions, updateSecOptions] = useState([]);
  const [dropBatchOptions, updateBatchOptions] = useState([]);
  const [dropBranchOptions, updateBranchOptions] = useState([]);
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [userType, updateType] = useState("");
  const [popUp, updatePop] = useState(false);

  const { match } = props;
  const { params } = match;
  const { stdId } = params;

  const openModal = async () => {
    updatePop(true);
  };

  const closeModal = async () => {
    updatePop(false);
  };

  const renderPopup = () => {
    return (
      <div className={`${styles["modal"]}`}>
        <p className={`${styles.text} text-center mb-10`}>
          Do you want to delete the student{" "}
          <span className="capitalize font-black">
            {studentData?.firstName}
          </span>
          ?
        </p>

        <span>
          <button
            onClick={closeModal}
            className="mr-9 text-blue-600 font-medium bg-[#F4F9FF] rounded px-5 py-1 border"
          >
            No
          </button>
          <button
            onClick={stdDelete}
            className="text-blue-600 font-medium bg-red-600 text-white rounded px-5 py-1 border"
          >
            Yes
          </button>
        </span>
      </div>
    );
  };

  const overlayStyle = { background: "rgba(0,0,0,0.5)" };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getBranchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const clickedSemBtn = (event) => {
    updateSemButton(event.target.value);
  };

  const getStudentData = async () => {
    const token = Cookies.get("jwt_token");

    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_STD_INFO +
          `?stdId=${stdId}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateStudentData(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStudentCoursesData = async () => {
    const token = Cookies.get("jwt_token");
    updateLoader(true);
    updateStudentCourses({});

    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_STD_COURSES +
          `?stdId=${stdId}&semester=${semButton}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateStudentCourses(data);
      }
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  const renderButtonOptions = () => {
    return (
      <>
        {Object.entries(buttonValues).map(([key, value]) => (
          <button
            key={key}
            value={key}
            className={`${
              semButton === key
                ? styles["sem-button-active"]
                : styles["sem-button"]
            }`}
            onClick={clickedSemBtn}
          >
            {value}
          </button>
        ))}
      </>
    );
  };

  const stdDelete = async () => {
    const token = Cookies.get("jwt_token");

    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(newStudent),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_DELETE_STUDENT +
          `?stdId=${studentData?.student_id}`,
        options
      );

      if (response.ok) {
        const { history } = props;
        return history.replace("/obe/students/performance-analysis");
      } else {
        alert("Student not deleted");
      }
    } catch (err) {
      console.log(err);
      alert("Student not deleted");
    }
  };

  const renderStudentData = () => (
    <div className={styles["stu-details-container"]}>
      <img
        src="/assets/defaultImage.svg"
        alt="dp"
        className={styles["stu-img"]}
      />
      <div className={styles["stu-profile-details"]}>
        <div className={styles["stu-name-edit"]}>
          <h1 className={styles["stu-name"]}>
            {studentData?.first_name} {studentData?.last_name}
          </h1>

          <div className={styles["stu-edit-del"]}>
            <div onClick={showOverlay} className={styles["stu-edit"]}>
              <img
                src="/studentassests/edit.svg"
                alt="edit"
                className="stu-logo"
              />
              <span className="pl-2">Edit</span>
            </div>

            {userType === roles.principal && (
              <>
                <div className={styles["ver-line"]}></div>
                <div onClick={openModal} className={styles["stu-del"]}>
                  <img
                    src="/studentassests/delete.svg"
                    alt="delete"
                    className="stu-logo"
                  />
                  <span className="pl-2">Delete</span>
                </div>
                <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
                  {renderPopup()}
                </Popup>
              </>
            )}
          </div>
        </div>

        <div className={styles["stu-details"]}>
          <div>
            <span className={`${styles["stu-span-head"]}`}>Batch</span>
            <p className={styles["stu-p"]}>{studentData?.batch_name}</p>
          </div>

          <div>
            <span className={`${styles["stu-span-head"]}`}>Branch</span>
            <p className={styles["stu-p"]}>{studentData?.branch}</p>
          </div>

          <div>
            <span className={`${styles["stu-span-head"]}`}>Class</span>
            <p className={styles["stu-p"]}>{studentData?.section}</p>
          </div>

          <div>
            <span className={`${styles["stu-span-head"]}`}>Mail ID</span>

            <div className={styles["stu-act"]}>
              <img
                src="/studentassests/email.svg"
                alt="mail"
                className="stu-logo"
              />
              <span className={`${styles["stu-details-span"]} pl-2`}>
                {studentData?.email}
              </span>
            </div>
          </div>

          <div className="h-full">
            <span className={styles["stu-span-head"]}>Contact</span>
            <div className={styles["stu-act"]}>
              <img
                src="/studentassests/call.svg"
                alt="contact"
                className="stu-logo"
              />
              <span className={`${styles["stu-details-span"]} pl-2`}>
                {studentData?.phone_number}
              </span>
            </div>
          </div>
        </div>

        {/* <div className={styles["stu-quick-act"]}>
          <span className={styles["stu-quick-span"]}>QUICK ACTIONS</span>

          <div className={styles["stu-act"]}>
            <img
              src="/assets/download.svg"
              alt="download"
              className="stu-logo"
            />
            <span className={styles["stu-span"]}>Download Profile</span>
          </div>

          <div className={`${styles["ver-line"]} h-7`}></div>

          <div className={styles["stu-act"]}>
            <img
              src="/assets/download.svg"
              alt="download"
              className="stu-logo"
            />
            <span className={styles["stu-span"]}>Download Performance</span>
          </div>

          <div className={`${styles["ver-line"]} h-7`}></div>

          <div className={styles["stu-act"]}>
            <img src="/assets/bell.svg" alt="bell" className="stu-logo" />
            <span className={styles["stu-span"]}>Contact Parent</span>
          </div>
        </div> */}
      </div>
    </div>
  );

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      if (Object.keys(studentData).length > 0) {
        const response = await apiClient.get(
          apiClient.urls.fatcat.GET_SECTIONS +
            `?branch=${studentData?.branch}&batch=${studentData?.batch_name}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          if (response["data"]["result"].length > 0) {
            updateSecOptions(response["data"]["result"]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addNewStudent = async (event) => {
    event.preventDefault();
    updateNewStdLoader(true);

    const token = Cookies.get("jwt_token");

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(newStudent),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_UPDATE_STUDENT,
        options
      );

      if (response.ok) {
        closeBtn();
        getStudentData();
        getStudentCoursesData();
        updateNewStudent(std);
      } else {
        const data = await response.json();
        alert(data);
      }
    } catch (err) {
      console.log(err);
      alert("Student not added");
    }
    updateNewStdLoader(false);
  };

  const newStdDataChanged = (event) => {
    updateNewStudent({
      ...newStudent,
      [event.target.name]: event.target.value,
    });
  };

  const addStudentOverlay = () => {
    const {
      rollNum,
      firstName,
      lastName,
      section,
      gender,
      phoneNumber,
      dob,
      email,
      stdType,
      batchId,
      branch,
    } = newStudent;

    return (
      <div
        onClick={closeBtn}
        className={isOverlayActive ? styles["overlay"] : "hidden"}
      >
        <form
          onSubmit={addNewStudent}
          onClick={preventPropagation}
          className={`${styles["overlay-card"]} flex flex-col text-[#636363] bg-[#FFFFFF] shadow p-5 pt-0 pb-10`}
        >
          <span className="flex flex-row justify-between items-center w-full mb-5">
            <h1 className={styles["overlay-title"]}>Update Student</h1>
            <span className="flex items-center">
              <RxCross2
                onClick={closeBtn}
                size={26}
                className="cursor-pointer text-[#456bf1] items-end"
              />
            </span>
          </span>

          <div className="bg-[#F5F8FC] flex flex-wrap p-5 pl-8 pr-8">
            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Roll Number</label>
              <input
                onChange={newStdDataChanged}
                name="rollNum"
                required
                value={rollNum}
                type="text"
                placeholder="ENTER"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>First Name</label>
              <input
                onChange={newStdDataChanged}
                name="firstName"
                required
                value={firstName}
                type="text"
                placeholder="ENTER"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Last Name</label>
              <input
                value={lastName}
                onChange={newStdDataChanged}
                name="lastName"
                required
                type="text"
                placeholder="ENTER"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            {/* <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Batch</label>
              <select
                value={batchId}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderBatchOptions()}
              </select>
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Branch</label>
              <select
                value={branch}
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderBranchOptions()}
              </select>
            </span> */}

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Section</label>
              <select
                value={section}
                onChange={newStdDataChanged}
                name="section"
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderSecOptions()}
              </select>
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Gender</label>
              <select
                value={gender}
                onChange={newStdDataChanged}
                required
                name="gender"
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Phone Number</label>
              <input
                value={phoneNumber}
                onChange={newStdDataChanged}
                name="phoneNumber"
                required
                type="tel"
                placeholder="ENTER"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Date of Birth</label>
              <input
                value={dob}
                onChange={newStdDataChanged}
                name="dob"
                required
                type="date"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Email Id</label>
              <input
                value={email}
                onChange={newStdDataChanged}
                name="email"
                required
                type="email"
                placeholder="ENTER"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className=" mt-3 mr-1 mb-5 flex flex-col ">
              <label className={styles["labels"]}>Admission Type</label>

              <select
                value={stdType}
                onChange={newStdDataChanged}
                required
                name="stdType"
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option value="" disabled selected hidden>
                  SELECT
                </option>
                <option value="General">General</option>
                <option value="Lateral">Lateral</option>
                <option value="Migrated">Migrated</option>
                <option value="Separate">Separate</option>
              </select>
            </span>
          </div>

          <div className="self-end mb-4 mt-1">
            <button
              disabled={newStdLoader}
              type="submit"
              className={`${styles["button"]} ${
                newStdLoader && "cursor-not-allowed"
              }`}
            >
              {newStdLoader ? (
                <Oval
                  height={30}
                  width={30}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const showOverlay = () => {
    setIsOverlayActive(true);
  };

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  const closeBtn = () => {
    setIsOverlayActive(false);
  };

  const renderSecOptions = () => {
    const ans = [];
    try {
      for (const item of secOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const token = Cookies.get("jwt_token");
    if (token !== undefined) {
      const claim = jose.decodeJwt(token);
      updateType(claim["user_type"]);
    }
    getStudentData();
    getOptions();
  }, []);

  useEffect(() => {
    getSectionOptions();
    updateNewStudent({
      stdId: studentData?.student_id,
      rollNum: studentData?.roll_num,
      firstName: studentData?.first_name,
      lastName: studentData?.last_name,
      batchId: studentData?.batch_id,
      branch: studentData?.branch,
      section: studentData?.section,
      gender: studentData?.gender,
      phoneNumber: studentData?.phone_number,
      email: studentData?.email,
      dob: studentData?.date_of_birth,
      stdType: studentData?.std_type,
    });
  }, [studentData]);

  useEffect(() => {
    getStudentCoursesData();
  }, [semButton]);

  return (
    <>
      {isOverlayActive && addStudentOverlay()}
      <>
        {renderStudentData()}
        <div className={styles["stu-academic-container"]}>
          <div className={styles["academic-container"]}>
            <button
              className={`${styles["academic-button"]} border-b-2 border-black mr-4 text-[#040C58]`}
            >
              ASSESSMENTS & COURSES
            </button>
            <button
              className={`${styles["academic-button"]} cursor-not-allowed mr-4 text-[#464D90]`}
            >
              GRADUATE ATTRIBUTES
            </button>
            <button
              className={`${styles["academic-button"]} cursor-not-allowed mr-4 text-[#464D90]`}
            >
              TECHNICAL SKILLS
            </button>
            <button
              className={`${styles["academic-button"]} cursor-not-allowed mr-4 text-[#464D90]`}
            >
              CERTIFICATIONS
            </button>
            <button
              className={`${styles["academic-button"]} cursor-not-allowed mr-4 text-[#464D90]`}
            >
              FACULTY REMARKS
            </button>
          </div>

          <div className={styles["sem-fail-sub-container"]}>
            <div>
              <span className={styles["span-sem"]}>Choose Semester</span>

              <div>{renderButtonOptions()}</div>
            </div>

            {/* <div className={styles["fail-sub-container"]}>
            <p> ! Failed - 2 Subjects need attention</p>
          </div> */}
          </div>

          <table className={styles["sub-marks-container"]}>
            <tr className={`${styles["sub-head-container"]} mb-5`}>
              <th className={`${styles["sub-head"]} text-[#8F949F]`}>
                Subjects & Attainment
              </th>
              <th className={`${styles["sub-head"]} text-[#8F949F]`}>
                Avg Atnmt(%)
              </th>
              <th className={`${styles["sub-head"]} text-[#8F949F]`}>
                Internal marks
              </th>
              <th className={`${styles["sub-head"]} text-[#8F949F]`}>
                External marks
              </th>
              <th className={`${styles["sub-head"]} text-[#8F949F]`}>
                Teacher Name
              </th>
            </tr>

            {loader ? (
              <div className="w-full flex justify-center mb-10 mt-10">
                <Oval
                  height={50}
                  width={50}
                  color="#3D65F4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#3D65F4"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              Object.values(studentCourses).map((each) => (
                <StudentCoursesList
                  stdId={stdId}
                  key={each.courseId}
                  item={each}
                />
              ))
            )}
          </table>
        </div>
      </>
    </>
  );
};

export default withRouter(StudentInfo);
