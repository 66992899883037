import AddStudentsPage from "./AddStudentsPage";
import { RxCross2 } from "react-icons/rx";
import { Link, withRouter } from "react-router-dom";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import * as jose from "jose";
import styles from "./index.module.css";
import * as Highcharts from "highcharts";
import { Oval } from "react-loader-spinner";
import HighchartsReact from "highcharts-react-official";
import StudentsList from "./StudentsList";
import roles from "../../../roles";
require("highcharts/modules/exporting");

const semRoman = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
};

const std = {
  rollNum: "",
  firstName: "",
  lastName: "",
  batchId: "",
  branch: "",
  section: "",
  gender: "",
  phoneNumber: "",
  email: "",
  dob: "",
  stdType: "",
};

const PerformanceAnalysis = (props) => {
  const [filters, updateFilters] = useState({
    semester: "",
    branchName: "",
    batch: "",
    courseId: "",
    coCourseOption: "LOWEST",
  });

  const [isStudentsPresent, updateStudentsPresent] = useState(false);

  const [stuData, updateData] = useState([]);
  const [studataUpdated, updateNewData] = useState([]);
  const [pagesCount, updatePagesCount] = useState(1);
  const [page, updatePage] = useState(1);
  const [loader, updateLoader] = useState(true);
  const [courseLoader, updateCourseLoader] = useState(true);
  const [newStdLoader, updateNewStdLoader] = useState(false);
  const [dropBatchOptions, updateBatchOptions] = useState([]);
  const [dropBranchOptions, updateBranchOptions] = useState([]);
  const [dropSemOptions, updateSemOptions] = useState([]);
  const [secOptions, updateSecOptions] = useState([]);

  const [userType, updateType] = useState("");
  const [totalStu, totalStudentUpdate] = useState("");
  const [course, getCourses] = useState([]);
  const [categories, categoriesChanged] = useState([]);
  const [seriesData, seriesDataChanged] = useState({
    avg: [],
    high: [],
    low: [],
  });

  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [lowPercent, lowPercentUpdate] = useState(40);
  const [highPercent, highPercentUpdate] = useState(70);
  const [lowAttained, lowAttainedUpdate] = useState(0);
  const [highAttained, highAttainedUpdate] = useState(0);
  const [lowAttainment, updateLowAttainment] = useState(false);
  const [highAttainment, updateHighAttainment] = useState(false);
  const [newStudent, updateNewStudent] = useState(std);
  const [abortControllerAtt, setAbortControllerAtt] = useState(null);
  const [abortControllerCourses, setAbortControllerCourses] = useState(null);
  const [abortControllerGraph, setAbortControllerGraph] = useState(null);

  const { match } = props;
  const { path } = match;

  const options = {
    chart: {
      type: "column",
      // width: 900,
      height: 280,
    },
    title: {
      text: "Student Attainment Growth Graph",
      verticalAlign: "top",
      align: "left",
      style: {
        color: "#606981",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "900",
      },
    },

    subtitle: {
      text: "",
      align: "center",
      style: {
        color: "#606981",
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Merriweather Sans",
      },
    },

    xAxis: {
      categories: categories,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        // formatter: function () {
        //   return this.value + "%";
        // },
      },
    },
    // tooltip: {
    //   valueSuffix: "%",
    // },
    accessibility: {
      enabled: false,
    },

    plotOptions: {
      column: {
        // pointPadding: 0.4,
        borderWidth: 0,
        pointWidth: 15,
        maxPointWidth: 15,
      },

      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        pointPadding: 0,
        borderRadius: 6,
      },
    },

    legend: {
      y: -40,
      align: "right",
      verticalAlign: "top",
      layout: "horizontal",
      symbolRadius: 2.8,
      itemStyle: {
        color: "#989898",
        fontWeight: "400",
      },
      enabled: true,
      reversed: true,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        data: seriesData.avg,
        type: "column",
        name: "Average",
        color: "#EBEDF6",
        marker: {
          enabled: false,
        },
      },
      {
        data: seriesData.high,
        type: "column",
        name: "High",
        color: "#9FB2F6",
        marker: {
          enabled: false,
        },
      },
      {
        data: seriesData.low,
        type: "column",
        name: "Low",
        color: "#456BF1",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  const avgLowAttainment = () => {
    if (highAttainment === false && lowAttainment === false) {
      updateLowAttainment(true);
    }
    if (lowAttainment === false && highAttainment === true) {
      updateLowAttainment(true);
      updateHighAttainment(false);
    }
    if (highAttainment === false && lowAttainment === true) {
      updateLowAttainment(false);
    }
  };

  const avgHighAttainment = () => {
    if (highAttainment === false && lowAttainment === false) {
      updateHighAttainment(true);
    }
    if (highAttainment === false && lowAttainment === true) {
      updateLowAttainment(false);
      updateHighAttainment(true);
    }
    if (lowAttainment === false && highAttainment === true) {
      updateHighAttainment(false);
    }
  };

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateBatchOptions(response["data"]["result"]);
        updateFilters((prev) => ({
          ...prev,
          batch: response["data"]["result"][0]["optionValue"],
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=SEM_VALUES&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateSemOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batchVal = dropBatchOptions.filter(
        (each) => each.optionValue == filters.batch
      );
      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branchName}&batch=${batchVal[0]["optionDesc"]}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"].length > 0) {
          updateSecOptions(response["data"]["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getSemOptions();
      await getBranchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branchName: event.target.value });
  };

  const batchChanged = (event) => {
    updateFilters({ ...filters, batch: event.target.value });
  };

  const semesterChanged = (event) => {
    const val = event.target.value;
    updateFilters({ ...filters, semester: filters.semester == val ? "" : val });
  };

  const courseIdChanged = (event) => {
    const val = event.target.id;

    updateFilters({ ...filters, courseId: filters.courseId == val ? "" : val });
  };

  const renderCourses = () => {
    let result = course.map((item) => (
      <li
        key={item.course_id}
        className={`flex items-center ${
          filters.courseId == item.course_id && styles["course-list-active"]
        }`}
        onClick={courseIdChanged}
        id={item.course_id}
      >
        {item.course_short_name}&nbsp;
        <p id={item.course_id}>
          ({item.attained_cos}/{item.total_cos}) -{" "}
          <span
            id={item.course_id}
            className={`text-${item.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}
          >
            {item.avg_att}%
          </span>
        </p>
      </li>
    ));

    return result;
  };

  const coCourseOptionChanged = (event) => {
    updateFilters({ ...filters, coCourseOption: event.target.value });
  };

  const lowAttainmentFilters = (event) => {
    lowPercentUpdate(event.target.value);
  };

  const highAttainmentFilters = (event) => {
    highPercentUpdate(event.target.value);
  };

  const attainedPercentile = () => {
    const filteredDataLow = stuData.filter((item) => item.avg_att < lowPercent);

    lowAttainedUpdate(filteredDataLow.length);

    const filteredDataHigh = stuData.filter(
      (item) => item.avg_att > highPercent
    );

    highAttainedUpdate(filteredDataHigh.length);
  };

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <li
            className={
              filters.batch == item["optionValue"]
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={batchChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType["user_type"] === "FRESHMAN") {
        ans.push(
          <li
            className={
              filters.semester == dropSemOptions[0]["optionValue"]
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[0]["optionDesc"]}
          </li>
        );

        ans.push(
          <li
            className={
              filters.semester == dropSemOptions[1]["optionValue"]
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[1]["optionDesc"]}
          </li>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <li
              className={
                filters.semester == item["optionValue"]
                  ? styles["batches-ul-active"]
                  : "bg-[#fff] text-[#606981]"
              }
              key={item["optionValue"]}
              value={item["optionValue"]}
              onClick={semesterChanged}
            >
              {item["optionDesc"]}
            </li>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const applyFiltersToData = () => {
    let filteredData = [...stuData];
    if (lowAttainment || highAttainment) {
      // Apply low attainment filter if selected
      if (lowAttainment === true) {
        filteredData = filteredData.filter((item) => item.avg_att < lowPercent);
      }

      // Apply high attainment filter if selected
      if (highAttainment === true) {
        filteredData = filteredData.filter(
          (item) => item.avg_att > highPercent
        );
      }

      // Update data only if any filter is applied
      updateNewData(filteredData);
    } else {
      // If no filters are applied, retain the original data
      updateNewData(stuData);
    }
  };

  const getStudentsData = async () => {
    if (abortControllerAtt) {
      abortControllerAtt.abort();
    }

    const controller = new AbortController();
    setAbortControllerAtt(controller);

    const { signal } = controller;

    updateLoader(true);
    updateData([]);

    const token = Cookies.get("jwt_token");

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let queryParams = `?batchId=${filters.batch}&branch=${filters.branchName}&courseId=${filters.courseId}`;

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_CO_ATTAINMENTS +
          queryParams,
        { ...options, signal }
      );

      if (response.ok) {
        const data = await response.json();
        filters.courseId === "" && data.length === 0
          ? updateStudentsPresent(false)
          : updateStudentsPresent(true);
        updateData(data);
        updateLoader(false);
      } else {
        updateLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTotalStudents = async () => {
    const token = Cookies.get("jwt_token");
    totalStudentUpdate([]);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let queryParams = `?batchId=${filters.batch}&branch=${filters.branchName}`;

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_STD_FAC_RATIO + queryParams,
        options
      );
      if (response.ok) {
        const data = await response.json();
        totalStudentUpdate(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStudentsGraph = async () => {
    if (abortControllerGraph) {
      abortControllerGraph.abort();
    }

    const controller = new AbortController();
    setAbortControllerGraph(controller);

    const { signal } = controller;

    const token = Cookies.get("jwt_token");

    categoriesChanged([]);
    seriesDataChanged({
      avg: [],
      high: [],
      low: [],
    });

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let queryParams = `?batchId=${filters.batch}&branch=${filters.branchName}`;
    const categories = [];
    const seriesData = { high: [], low: [], avg: [] };

    try {
      const response = await fetch(
        apiClient.urls.fatcat
          .OBE_STUDENTS_STUDENTS_STD_ATTAINMENTS_GROWTH_GRAPH + queryParams,
        { ...options, signal }
      );

      if (response.ok) {
        const data = await response.json();
        for (const item of data) {
          categories.push(semRoman[item.semester]);
          seriesData.high.push(item.high);
          seriesData.low.push(item.low);
          seriesData.avg.push(item.avg);
        }

        categoriesChanged(categories);
        seriesDataChanged(seriesData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCoCourses = async () => {
    if (abortControllerCourses) {
      abortControllerCourses.abort();
    }

    const controller = new AbortController();
    setAbortControllerCourses(controller);

    const { signal } = controller;

    const token = Cookies.get("jwt_token");
    updateCourseLoader(true);
    getCourses([]);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let queryParams = `?batchId=${filters.batch}&branch=${filters.branchName}&sortBy=${filters.coCourseOption}&semester=${filters.semester}`;

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_COURSES_CO_ATTAINMENTS +
          queryParams,
        { ...options, signal }
      );

      if (response.ok) {
        const data = await response.json();
        getCourses(data);
      }
    } catch (err) {
      console.log(err);
    }
    updateCourseLoader(false);
  };

  const renderEmptyView = () => (
    <div className="flex justify-center items-center p-10 mt-5 mb-5">
      <p className={styles["empty-view"]}>
        No student attainment data found for the selected filters
      </p>
    </div>
  );

  const reload = () => {
    getStudentsData();
    getCoCourses();
    getTotalStudents();
    getStudentsGraph();
  };

  const studentProfileClick = (id) => {
    const { history } = props;
    return history.push(`/obe/students/student-profile/${id}`);
  };

  useEffect(() => {
    if (filters.batch !== "" && filters.branchName !== "") {
      getStudentsData();
    }
  }, [filters.batch, filters.branchName, filters.courseId]);

  useEffect(() => {
    if (filters.batch !== "" && filters.branchName !== "") {
      getCoCourses();
    }
  }, [
    filters.batch,
    filters.branchName,
    filters.semester,
    filters.coCourseOption,
  ]);

  useEffect(() => {
    attainedPercentile();
  }, [stuData, highPercent, lowPercent]);

  useEffect(() => {
    if (!(userType["user_type"] === roles.hod)) {
      updateFilters((prev) => ({
        ...prev,
        branchName: dropBranchOptions?.[0]?.["optionValue"],
      }));
    }
  }, [dropBranchOptions]);

  useEffect(() => {
    updateFilters({ ...filters, courseId: "" });

    if (filters.batch !== "" && filters.branchName !== "") {
      getTotalStudents();
      getStudentsGraph();
      getSectionOptions();
    }
  }, [filters.batch, filters.branchName]);

  useEffect(() => {
    applyFiltersToData();
  }, [stuData, lowAttainment, highAttainment]);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim);

        if (claim["user_type"] === "HOD") {
          updateFilters({ ...filters, branchName: claim["dept_name"] });
        } else if (claim["user_type"] === "FRESHMAN") {
          updateFilters({ ...filters, semester: "1" });
        } else {
          updateFilters({ ...filters, branchName: "" });
        }
        getOptions();
      }
    }
  }, []);

  useEffect(() => {
    const len = studataUpdated.length;
    const pages = Math.ceil(len / 30);
    updatePagesCount(pages === 0 ? 1 : pages);
    updatePage(1);
  }, [studataUpdated]);

  const batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "left", 25, 100, 300);
  };

  const batchTab = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "right", 25, 100, 300);
  };

  const semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "left", 25, 100, 180);
  };

  const semTab = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "right", 25, 100, 180);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const addNewStudent = async (event) => {
    event.preventDefault();
    updateNewStdLoader(true);

    const token = Cookies.get("jwt_token");

    const reqBody = {
      ...newStudent,
      branch: filters.branchName,
      batchId: filters.batch,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_NEW_STUDENT,
        options
      );

      if (response.ok) {
        closeBtn();
        getStudentsData();
        getStudentsGraph();
        getTotalStudents();
        updateNewStudent(std);
      } else {
        const data = await response.json();
        alert(data);
      }
    } catch (err) {
      console.log(err);
      alert("Student not added");
    }
    updateNewStdLoader(false);
  };

  const newStdDataChanged = (event) => {
    updateNewStudent({
      ...newStudent,
      [event.target.name]: event.target.value,
    });
  };

  const addStudentOverlay = () => {
    const {
      rollNum,
      firstName,
      lastName,
      section,
      gender,
      phoneNumber,
      dob,
      email,
      stdType,
    } = newStudent;

    return (
      <div
        onClick={closeBtn}
        className={isOverlayActive ? styles["overlay"] : "hidden"}
      >
        <form
          onSubmit={addNewStudent}
          onClick={preventPropagation}
          className={`${styles["overlay-card"]} flex flex-col text-[#636363] bg-[#FFFFFF] shadow p-5 pt-0 pb-10`}
        >
          <span className="flex flex-row justify-between items-center w-full mb-5">
            <h1 className={styles["overlay-title"]}>Add Student</h1>
            <span className="flex items-center">
              <RxCross2
                onClick={closeBtn}
                size={26}
                className="cursor-pointer text-[#456bf1] items-end"
              />
            </span>
          </span>

          <div className="bg-[#F5F8FC] flex flex-wrap p-5 pl-8 pr-8">
            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Roll Number</label>
              <input
                onChange={newStdDataChanged}
                name="rollNum"
                required
                value={rollNum}
                type="text"
                placeholder="Ex: RollNo1"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>First Name</label>
              <input
                onChange={newStdDataChanged}
                name="firstName"
                required
                value={firstName}
                type="text"
                placeholder="Ex: John"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Last Name</label>
              <input
                value={lastName}
                onChange={newStdDataChanged}
                name="lastName"
                required
                type="text"
                placeholder="Ex: Wick"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Section</label>
              <select
                value={section}
                onChange={newStdDataChanged}
                name="section"
                required
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  SELECT
                </option>
                {renderSecOptions()}
              </select>
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Gender</label>
              <select
                value={gender}
                onChange={newStdDataChanged}
                required
                name="gender"
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option selected disabled hidden value="">
                  M
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Phone Number</label>
              <input
                value={phoneNumber}
                onChange={newStdDataChanged}
                name="phoneNumber"
                required
                type="tel"
                placeholder="Ex: 91XXXXXXXX"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Date of Birth</label>
              <input
                value={dob}
                onChange={newStdDataChanged}
                name="dob"
                required
                type="date"
                placeholder="01/01/2000"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className="w-2/5 mt-1 mr-1 mb-5 flex flex-col">
              <label className={styles["labels"]}>Email Id</label>
              <input
                value={email}
                onChange={newStdDataChanged}
                name="email"
                required
                type="email"
                placeholder="Ex: abc@gmail.com"
                className={`${styles["filter-txt"]} focus:ring-0 w-5/6 p-2`}
              />
            </span>

            <span className=" mt-3 mr-1 mb-5 flex flex-col ">
              <label className={styles["labels"]}>Admission Type</label>

              <select
                value={stdType}
                onChange={newStdDataChanged}
                required
                name="stdType"
                className={`${styles["filter-select"]} focus:ring-0 p-2`}
              >
                <option value="" disabled selected hidden>
                  General
                </option>
                <option value="General">General</option>
                <option value="Lateral">Lateral</option>
                <option value="Migrated">Migrated</option>
                <option value="Separate">Separate</option>
              </select>
            </span>
          </div>

          <div className="self-end mb-4 mt-1">
            <button
              disabled={newStdLoader}
              type="submit"
              className={`${styles["button"]} ${
                newStdLoader && "cursor-not-allowed"
              }`}
            >
              {newStdLoader ? (
                <Oval
                  height={30}
                  width={30}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const pageChange = (event) => {
    if (event.target.name === "plus") {
      page < pagesCount && updatePage(page + 1);
    } else {
      page > 1 && updatePage(page - 1);
    }
  };

  const renderStudentAttainmentData = () => {
    return (
      <div className={`${styles["students-container"]} mr-1`}>
        <div className="flex justify-between mt-5 mb-4 pl-6 pr-5">
          <div className="w-1/12 flex justify-between items-center">
            <input type="checkbox" className="scale-125" />
            <img src="/assets/reload.svg" alt="reload" className="mr-2" />

            <img src="/assets/dots.svg" alt="dots" />
          </div>

          <div className="ml-auto mr-2 flex justify-between items-center bg-[#F4F9FF] rounded-3xl p-2">
            <button
              className={`${styles["table-btns"]} flex items-center`}
              type="button"
              onClick={showOverlay}
            >
              <img src="/assets/add.svg" alt="add" className="mr-2" />
              Add Student
            </button>

            {/* <span className={styles["vertical-line"]}></span>

      <button
        className={`${styles["table-btns"]} flex items-center`}
        type="button"
      >
        <img src="/assets/download.svg" alt="add" className="mr-2" />
        Download
      </button>

      <span className={styles["vertical-line"]}></span>

      <button
        className={`${styles["table-btns"]} flex items-center`}
        type="button"
      >
        <img src="/assets/bell.svg" alt="add" className="mr-2" />
        Send Notification
      </button> */}
          </div>

          <div className="w-2/12 flex justify-between items-center">
            <button
              name="minus"
              onClick={pageChange}
              className={styles["arrow-buttons-table"]}
            >
              <img
                name="minus"
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <p className={styles["table-list-count"]}>
              {page} of {pagesCount}
            </p>
            <button
              name="plus"
              onClick={pageChange}
              className={`${styles["arrow-buttons-table"]} ${styles["right-btn"]}`}
            >
              <img
                name="plus"
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>
        <table className={styles["students-table-container"]}>
          <tr>
            <th>Student ID</th>
            <th>Name</th>
            <th>Course</th>
            <th>Contact Info</th>
            <th>Outcomes</th>
            <th>Avg Attainment</th>
          </tr>

          {studataUpdated.slice((page - 1) * 30, page * 30).map((item) => (
            <StudentsList
              item={item}
              key={item.student_id}
              studentProfileClick={studentProfileClick}
            />
          ))}
        </table>
        {studataUpdated.length === 0 && renderEmptyView()}
      </div>
    );
  };

  const showOverlay = () => {
    setIsOverlayActive(true);
  };

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  const closeBtn = () => {
    setIsOverlayActive(false);
  };

  const renderSecOptions = () => {
    const ans = [];
    try {
      for (const item of secOptions) {
        ans.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isOverlayActive && addStudentOverlay()}
      <div className={styles["faculty-obe"]}>
        <div className="flex flex-row ml-5">
          <Link to="/obe/students/performance-analysis">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/students/performance-analysis"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              PERFORMANCE ANALYSIS
            </h1>
          </Link>
          {/* <Link to="/obe/students/skill-analysis">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/students/skill-analysis"
                ? "font-bold underline"
                : "font-medium"
            } ml-10 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            SKILL ANALYSIS
          </h1>
        </Link> */}
        </div>

        <div className={`${styles["students-bg"]} mb-5`}>
          <div className={`${styles["students-filters"]} w-3/12`}>
            {!(userType["user_type"] === roles.hod) && (
              <select
                value={filters.branchName}
                className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none ml-5 mt-7 mb-1.5 pr-5`}
                onChange={branchChanged}
              >
                {renderBranchOptions()}
              </select>
            )}
          </div>

          <div className={`${styles["students-filters"]} ml-3 w-5/12`}>
            <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
            <div className="flex relative">
              <button
                onClick={batchTabLeft}
                className={styles["arrow-buttons"]}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul id="batch-tabs" className={styles["batches-ul"]}>
                {renderBatchOptions()}
              </ul>

              <button
                onClick={batchTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          <div className={`${styles["students-filters"]} ml-2 w-3/12`}>
            <h1 className={`${styles["filters-title"]}`}>Semester</h1>

            <div className="flex relative">
              <button onClick={semTabLeft} className={styles["arrow-buttons"]}>
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul
                id="sem-tabs"
                className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
              >
                {renderSemOptions()}
              </ul>

              <button
                onClick={semTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>

          <div className={`${styles["students-filters"]} w-3/12`}>
            <h1 className={`${styles["filters-title"]} pl-4`}>Performance</h1>

            <ul className={`${styles["performance-filter"]}`}>
              <li className={styles["batches-ul-active"]}>Attainment</li>
              <li className="cursor-not-allowed bg-[#fff] text-[#606981]">
                Marks
              </li>
            </ul>
          </div>
        </div>

        {loader ? (
          <div className="fixed top-2/4 left-2/4">
            <Oval
              height={80}
              width={80}
              color="#3D65F4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3D65F4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : isStudentsPresent ? (
          <>
            <div className="flex justify-between items-center ml-5">
              <div
                className={`${styles["attainment-card"]} ${styles["attainment-list"]} justify-start`}
              >
                <div className="flex justify-between items-baseline">
                  <h2>Course Attainment List</h2>

                  <select
                    value={filters.coCourseOption}
                    className={`${styles["attainment-filter"]} ${styles["attainment-list-filter"]} focus:ring-0`}
                    onChange={coCourseOptionChanged}
                  >
                    <option value="LOWEST">Lowest</option>
                    <option value="HIGHEST">Highest</option>
                  </select>
                </div>

                <ul className="list-none p-0 flex flex-wrap mt-3">
                  {courseLoader ? (
                    <div className="flex justify-center w-full mt-16">
                      <Oval
                        height={50}
                        width={50}
                        color="#3D65F4"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#3D65F4"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : (
                    renderCourses()
                  )}
                </ul>
              </div>

              <div
                className={`${styles["attainment-card"]} ${styles["std-attainment-graph"]} justify-start`}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  {...props}
                />
              </div>
            </div>

            <div className="flex items-center mt-4 ml-5">
              <div
                onClick={avgLowAttainment}
                className={`
                  ${
                    lowAttainment
                      ? `${styles["attainment-card-active"]} ${styles["attainment-card"]}`
                      : styles["attainment-card"]
                  } justify-between`}
              >
                <div className="flex justify-between items-center">
                  <img
                    src="/assets/like.svg"
                    alt="Like"
                    className="rotate-180"
                  />

                  <h2>Average Attainment</h2>

                  <select
                    value={lowPercent}
                    className={`${styles["attainment-filter"]} focus:ring-0`}
                    onChange={lowAttainmentFilters}
                  >
                    <option value={40}>&lt;40%</option>
                    <option value={30}>&lt;30%</option>
                    <option value={20}>&lt;20%</option>
                    <option value={10}>&lt;10%</option>
                  </select>
                </div>

                <div className="flex items-center">
                  <h1 className="flex mr-2">{lowAttained}</h1>
                  <span>Low Attainment Students</span>
                </div>

                {/* <p className="flex items-center">
            <img
              src="/assets/arrowup.svg"
              alt="increase"
              className="bg-[#BDEFB8] p-1 rounded-3xl mr-2"
            />
            6.75% this week
          </p> */}
              </div>
              <div
                onClick={avgHighAttainment}
                className={`
                  ${
                    highAttainment
                      ? `${styles["attainment-card-active"]} ${styles["attainment-card"]}`
                      : styles["attainment-card"]
                  } justify-between`}
              >
                <div className="flex justify-between items-center">
                  <img src="/assets/like.svg" alt="Like" className="rotate-0" />

                  <h2>Average Attainment</h2>

                  <select
                    value={highPercent}
                    className={`${styles["attainment-filter"]} focus:ring-0`}
                    onChange={highAttainmentFilters}
                  >
                    <option value={70}>&gt;70%</option>
                    <option value={80}>&gt;80%</option>
                    <option value={90}>&gt;90%</option>
                  </select>
                </div>
                <div className="flex items-center">
                  <h1 className="flex mr-2">{highAttained}</h1>
                  <span>High Attainment Students</span>
                </div>

                {/* <p className="flex items-center">
            <img
              src="/assets/arrowup.svg"
              alt="increase"
              className="bg-[#BDEFB8] p-1 rounded-3xl mr-2"
            />
            6.75% this week
          </p> */}
              </div>

              <div
                className={`${styles["card-container"]} bg-[#F6F5FF] flex flex-col justify-between items-start`}
              >
                <div className="flex justify-between items-center">
                  <img src="/assets/graduate.svg" alt="students" />
                  <h2>Total Students</h2>
                </div>

                <h1>{totalStu.totalStudents}</h1>
              </div>
              <div
                className={`${styles["card-container"]} bg-[#F1F8FE] flex flex-col justify-between items-start`}
              >
                <div className="flex justify-between items-center">
                  <img src="/assets/teaching.svg" alt="students" />
                  <h2>Student to faculty ratio</h2>
                </div>

                <h1>1:{totalStu.stdFacRatio}</h1>
              </div>
              {/* <div className={styles["attainment-card"]}>
          <div className="flex items-center">
            <img src="/assets/risk.svg" alt="risk" className="mr-2" />

            <h2>Risk Prediction</h2>
          </div>

          <div className="flex items-center">
            <h1 className="flex mr-2">20</h1>

            <span>
              Students are at risk of non-attainment for next semesters{" "}
            </span>
          </div>

          <p className="flex items-center">
            <img
              src="/assets/arrowup.svg"
              alt="increase"
              className="bg-[#BDEFB8] p-1 rounded-3xl mr-2"
            />
            6.75% this week
          </p>
        </div> */}
            </div>

            {renderStudentAttainmentData()}
          </>
        ) : (
          <AddStudentsPage
            reload={reload}
            batch={filters.batch}
            branch={filters.branchName}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(PerformanceAnalysis);
