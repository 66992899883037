import { IoInformationCircleOutline } from "react-icons/io5";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";

const MappedQuestionPaper = (props) => {
  const [qpMapping, updateQpMapping] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [desc, updateDesc] = useState([]);

  const { templateName, data, mappedGoBack } = props;

  const fetchQpMapping = async () => {
    updateLoading(true);
    updateQpMapping([]);

    const token = Cookies.get("jwt_token");

    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_EXAM_PAPER_FETCH +
          `?courseId=${data?.courseId}&templateName=${templateName}`,
        options
      );

      if (response.ok) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          updateQpMapping(res["result"]);
        }
      }
    } catch (err) {
      console.log(err);
    }

    updateLoading(false);
  };

  const uploadQpMapping = async () => {
    updateLoading(true);

    const token = Cookies.get("jwt_token");

    const reqBody = qpMapping.map((each) => ({
      maxMarks: each.maxMarks,
      questionDesc: each.questionDesc.replace(/'/g, ""),
      questionNo: each.questionNo,
      refId: each.qid,
      requestId: each.requestId,
      upDtCo: each.predictedCo,
      updtBL: each.predictedBL,
      updtReasonBL: each.reasonBL.replace(/'/g, ""),
      updtReasonCo: each.reasonCo.replace(/'/g, ""),
    }));

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_EXAM_PAPER_UPDATE,
        options
      );

      if (response.ok) {
        const res = await response.json();

        if (res["result"] === "OK") {
          alert("Mapping updated successfully!");
          fetchQpMapping();
        } else {
          alert("Mapping not updated");
        }
      } else {
        alert("Mapping not updated");
      }
    } catch (err) {
      console.log(err);
      alert("Mapping not updated");
    }

    updateLoading(false);
  };

  const getDescCo = async () => {
    const id = data?.courseId;
    const token = Cookies.get("jwt_token");
    updateDesc([]);
    try {
      const responseDesc = await apiClient.get(
        apiClient.urls.fatcat.FACULTY_FEEDBACK_CO_DESCRIPTION +
          `?courseId=${id}`,
        { Authorization: token }
      );

      if (responseDesc["data"]["statusCode"] === "OK") {
        updateDesc(responseDesc["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyView = (text) => (
    <div className="flex justify-center items-center p-10 mt-16 mb-5">
      <p className={styles["empty-view"]}>{text}</p>
    </div>
  );

  const renderCoOptions = () => {
    return desc.map((each) => (
      <option className="text-left" value={each?.coName}>
        {each?.coName}{" "}
        <span className={styles["co-Description"]}>{each?.coDesc}</span> &nbsp;
      </option>
    ));
  };

  const mappedDataChanged = (event) => {
    const name = event.target.name;
    const id = event.target.id;
    const val = event.target.value;

    const updatedData = qpMapping.map((each) => {
      if (each.qid === parseInt(id)) {
        return name === "co"
          ? { ...each, predictedCo: val }
          : { ...each, maxMarks: val };
      }

      return each;
    });

    updateQpMapping(updatedData);
  };

  const mappedBloomDataChanged = (event) => {
    const id = event.target.id;
    const val = event.target.value;

    const updatedData = qpMapping.map((each) => {
      if (each.qid === parseInt(id)) {
        return { ...each, predictedBL: val };
      }

      return each;
    });

    updateQpMapping(updatedData);
  };

  const renderQuestionMappedLi = (qstn) => {
    const {
      questionNo,
      questionDesc,
      predictedCo,
      maxMarks,
      qid,
      reasonCo,
      predictedBL,
    } = qstn;

    return (
      <tr className="text-center">
        <td>{questionNo}</td>
        <td className="text-left w-4/6">{questionDesc}</td>
        <td className={styles["co-cell"]}>
          <select
            onChange={mappedDataChanged}
            name="co"
            id={qid}
            value={predictedCo}
            className={styles["co-select"]}
          >
            <option value=""></option>
            {renderCoOptions()}
          </select>
          <div className={styles["tooltip"]}>
            <IoInformationCircleOutline className={styles["info-icon"]} />
            <span className={styles["tooltip-text"]}>{reasonCo}</span>
          </div>
        </td>
        <td>
          <input
            onChange={mappedDataChanged}
            name="marks"
            id={qid}
            className={styles["marks-input"]}
            value={maxMarks}
          />
        </td>
        <td>
          <select
            onChange={mappedBloomDataChanged}
            name="bloom"
            id={qid}
            value={predictedBL}
            className={styles["bloom-select"]}
          >
            <option value=""></option>
            <option value={"L1"}>L1</option>
            <option value={"L2"}>L2</option>
            <option value={"L3"}>L3</option>
            <option value={"L4"}>L4</option>
            <option value={"L5"}>L5</option>
            <option value={"L6"}>L6</option>
          </select>
        </td>
      </tr>
    );
  };

  const renderMappedData = () => (
    <table className={styles["mapped-qp-table"]}>
      <tr>
        <th>Q.No</th>
        <th>Question</th>
        <th>CO Mapped</th>
        <th>Maximum Marks</th>
        <th>Bloom's</th>
      </tr>

      {qpMapping.map((each) => renderQuestionMappedLi(each))}
    </table>
  );

  const goBack = () => {
    mappedGoBack();
  };

  const renderScreen = () => (
    <div className={`overflow-y-scroll h-3/4`}>
      {qpMapping.length === 0
        ? renderEmptyView("No Mapping Data Found")
        : renderMappedData()}
    </div>
  );

  useEffect(() => {
    getDescCo();
    fetchQpMapping();
  }, []);

  return loading ? (
    <div className="fixed top-2/4 left-2/4">
      <Oval
        height={70}
        width={70}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : (
    <div className="overflow-hidden h-full">
      {renderScreen()}

      <span
        className={`flex flex-row justify-end items-end mb-2 mt-2 absolute right-10 bottom-5`}
      >
        <button onClick={goBack} className={styles["back-button"]}>
          Go Back
        </button>
        {qpMapping.length > 0 && !loading && (
          <button
            disabled={loading}
            onClick={uploadQpMapping}
            className={`${styles["submit-button"]} ${
              loading && "cursor-not-allowed"
            }`}
          >
            Submit
          </button>
        )}
      </span>
    </div>
  );
};

export default MappedQuestionPaper;
