import ManageCourses from "./ManageCourses";
import Analysis from "./Analysis";
import Attainments from "../Attainments";
import SideBar from "../../layouts/SideBar";
import FacultyNavBar from "../../layouts/FacultyNavBar";
import "./index.css";
import "../../LoginForm/index.css";

const FacultyDashboardPage = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <span className="flex flex-row justify-center h-screen overflow-hidden">
      <div className="home flex flex-row overflow-y-auto overflow-x-hidden bg-[#FFFFFF]">
        <SideBar />
        <div className="flex flex-col">
          <FacultyNavBar />

          {path.includes("/faculty/dashboard/managecourses") ? (
            <div className="flex flex-col overflow-hidden">
              <ManageCourses />
            </div>
          ) : (
            <div className="flex flex-col overflow-y-auto">
              <Attainments />
            </div>
          )}
        </div>
      </div>
    </span>
  );
};

export default FacultyDashboardPage;
