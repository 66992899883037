import styles from "./index.module.css";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting");

const AnalysisCourseAttainment = (props) => {
  const { graph, data, content, actSem, updateActSem } = props;

  const options = {
    chart: {
      type: "column",
      // width: 900,
      height: 245,
    },
    title: {
      text: graph.length === 0 ? "Analytics will appear after a semester" : "",
      verticalAlign: "middle",
      align: "center",
      style: {
        color: "#9FB2F6",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "900",
      },
    },

    subtitle: {
      text: "",
      align: "center",
      style: {
        color: "#606981",
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: "Merriweather Sans",
      },
    },

    xAxis: {
      categories:
        graph.length !== 0
          ? graph.map((each) => each["courseShortName"])
          : ["course 1", "course 2", "course 3", "course 4"],
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: "justify",
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    tooltip: {
      valueSuffix: "%",
    },
    accessibility: {
      enabled: false,
    },

    plotOptions: {
      column: {
        // pointPadding: 0.4,
        borderWidth: 0,
        pointWidth: 30,
        maxPointWidth: 30,
      },

      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        // pointPadding: 0,
        // borderRadius: 3,
      },
    },

    legend: {
      y: 20,
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
      symbolRadius: 0,
      itemStyle: {
        color: "#8F949F",
        fontWeight: "normal",
      },
      // enabled: graph.length !== 0,
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        data:
          graph.length !== 0 ? graph.map((each) => each.coPct) : [0, 0, 0, 0],
        type: "column",
        name: graph.length !== 0 && graph[0].batchName,
        color: "#9FB2F6",
      },
      // {
      //   data: [19, 32, 43, 21, 61, 31, 10, 23],
      //   type: "column",
      //   name: "2016-20",
      //   // color: "#9FB2F6",
      //   color: "transparent",
      //   marker: {
      //     enabled: false,
      //   },
      // },
      // {
      //   data: [10, 23, 53, 28, 40, 10, 10, 23],
      //   type: "column",
      //   name: "2015",
      //   // color: "#B6DAF9",
      //   color: "transparent",
      //   marker: {
      //     enabled: false,
      //   },
      // },
      // {
      //   data: [11, 22, 43, 62, 53, 28, 40, 10],
      //   type: "column",
      //   name: "2018",
      //   // color: "#D9E0FF",
      //   color: "transparent",
      //   marker: {
      //     enabled: false,
      //   },
      // },
    ],
  };

  const renderTr = () => {
    try {
      const ans = [];

      for (const each of data) {
        ans.push(
          <tr
            className={`${styles["table-th"]} border border-blue-500 text-[#040C58]`}
          >
            <th className="border border-blue-500 p-2 bg-[#EFF3FF]" colSpan={2}>
              {each["courseCode"]}
            </th>
            <td
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981] `}
            >
              {each["numOfAttCos"]}/{each["numOfCos"]}
            </td>
          </tr>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const semClicked = () => {
    updateActSem();
  };

  return (
    <>
      <div className={styles["dashboard-periodwisebar-bg"]}>
        <div className={styles["barchart-container"]}>
          <div className={styles["line-chart"]}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              {...props}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisCourseAttainment;
