import styles from "./index.module.css";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import AnalysisCourseAttainment from "./AnalysisCourseAttainment";
import CoursesAnalysisOutcome from "./CoursesAnalysisOutcome";
import { Link, withRouter } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";

const CoursesAnalysis = (props) => {
  const [filters, updateFilters] = useState({
    batch: "",
    branch: "",
  });

  const [activeSem, updateActiveSem] = useState(1);
  const [data, updateData] = useState([]);
  const [breakData, updateBreakData] = useState([]);
  const [content, updateContent] = useState(false);
  const [breakContent, updateBreakContent] = useState(false);
  const [view, updateView] = useState("loading");
  const [graph, updateGraph] = useState([]);
  const [graph1, updateGraph1] = useState([]);
  const [dropBatchOptions, UpdateBatchOptions] = useState([]);
  const [dropBranchOptions, UpdateBranchOptions] = useState([]);
  const [userType, updateType] = useState("");
  const [overlay, updateOverlay] = useState(false);

  const getBatchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=batch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBatchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBranchOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OPTIONS_API +
          `?dropdownName=branch_key&screenName=OBE_COURSES`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateBranchOptions(response["data"]["result"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOptions = async () => {
    try {
      await getBatchOptions();
      await getBranchOptions();
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (filters.batch !== "" && filters.branch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat
            .OBE_ADMIN_COURSES_ANALYSIS_AVG_ATTAINMENT_TABLE +
            `?branch=${filters.branch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateContent(true);
          updateData(response["data"]["result"]);
        } else {
          updateContent(false);
          updateData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBreakDownData = async () => {
    try {
      const token = Cookies.get("jwt_token");

      if (filters.batch !== "" && filters.branch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat
            .OBE_ADMIN_COURSES_ANALYSIS_COURSE_ATT_BREAKDOWN_TABLE +
            `?batch=${filters.batch}&branch=${filters.branch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          updateBreakContent(true);
          updateBreakData(response["data"]["result"]);
        } else {
          updateBreakContent(false);
          updateBreakData([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getGraph = async () => {
    try {
      const token = Cookies.get("jwt_token");
      if (filters.branch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat
            .OBE_ADMIN_COURSES_ANALYSIS_AVG_ATTAINMENT_GRAPH +
            `?branch=${filters.branch}&batch=${filters.batch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          // console.log(response['data']['result']);
          updateGraph(response["data"]["result"]);
        } else {
          updateGraph([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const activeSemChange = () => {
    if (activeSem === 1) {
      updateActiveSem(2);
    } else {
      updateActiveSem(1);
    }
  };

  const getGraph1 = async () => {
    try {
      const token = Cookies.get("jwt_token");
      if (filters.branch !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_ADMIN_COURSES_ANALYSIS_ATT_BREAKDOWN_GRAPH +
            `?branch=${filters.branch}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          // console.log(response["data"]["result"]);
          updateGraph1(response["data"]["result"]);
        } else {
          updateGraph1([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value });
  };

  const getCourses = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=&branch=&regulation=&semester=`,
        { Authorization: token }
      );

      // console.log(response);
      if (response["data"]["statusCode"] === "OK") {
        if (response["data"]["result"]["courseDetailsResponses"].length > 0) {
          updateView(true);
        } else {
          updateView(false);
        }
      } else {
        updateView(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      if (dropBatchOptions.length !== 0) {
        updateFilters({
          ...filters,
          batch: dropBatchOptions[0]["optionValue"],
        });
      }
    }
  }, [dropBatchOptions]);

  useEffect(() => {
    if (navigator.onLine) {
      if (userType !== "HOD") {
        if (dropBranchOptions.length !== 0) {
          updateFilters({
            ...filters,
            branch: dropBranchOptions[0]["optionValue"],
          });
        }
      }
    }
  }, [dropBranchOptions]);

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");

      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        // console.log(claim);
        if (claim["user_type"] === "HOD") {
          updateFilters({ ...filters, branch: claim["dept_name"] });
          updateType("HOD");
        }
      }
      getCourses();
      getOptions();
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        getData();
        getBreakDownData();
        getGraph();
      }
    }
  }, [filters]);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        getGraph1();
      }
    }
  }, [filters, activeSem]);

  const renderAttTable = () => {
    try {
      const ans = [];
      for (let index = 0; index < data.length; index++) {
        ans.push(
          <tr className="border border-blue-500 text-[#040C58]">
            <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
              {data[index].batchName}
            </th>
            <td
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
            >
              {data[index].totalCourses}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
            >
              {data[index].numAttained}/
              {data[index].numAttained + data[index].numNotAttained}
            </td>
            <td
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
            >
              {Math.round(data[index].pct)}%
            </td>

            <td
              colSpan={2}
              className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
            >
              {data[index].numNotAttained}
            </td>
          </tr>
        );
      }

      return ans;
    } catch (error) {
      console.log(error);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderEmptyCourses = () => (
    <div className="fixed flex flex-col inset-x-2 top-2/4 items-center">
      <img
        src="/assets/cos.svg"
        width={361}
        height={38}
        alt="COURSE OUTCOMES"
      />
      <p className={styles["no-courses-text"]}>
        You Don&apos;t Have Any Courses Created
      </p>
      {/* <button className={styles['cc-btn']}>Create Courses</button> */}
    </div>
  );

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const closeBtn = () => {
    updateOverlay(false);
  };

  const openBtn = () => {
    updateOverlay(true);
  };

  const renderScreen = () => {
    return (
      <>
        <CoursesAnalysisOutcome />
        <div className={styles["dashboard-periodwisebar-bg"]}>
          <div
            onClick={closeBtn}
            className={overlay ? styles.overlay : "hidden"}
          >
            <div onClick={preventBubbling} className={styles["overlay-card"]}>
              <div className={`${styles["overlay-filters"]}`}>
                <select
                  // onChange={this.batchChanged}
                  // value={batch}
                  className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
                >
                  <option>2018-2019</option>
                  {/* {this.renderBatchOptions()} */}
                </select>
                {userType !== "HOD" && (
                  <select
                    // onChange={this.branchChanged}
                    // value={branch}
                    className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
                  >
                    <option>AIML</option>
                    {/* {this.renderBranchOptions()} */}
                  </select>
                )}

                <select
                  // onChange={this.semChanged}
                  // value={sem}
                  className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
                >
                  <option>1st Year</option>
                  {/* {this.renderSemOptions()} */}
                </select>
              </div>
              <div className={styles["overlay-table-details"]}>
                {true ? (
                  <table className="text-center border border-blue-500 w-full">
                    <tbody>
                      <tr
                        className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                      >
                        <th className="border border-blue-500 p-2">Course</th>
                        <th className="border border-blue-500 p-2">Batch</th>
                        <th className="border border-blue-500 p-2">
                          Total COs
                        </th>
                        <th className="border border-blue-500 p-2">Attained</th>
                        <th className="border border-blue-500 p-2">
                          Not-Attained
                        </th>
                        <th className="border border-blue-500 p-2">Target</th>
                        <th className="border border-blue-500 p-2">
                          Threshold
                        </th>
                      </tr>

                      <tr className="border border-blue-500 text-[#040C58]">
                        <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                          M1
                        </th>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          2015-2019
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          5
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          3
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#E35050]`}
                        >
                          2
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                      </tr>
                      <tr className="border border-blue-500 text-[#040C58]">
                        <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                          M1
                        </th>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          2015-2019
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          5
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          3
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#E35050]`}
                        >
                          2
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                      </tr>
                      <tr className="border border-blue-500 text-[#040C58]">
                        <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                          M1
                        </th>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          2015-2019
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          5
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          3
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#E35050]`}
                        >
                          2
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                      </tr>

                      <tr className="border border-blue-500 text-[#040C58]">
                        <th className="border border-blue-500 p-2 bg-[#EFF3FF]">
                          M1
                        </th>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          2015-2019
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          5
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          3
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#E35050]`}
                        >
                          2
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                        <td
                          className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
                        >
                          75
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center mt-5 pb-5 text-red-400">
                    No Data Found
                  </p>
                )}
              </div>
            </div>
          </div>

          <span className="flex flex-row justify-between mt-2 mb-10  items-center">
            <h1 className={styles["periodwisebar-title"]}>
              COURSES CO ATTAINMENTS BREAKDOWN
            </h1>

            <div className={styles["filters"]}>
              {userType !== "HOD" && (
                <select
                  onChange={branchChanged}
                  value={filters.branch}
                  className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
                >
                  {renderBranchOptions()}
                </select>
              )}

              {/* <select
                className={`${styles["filter-select"]} focus:ring-0 mr-5 p-2 pr-5`}
              >
                <option>1st Year</option>
                <option>2nd Year</option>
              </select> */}

              <button
                disabled
                onClick={openBtn}
                type="button"
                className={`${styles["see-data-btn"]} mr-3 cursor-not-allowed focus:ring-0 p-2`}
              >
                See Data
              </button>
              <span
                // onClick={content && downloadPdf}
                className={styles["info-btns"]}
              >
                <button
                  type="button"
                  className="flex items-center cursor-not-allowed"
                >
                  <span className="mr-2">
                    <img
                      src="/assets/download.svg"
                      alt="download"
                      width={18}
                      height={18}
                    />
                  </span>
                  Download report
                </button>
              </span>
            </div>
          </span>

          <AnalysisCourseAttainment
            graph={graph1}
            actSem={activeSem}
            updateActSem={activeSemChange}
            content={breakContent}
            data={breakData}
          />

          <div className={styles["barchart-container"]}>
            {/* <div className={styles["line-chart"]}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
              />
            </div> */}

            <div className={styles["bar-chart-details"]}>
              {content ? (
                <table className="text-center border border-blue-500 w-full">
                  <tbody>
                    <tr
                      className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                    >
                      <th className="border border-blue-500 p-2 pt-0"></th>
                      <th className="border border-blue-500 p-2 pt-0">
                        Total Courses
                      </th>
                      <th className="border border-blue-500 p-2 pt-0">COs</th>
                      <th className="border border-blue-500 p-2 pt-0">
                        Percentage
                      </th>
                      <th className="border border-blue-500 pt-0">
                        Non-Attained
                        <span className="flex w-full">
                          <div className="pb-1 border-blue-500 border-b-0 border-l-0 border-r-0 w-full pr-0.5">
                            (CO)
                          </div>
                        </span>
                      </th>
                    </tr>

                    {renderAttTable()}
                  </tbody>
                </table>
              ) : (
                <p className="text-center mt-5 pb-5 text-red-400">
                  No Data Found
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderInitialView = () => {
    try {
      switch (view) {
        case true:
          return renderScreen();
        case false:
          return renderEmptyCourses();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { match } = props;
  const { path } = match;

  return (
    <>
      <div className="flex flex-row mt-24 ml-8">
        <Link to="/obe/courses/managecourses">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/courses/managecourses"
                ? "font-bold underline"
                : "font-medium"
            } ml-4 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            MANAGE COURSES
          </h1>
        </Link>
        <Link to="/obe/courses/analysis">
          <h1
            className={`hover:font-bold hover:[#464D90] ${
              path === "/obe/courses/analysis"
                ? "font-bold underline"
                : "font-medium"
            } ml-6 mb-2 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
          >
            ANALYSIS
          </h1>
        </Link>
      </div>
      {renderInitialView()}
    </>
  );
};

export default withRouter(CoursesAnalysis);
